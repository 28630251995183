import React, { useState } from "react";
import "./style.scss";

import { ReactComponent as HeaderImg } from "./img/logo-admin.svg";
import { DefaultButton, FontIcon, IIconProps } from "@fluentui/react";

const List2 = ({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={"navlist"}>
      <label>{title}</label>
      <ol>{children}</ol>
    </div>
  );
};

const List = ({
  title,
  isCollapsed = false,
  group,
  setCurrPos,
  children,
  hasNews,
}: {
  title: string;
  isCollapsed?: boolean;
  group?: number;
  setCurrPos?: Function;
  children?: React.ReactNode;
  hasNews?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed ?? false);
  //const { setSection } = useContext(AppContext);

  const handleChangeList = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div
      className={`navlist ${title.replaceAll(" ", "").toLowerCase()}${
        collapsed ? " collapsed" : ""
      }`}
    >
      <div
        className="main-option"
        onClick={handleChangeList}
        style={{ position: "relative" }}
      >
        <label>{title}</label>
        <div style={{ position: "absolute", left: "100%" }}>
          <FontIcon iconName="CaretSolidDown" className="navlist-caret" />
        </div>
        {hasNews && (
          <span className="is-new" title="Esta sección contiene novedades">
            &#9733;
          </span>
        )}
      </div>
      <ol>{children}</ol>
    </div>
  );
};

const ListItem = ({
  i,
  text,
  section,
  setSection,
  disabled,
  onClick,
}: {
  i: number;
  text: string;
  section: number;
  setSection: Function;
  disabled?: boolean;
  onClick?: Function;
}) => {
  const handleMenuSelection = (e: any) => {
    if (!!onClick) {
      onClick();
    } else if (!disabled) {
      if (i !== section) setSection(i);
    }
  };

  return (
    <li
      onClick={handleMenuSelection}
      className={`navlist-item${section === i ? " active" : ""}${
        disabled ? " disabled" : ""
      }`}
    >
      {text}
    </li>
  );
};

function _logout(): void {
  window.localStorage.removeItem("auth-token");
  window.location.reload();
}

const Header = ({
  section,
  setSection,
}: {
  section: number;
  setSection: Function;
}) => {
  return (
    <>
      <header className="admin">
        <nav>
          <div className="header-img">
            <HeaderImg />
          </div>
          <List title={"Mantenimiento de datos"} isCollapsed={false}>
            <ListItem
              key={0}
              i={0}
              text={"Grupos de cadenas"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={1}
              i={1}
              text={"Cadenas"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={2}
              i={2}
              text={"Segmentos"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={3}
              i={3}
              text={"Especialidades"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={4}
              i={4}
              text={"Usuarios"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={5}
              i={5}
              text={"Ventas"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={6}
              i={6}
              text={"Eventos"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={7}
              i={7}
              text={"Periodos"}
              section={section}
              setSection={setSection}
              disabled={true}
            />
          </List>
          <List title={"Control"} isCollapsed={false}>
            <ListItem
              key={8}
              i={8}
              text={"Cuadro de visibilidad"}
              section={section}
              setSection={setSection}
            />
            <ListItem
              key={9}
              i={9}
              text={"Registros"}
              section={section}
              setSection={setSection}
              disabled={true}
            />
            <ListItem
              key={10}
              i={10}
              text={"Mantenimiento"}
              section={section}
              setSection={setSection}
            />
          </List>
          {/**/}
          <List title={"Integraciones"} isCollapsed={false}>
            <ListItem
              key={13}
              i={13}
              text={"Clientes REVO"}
              section={section}
              setSection={setSection}
            />
          </List>
          {/**/}
          <List title={"Otros"} isCollapsed={false}>
            <ListItem
              key={11}
              i={11}
              text={"Carga de datos"}
              section={section}
              setSection={setSection}
            />
            {/*<ListItem key={11} i={11} text={"Configuración"}	section={section} setSection={setSection} />*/}
            <ListItem
              onClick={_logout}
              key={12}
              i={12}
              text={"Cerrar sesión"}
              section={section}
              setSection={setSection}
            />
          </List>
        </nav>
      </header>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";

import "./style.scss";

interface Props {
  children: React.ReactNode;
}

export const GraphNotCompatible = (props: Props) => {
  return (
    <div className="graph-not-compatible">
      <p>
        {props.children ||
          "No se puede mostrar el gráfico. Pruebe con otros filtros."}
      </p>
    </div>
  );
};

import React, { memo } from "react";
import { geoCentroid } from "d3-geo";
import { scaleQuantize } from "d3-scale";
import { ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ReactComponent as SpainMap } from "./spain-simple.svg";

const geoUrl = "assets/data/map.json";

const getLabelCoord = (codigo: number) => {
	switch(codigo){
		default:	return {x:0,y:0}
		case 2:	return {x:-10,y:-15}
		case 3:	return {x:-20,y:-20}
		case 4:	return {x:-20,y:80}
		case 5:	return {x:0,y:0}
		case 6:	return {x:-80,y:40}
	}
}

const MapChart = ({ setTooltipContent, values, className, loading }: {setTooltipContent: any, values: number[], className?: string, loading?: boolean}) => {
	const colorScaleP = scaleQuantize<string>()
	.domain([0,Math.max(...values)])
	.range([
		"#bbdee0",
		"#add9dc",
		"#9fd5d9",
		"#8fd1d6",
		"#80cdd4",
		"#70cad2",
		"#5fc7d1",
		"#4ec4d1",
		"#3cc1d0",
		"#2dbcce",
		"#26b1c3",
		"#20a5b8",
		"#1a98ab",
		"#158c9f",
		"#117f91",
		"#0d7284",
		"#096475",
		"#065766",
		"#044957",
		"#023b47",
	]);
	const colorScaleN = scaleQuantize<string>()
	.domain([Math.min(...values),0])
	.range([
		"#393939",
		"#414141",
		"#4a4a4a",
		"#525252",
		"#5b5b5b",
		"#636363",
		"#6c6c6c",
		"#747474",
		"#7c7c7c",
		"#858585",
		"#8d8d8d",
		"#969696",
		"#9e9e9e",
		"#a6a6a6",
		"#afafaf",
		"#b7b7b7",
		"#c0c0c0",
		"#c8c8c8",
		"#d1d1d1",
		"#d9d9d9",
	]);
	
	return (
	<>
		{loading &&
			<div style={{justifyContent: "center",display: "flex",height:400,marginBottom: -400}}>
				<Spinner size={SpinnerSize.large} />
			</div>
		}
		<ComposableMap className={className ?? ""} data-tip="" projectionConfig={{ scale: 3100 }}>
			<Geographies geography={geoUrl}>
				{({ geographies }) =>
				<>
					{geographies.map((geo) => (
						<Geography
							key={geo.properties.codigo}
							geography={geo}
							fill={loading?"#222222":values[geo.properties.codigo]>0?colorScaleP(values[geo.properties.codigo]):colorScaleN(values[geo.properties.codigo])}
							onMouseEnter={() => {
								const { ccaa } = geo.properties;
								if(!loading) setTooltipContent(`${ccaa}: ${values[geo.properties.codigo]}%`);
							}}
							onMouseLeave={() => {
								setTooltipContent("");
							}}
							style={{
								default: {
									//fill: "#D6D6DA",
									outline: "none",
									stroke: "#191A1B",
									paintOrder: "stroke",
									strokeWidth: 0.05,
									fillOpacity: 0.9,
								},
								hover: {
									//fill: "#000000dd",
									fillOpacity: 1,
									outline: "none",
									stroke: "#191A1B",
									paintOrder: "fill",
									strokeWidth: 0,
									cursor: "pointer"
								},
								pressed: {
									//fill: "#064c55",
									fillOpacity: 0.5,
									outline: "none"
								}
							}}
						/>
					))}
					{!loading && geographies.map((geo) => {
						const centroid = geoCentroid(geo);
						return (
							<g key={geo.properties.codigo + "-name"}>
								<Marker coordinates={centroid}>
									<text
										fontSize={16}
										pointerEvents="none"
										paintOrder="stroke"
										textAnchor="middle"
										strokeWidth="2.5"
										fill="white"
										stroke="black"
										x={getLabelCoord(geo.properties.codigo).x}
										y={getLabelCoord(geo.properties.codigo).y}
									>
										{`${Math.round(values[geo.properties.codigo]*10)/10}%`}
									</text>
								</Marker>
							</g>
						);
					})}
					{/*<g key={"total"} className="total-marker">
						<Marker coordinates={[-7,0]}>
							<text
								fontSize={16}
								pointerEvents="none"
								paintOrder="stroke"
								textAnchor="middle"
								strokeWidth="2.5"
								fill="white"
								stroke="black"
								x={10}
								y={10}
							>
								{`Total: ${values[0]}%`}
							</text>
						</Marker>
					</g>*/}
				</>
				}
			</Geographies>
		</ComposableMap>
		{!loading && values.length>0 && 
		<div className="region-total">
			<SpainMap fill={values[0]>0?colorScaleP(values[0]):colorScaleN(values[0])}/>
			<span><strong>Total:</strong> {values[0]}%</span>
		</div>
		}
	</>
	);
  };
  
  export default memo(MapChart);

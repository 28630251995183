import { useState, useEffect, useRef, useContext } from "react";
//import Line from "react-apexcharts";
//import { ResponsiveLine } from "@nivo/line";
import { GraphNotCompatible } from "../../../components/GraphNotCompatible";

import { Chart } from "react-chartjs-2";
import { Chart as Chart2 } from "chart.js";

import { gql, useLazyQuery } from "@apollo/client";
import { Entorno } from "../../../utils/constants";
import {
  getItemHexColor,
  mergeKeyData,
  getMax,
  getMin,
  getSteps,
} from "../../../utils/functions";
import Chainz from "../../../dictionaries/Chainz.json";
import { Spinner, SpinnerSize } from "@fluentui/react";
import AppContext from "../../../AppContext";

import { getMedidaSQL } from "../../../utils/functions";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart2.register(ChartDataLabels);

//import './style.scss'

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  hue: number;
  annotation: string;
  percentages?: boolean;
};

export const ComparadorGap = ({
  variables,
  hue,
  annotation,
  percentages,
  legend,
  updateLegend,
  updateExportData,
  showLegend,
}: any) => {
  let statusRef = useRef<any>([]);
  const graphRef = useRef<any>(null);
  let appContext = useContext(AppContext);
  const { semanas, filters, user } = useContext(AppContext);

  let currentFilters = useRef<any>(null);

  let leverTypeKeys: any = [
    "Totales",
    "Canal",
    "Momento",
    "Region",
    "Ubicacion",
  ];
  let leverKeys: any = {
    Canal: { C0: "Total", C1: "Local", C2: "TakeAway", C3: "Delivery" },
    Momento: {
      M0: "Total",
      M1: "Mañanas",
      M2: "Tardes",
      M3: "Comidas (L-J)",
      M4: "Cenas (L-J)",
      M5: "Comidas (V-D)",
      M6: "Cenas (V-D)",
    },
    Region: {
      Z0: "Total",
      Z1: "Madrid",
      Z2: "Barcelona",
      Z3: "Norte",
      Z4: "Centro",
      Z5: "Noreste",
      Z6: "Sur",
    },
    Ubicacion: { U0: "Total", U1: "Calle", U3: "CC", U4: "Resto" },
  };

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
      $misdatos: Int
    ) {
      graph(
        Graph: "GAP comparador"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
          misdatos: $misdatos
        }
      )
    }
  `;

  const [graphData, setGraphData] = useState<any>(null);
  let emptyData = {
    labels: [],
    datasets: [],
  };
  const [errorMsg, setErrorMsg] = useState<string>(
    `Se ha producido un error recuperando los datos`
  );

  const [isCompatible, setCompatible] = useState<boolean>(true);

  const [fetchData, { loading, error, data }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    // Launch query
    fetchData({
      variables: {
        ...variables,
        yr1: semanas[0].year,
        wk1: semanas[0].week,
        yr2: semanas[1].year,
        wk2: semanas[1].week,
        var: 1,
        segment:
          filters.segmentType === 0
            ? filters?.segmentos[filters?.segment]
            : filters?.especialidades[filters?.segment],
        segmentType: filters.segmentType,
        misdatos: filters?.misdatos,
      },
    });
    currentFilters.current = { ...filters };
  }, [semanas, filters?.segmentType, filters?.segment, filters?.misdatos]);

  useEffect(() => {
    if (data?.graph && !loading) {
      if (data?.graph?.Error) {
        setCompatible(false);
        setErrorMsg(data?.graph?.Error);
        return;
      }

      setCompatible(true);

      // const myData = data.graph;

      const medida = getMedidaSQL(filters.medidas);
      let entorno =
        filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

      let fetchedData: any = data?.graph;

      if (!fetchedData) throw new Error(errorMsg);
      if (fetchedData?.Error) throw new Error(`${data.Error}`);

      let labels = fetchedData?.map((d: any) => Object.keys(d)[0]);
      if (labels.length < 3)
        throw new Error(
          `No hay datos suficientes para mostrar el periodo indicado.`
        );

      let datalabels = fetchedData[0][labels[0]].map(
        (d: { Descripcion: string }) => d.Descripcion
      );

      let dataName = fetchedData[0][labels[0]].map(
        (d: { Name: string }) => d.Name
      );

      let values: any = [];
      let series: any = [];
      let values2: any = {};

      // Filter Segment / Full market
      let segmentsArray: any = [];

      if (filters.variables === 0) {
        //
        segmentsArray = datalabels.filter(
          (item: string) => item?.indexOf("sin m") > -1
        );
      } else {
        segmentsArray = datalabels.filter(
          (item: string) =>
            item?.substring(0, 4) ===
            filters.segmentos[filters.variables].substring(0, 4)
        );
      }

      // Filter my data
      let myDataArray: any = [];

      let cadenas = datalabels.filter(
        (item: string) => item?.substring(0, 4) === "cad_"
      );

      //console.log("length: ", lengthDifference);
      myDataArray = [...cadenas];

      let filteredLabels: any = [];
      segmentsArray.map((item: any) => filteredLabels.push(item));
      myDataArray.map((item: any) => filteredLabels.push(item));

      values2 = filteredLabels.map((item: string) => {
        return { [item]: [] };
      });

      try {
        for (let i = 0; i < filteredLabels.length; i++) {
          let valueSub: any = [];

          fetchedData.map((dat: any): void => {
            let tempArray: any = [...Object.values(dat)];
            let index = tempArray[0].findIndex((item2: any) => {
              return (
                item2["Descripcion"].substring(0, 7) ===
                filteredLabels[i].substring(0, 7)
              );
            });

            let arrayOfItems = tempArray[0][index];
            let valueSub2 = arrayOfItems?.Item[medida][entorno];
            //console.log("arrayOfItems: ", arrayOfItems);
            //console.log(valueSub2);
            // find index of the item in values2 with key matching filteredLabels[i]
            let index2 = values2.findIndex((item2: any) => {
              return (
                Object.keys(item2)[0].substring(0, 7) ===
                filteredLabels[i].substring(0, 7)
              );
            });
            //console.log("index2: ", index2);
            values2[index2][filteredLabels[i]].push(valueSub2);

            //values2[filteredLabels[i]].push(valueSub2);

            if (filters.leverType === 0) {
              valueSub.push(arrayOfItems?.Item[medida][entorno].Totales);
            } else {
              let leverKey = leverTypeKeys[filters.leverType || 0];
              let leverSubKey = leverKeys[leverKey][filters.lever || 0];

              valueSub.push(
                arrayOfItems?.Item[medida][entorno][leverKey][leverSubKey]
              );
            }
          });
          values.push(valueSub.flat().reverse());
        }
      } catch {
        setCompatible(false);
      }

      labels.reverse();

      values.forEach((val: any, i: any) => {
        // Find index in Chainz with key matching dataName[i]
        const index = Object.keys(Chainz).findIndex(
          (item: any) => item === dataName[i]
        );
        let color: any = "#ffffff";
        if (index !== -1) {
          //@ts-ignore
          color = `${Chainz[dataName[i]].color}`;
        }

        series.push({
          name: filteredLabels[i],
          color: color,
          data: val,
        });
      });

      const graphData = {
        datalabels: filteredLabels,
        labels: labels,
        values: values,
        series: series,
        values2: values2,
      };

      console.log("GGGRAPH: ", graphData);
      console.log("FETCHED DATA: ", fetchedData);

      if (updateExportData) {
        currentFilters.current = { ...filters };
        let exportData = getExportData(
          fetchedData,
          filteredLabels,
          labels,
          semanas[0].year === 2019,
          currentFilters.current,
          leverTypeKeys,
          leverKeys
        );

        updateExportData(exportData);
      }

      setGraphData(graphData);
    }
  }, [data, filters, errorMsg]);

  useEffect(() => {
    //@ts-ignore -- Limitación de apexcharts?
    if (graphRef.current && graphRef.current.props.series.length > 5) {
      data.series.forEach(
        (serie: { name: string; data: number[] }, i: number) => {
          if (i > 5) graphRef?.current?.chart.hideSeries(serie.name);
        }
      );
    }
  }, [data]);

  const newLegendClickHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
    ci.update();

    // UpdateStatus
    let status = statusRef.current;
    if (status) {
      status[index].hidden = !status[index].hidden;
      //console.warn("STATUS: ", updateLegend);
      // updateLegend(status);
    }
  };

  const newLegendHoverHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem?.datasetIndex;
    const ci = legend.chart;
    setHoveredIndex(index);
    ci.update();
  };

  const newLegendLeaveHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const ci = legend.chart;
    setHoveredIndex(-1);
    ci.update();
  };

  const plugin: any = {
    id: "verticalLiner",
    afterInit: (chart: any, args: any, opts: any) => {
      chart.verticalLiner = {};
    },
    afterEvent: (chart: any, args: any, options: any) => {
      const { inChartArea } = args;
      chart.verticalLiner = { draw: inChartArea };
    },
    beforeTooltipDraw: (chart: any, args: any, options: any) => {
      const { draw } = chart.verticalLiner;
      if (!draw) return;

      const { ctx } = chart;
      const { top, bottom } = chart.chartArea;
      const { tooltip } = args;
      const x = tooltip?.caretX;
      if (!x) return;

      ctx.save();

      ctx.beginPath();
      ctx.moveTo(x, top);
      ctx.lineTo(x, bottom);
      ctx.strokeStyle = "#545455";
      ctx.stroke();

      ctx.restore();
    },
  };

  const options = {
    responsive: true,
    tension: 0,
    animation: {
      duration: 400,
    },
    interaction: {
      intersect: false,
      mode: "index" as any,
    },
    plugins: {
      verticalLiner: {},
      datalabels: {
        color: showLegend ? "white" : "transparent",
      },
      legend: {
        reverse: false,
        position: "bottom" as const,
        onClick: newLegendClickHandler,
        onHover: newLegendHoverHandler,
        onLeave: newLegendLeaveHandler,
        labels: {
          color: "rgb(255, 255, 255, 0.9)",
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Mi tendencia",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: true,
          tickColor: "#26292A",
          color: "#26292A",
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(0) + "%"; // convert it to percentage
          },
        },
      },
    },
  };

  let [dataTest, setDataTest] = useState<any>({
    labels: [],
    datasets: [],
  });

  let [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  useEffect(() => {
    if (
      !graphData ||
      graphData?.labels?.length === 0 ||
      graphData?.series?.length === 0
    )
      return;

    let datasets: any = [];
    let segmentsCount: number = 0;
    let chainsCount: number = 0;
    /* DEVELOPMENT */
    let graphDataGrouped: any = [];
    let datasetLabels: any = [];
    //       let labels = fetchedData?.map((d: any) => Object.keys(d)[0]);
    graphData.values2.map((item: any) => {
      let keys = Object.keys(item);
      graphDataGrouped.push({
        [keys[0]]: [],
      });
    });

    if (filters.leverType === 0) {
      // TOTALES de cada palanca

      graphData.values2.forEach((item: any, index: number) => {
        let key = Object.keys(item)[0];
        datasetLabels.push(key);
        // Gets values of each GROUP (mercado sin mi, compañia, etc.)
        let collection = item[key];
        //console.log("Collection: ", collection);

        let collectionKeys = Object.keys(collection[0]); // Gets keys to palancas (Totales, Momento, Canal, etc.)
        //console.log("VERZZ Collection keys: ", collectionKeys);
        collectionKeys = ["Totales"]; // ESTO ES TEMPORAL
        datasetLabels = [...collectionKeys];

        // Map collections and build subseries
        let subseries: any = [];

        collectionKeys.forEach((key: any) => {
          if (key !== "Totales") {
            // Era Totales
            let newSubserie = {
              name: "",
              data: [],
              color: "",
            };

            newSubserie.name = key;
            newSubserie.data = collection
              .map((item: any) => item[key].Total || item?.Totales)
              .reverse()
              .flat();

            /*
          newSubserie.name = key;
          newSubserie.data = collection
            .map((item: any) => item[key])
            .reverse()
            .flat();
          newSubserie.color = "#ffffff";
					*/
            //console.log("NEW SUBSERIE: ", newSubserie);
            subseries.push(newSubserie);
          } else {
            let newSubserie = {
              name: "",
              data: [],
              color: "",
            };

            newSubserie.name = key;
            newSubserie.data = collection
              .map((item: any) => item[key].Total || item?.Totales)
              .reverse()
              .flat();

            subseries.push(newSubserie);
          }
        });

        //console.log("SUBSERIESSSS: ", subseries);

        let currentDataSet: any = [];
        subseries.forEach(
          (
            serie: {
              name: string;
              data: number[];
              color: string;
              labels: string[];
            },
            index: number
          ) => {
            let color: string = serie.color;
            color = getItemHexColor("comparator", index);

            //let color = serie.color || rndColor;
            //console.log("SERE: ", serie, graphData);

            currentDataSet.push({
              type:
                serie.name.substring(0, 4) !== "cad_" &&
                serie.name.substring(0, 4).toLowerCase() !== "mi c"
                  ? "line"
                  : "line",
              order:
                serie.name.substring(0, 4) !== "cad_" &&
                serie.name.substring(0, 4).toLowerCase() !== "mi c"
                  ? "1"
                  : "0",
              label: serie.name,
              data: serie.data,
              borderColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              backgroundColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              //hidden: legend[index]?.hidden || false,
              hidden: statusRef?.current[index]?.hidden || false,
              fill: {
                target: "origin",
                above: color,
                below: color,
              },
            });

            //console.warn("SETTING ITEM: ", item);
          }
        );

        let data4: any = {
          labels: graphData.labels,
          datasets: currentDataSet,
        };

        setDataTest(data4);
      });
    } else {
      // Por palanca seleccionada

      let subLever = leverTypeKeys[filters.leverType || 0];

      graphData.values2.forEach((item: any, index: number) => {
        //console.log("ITEM: ", item);
        let key = Object.keys(item)[0];
        datasetLabels.push(key);
        // Gets values of each GROUP (mercado sin mi, compañia, etc.)
        let collection = item[key];

        let collectionKeys = Object.keys(collection[0][subLever]); // Gets keys to palancas (Totales, Momento, Canal, etc.)

        collectionKeys = collectionKeys.filter((item: any) => item !== "Total");
        datasetLabels = [...collectionKeys];

        // Map collections and build subseries
        let subseries: any = [];

        collectionKeys.forEach((key: any) => {
          if (key !== "Totales" && key !== "Concesiones" && key !== "Otros") {
            let newSubserie = {
              name: "",
              data: [],
              color: "",
            };

            if (filters.leverType === 3) {
              newSubserie.name = leverKeys.Region[key];
            } else {
              newSubserie.name = key;
            }
            newSubserie.data = collection
              .map((item: any) => item[subLever][key])
              .reverse()
              .flat();

            /*
          newSubserie.name = key;
          newSubserie.data = collection
            .map((item: any) => item[key])
            .reverse()
            .flat();
          newSubserie.color = "#ffffff";
					*/
            //console.log("NEW SUBSERIE: ", newSubserie);
            // If sublever === 3 rename keys to leverKeys.Region

            if (filters.lever === 0) {
              subseries.push(newSubserie);
            } else {
              let subkeys: any =
                Object.values(leverKeys)[filters.leverType - 1];

              if (
                subkeys[Object.keys(subkeys)[filters.lever]] ===
                newSubserie.name
              ) {
                subseries.push(newSubserie);
              }
            }
          }
        });

        //console.log("SUBSERIESSSS: ", subseries);

        let currentDataSet: any = [];

        subseries.forEach(
          (
            serie: {
              name: string;
              data: number[];
              color: string;
              labels: string[];
            },
            index: number
          ) => {
            let color: string = serie.color;
            color = getItemHexColor("comparator", index);

            //let color = serie.color || rndColor;
            //console.log("SERE: ", serie, graphData);

            currentDataSet.push({
              type:
                serie.name.substring(0, 4) !== "cad_" &&
                serie.name.substring(0, 4).toLowerCase() !== "mi c"
                  ? "line"
                  : "line",
              order:
                serie.name.substring(0, 4) !== "cad_" &&
                serie.name.substring(0, 4).toLowerCase() !== "mi c"
                  ? "1"
                  : "0",
              label: serie.name,
              data: serie.data,
              borderColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              backgroundColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              //hidden: legend[index]?.hidden || false,
              hidden: statusRef?.current[index]?.hidden || false,
              fill: {
                target: "origin",
                above: color,
                below: color,
              },
            });

            //console.warn("SETTING ITEM: ", item);
          }
        );

        let data4: any = {
          labels: graphData.labels,
          datasets: currentDataSet,
        };

        setDataTest(data4);
      });
    }

    if (statusRef.current.length === 0) {
      // statusRef.current = datasets;
    }

    //setDataTest(data4);
  }, [graphData, hoveredIndex, legend]);

  console.log("FILTERS UTC: ", filters);

  return (
    <div className="chart-container">
      {dataTest && (
        <div className="chart-wrapper">
          {/*dataTest && <Line data={dataTest} options={options} />*/}

          <Chart
            type="bar"
            data={!loading ? dataTest : emptyData}
            options={options}
            plugins={[plugin]}
          />

          {!isCompatible && !loading && (
            <GraphNotCompatible>{errorMsg}</GraphNotCompatible>
          )}
          {isCompatible && filters.leverType === 10 && !loading && (
            <GraphNotCompatible>
              {`Seleccione la variable a observar en el panel de filtros.`}
            </GraphNotCompatible>
          )}
          {filters.entorno === Entorno.Comparable &&
            filters.leverType > 0 &&
            !loading && (
              <GraphNotCompatible>
                Esta gráfica no soporta el entorno comparable para la variable
                seleccionada.
              </GraphNotCompatible>
            )}
        </div>
      )}

      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
          <p>Obteniendo datos...</p>
        </div>
      )}
    </div>
  );
};

const getExportData = (
  data: any,
  datalabels: any[],
  labels: any,
  isLeap: boolean,
  currentFilters: any,
  leverTypeKeys: any,
  leverKeys: any
) => {
  let exportData: any = [];
  let arrayDeCosas: any = [];
  console.log("CURRENT FILTERS: ", currentFilters);
  if (currentFilters.entorno === 0) {
    arrayDeCosas.push("Totales");
  } else {
    arrayDeCosas.push("Comparables");
  }
  let test: any = Object.values(data[0]);

  // Make an array of all "Descripcion" values in test[0]
  let test2: any = test[0].map((item: any) => item?.Descripcion);

  if (isLeap === false && currentFilters.leverType === 0) {
    //arrayDeCosas.push("Comparables");
  }
  for (let i = 0; i < test2.length; i++) {
    let tempVals: any = [];
    ["Ventas", "Tickets"].forEach((med: string, m: number) => {
      arrayDeCosas.forEach((ent: string, e: number) => {
        if (currentFilters.leverType === 0) {
          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i]?.Item[med][ent])
            )
            .flat()
            .reverse();

          let payload: any = {};

          tempVal.map((itemTemp: any, index: any) => {
            Object.keys(itemTemp).map((key: any) => {
              if (!payload[key]) {
                payload[key] = [itemTemp[key]];
              } else {
                payload[key].push(itemTemp[key]);
              }
            });
          });

          Object.keys(payload).map((key: any) => {
            tempVals[med + key] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        } else {
          let palanca = leverTypeKeys[currentFilters.leverType];
          let subPalanca: any = Object.values(leverKeys[palanca])[
            currentFilters.lever
          ];

          //console.log("HAY PALANCA: ", m, palanca, subPalanca);

          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i]?.Item[med][ent][palanca])
            )
            .flat()
            .reverse();

          let payload: any = {};

          tempVal.map((itemTemp: any, index: any) => {
            Object.keys(itemTemp).map((key: any) => {
              if (!payload[key]) {
                payload[key] = [itemTemp[key]];
              } else {
                payload[key].push(itemTemp[key]);
              }
            });
          });

          Object.keys(payload).map((key: any) => {
            tempVals[med + key] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        }
      });
    });
    exportData.push({ [test2[i]]: tempVals });
  }

  console.log("EXPORT DATA2: ", exportData);

  // Remove from exportData the ones whose key is not in datalabels
  let exportDataFiltered = exportData.filter((item: any) => {
    let key = Object.keys(item)[0];
    return datalabels.includes(key);
  });

  return exportDataFiltered;
};

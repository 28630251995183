import React, { useContext } from "react";
import Graph00, {
  title as title00,
  filters as filters00,
} from "./GraphsCompared/PosicionGlobal";
import Graph01, {
  title as title01,
  filters as filters01,
} from "./GraphsCompared/TamanoMercado";
import Graph02, {
  title as title02,
  filters as filters02,
} from "./GraphsCompared/MapaPosicionRelativa";
import Graph03, {
  title as title03,
  filters as filters03,
} from "./GraphsCompared/IndicadoresTendencia";
import Graph04, {
  title as title04,
  filters as filters04,
} from "./GraphsCompared/MercadoSegmentos";
import Graph05, {
  title as title05,
  filters as filters05,
} from "./GraphsCompared/SegmentosCanalServicio";
import Graph06, {
  title as title06,
  filters as filters06,
} from "./GraphsCompared/TendenciasCanalServicio";
import Graph07, {
  title as title07,
  filters as filters07,
} from "./GraphsCompared/TendenciasMomento";
import Graph08, {
  title as title08,
  filters as filters08,
} from "./GraphsCompared/TendenciasRegion";
import Graph09, {
  title as title09,
  filters as filters09,
} from "./GraphsCompared/TendenciasUbicacion";
import Graph10, {
  title as title10,
  filters as filters10,
} from "./Graphs/MiTendencia";
import Graph11, {
  title as title11,
  filters as filters19,
} from "./Graphs/MiCuotaMercado";
import Graph12, {
  title as title12,
  filters as filters12,
} from "./Graphs/MiMercadoSegmentos";
import Graph13, {
  title as title13,
  filters as filters13,
} from "./Graphs/MiSegmentoCanalServicio";
import Graph14, {
  title as title14,
  filters as filters14,
} from "./Graphs/MiTendenciaCanalServicio";
import Graph15, {
  title as title15,
  filters as filters15,
} from "./Graphs/MiTendenciaMomentoDia";
import Graph16, {
  title as title16,
  filters as filters16,
} from "./Graphs/MiTendenciaRegion";
import Graph17, {
  title as title17,
  filters as filters17,
} from "./Graphs/MiTendenciaUbicacion";
import Graph18, {
  title as title18,
  filters as filters18,
} from "./Graphs/MiTendencia2";
import Comparator1to1, {
  title as title19,
  filters as filters19b,
} from "./GraphsComparator/Comparator1to1";
//import Calendario from './GraphsCompared/Calendario';
import MassiveData, {
  title as title21,
  filters as filters21,
} from "./GraphsCompared/DescargaMasiva";
import Configuracion from "./Configuracion";
import AppContext from "../AppContext";
import ExcelLoader from "../components/ExcelLoader";
import Title from "../components/Title";

export const InformesComparados = [
  { group: 0, content: <Graph00 />, title: title00, filters: filters00 },
  { group: 0, content: <Graph01 />, title: title01, filters: filters01 },
  { group: 0, content: <Graph02 />, title: title02, filters: filters02 },
  { group: 0, content: <Graph03 />, title: title03, filters: filters03 },
  { group: 0, content: <Graph04 />, title: title04, filters: filters04 },
  { group: 0, content: <Graph05 />, title: title05, filters: filters05 },
  { group: 0, content: <Graph06 />, title: title06, filters: filters06 },
  { group: 0, content: <Graph07 />, title: title07, filters: filters07 },
  { group: 0, content: <Graph08 />, title: title08, filters: filters08 },
  { group: 0, content: <Graph09 />, title: title09, filters: filters09 },
];

export const MisInformes = [
  { group: 1, content: <Graph18 />, title: title18, filters: filters18 },
  { group: 1, content: <Graph11 />, title: title11, filters: filters19 },
];

export const Comparadores = [
  {
    group: 2,
    content: <Comparator1to1 />,
    title: title19,
    filters: filters19b,
  },
];

/*
	{group:1,content:<Graph12/>,title:title12,filters:filters12},
	{group:1,content:<Graph13/>,title:title13,filters:filters13},
	{group:1,content:<Graph14/>,title:title14,filters:filters14},
	{group:1,content:<Graph15/>,title:title15,filters:filters15},
	{group:1,content:<Graph16/>,title:title16,filters:filters16},
	{group:1,content:<Graph17/>,title:title17,filters:filters17}
	*/

export const DescargaMasiva: any = [
  { group: 3, content: <MassiveData />, title: title21, filters: filters21 },
];

const Sections = ({
  locked,
  auth,
}: {
  locked: boolean;
  auth: {
    Token: string;
    canRead: boolean;
    canWrite: boolean;
    canDownload: boolean;
    isAdmin: boolean;
  };
}) => {
  let { section } = useContext(AppContext);

  if (!auth.canRead && !auth.canWrite) return <></>;
  if (!auth.canRead && auth.canWrite)
    return (
      <main
        style={{ width: "100%" }}
        className={`dark${locked ? " locked" : ""}`}
      >
        <section className="manual">
          <Title title="Carga de datos semanales de ventas" />
          <ExcelLoader darkMode />
        </section>
      </main>
    );

  const InformesComparadosContainer = (
    <main className={`dark${locked ? " locked" : ""}`}>
      {InformesComparados.map((section, i) => {
        return (
          <section
            key={i}
            data-index={i}
            id={`sec${section.group}${i}`}
            className={`${auth.canDownload ? "DL" : ""}`}
          >
            {section.content}
          </section>
        );
      })}
    </main>
  );

  const MisInformesContainer = (
    <main className={`dark${locked ? " locked" : ""}`}>
      {MisInformes.map((section, i) => {
        return (
          <section
            key={i}
            data-index={i}
            id={`sec${section.group}${i}`}
            className={`${auth.canDownload ? "DL" : ""}`}
          >
            {section.content}
          </section>
        );
      })}
    </main>
  );

  const ComparadoresContainer = (
    <main className={`dark${locked ? " locked" : ""}`}>
      {Comparadores.map((section, i) => {
        return (
          <section
            key={i}
            data-index={i}
            id={`sec${section.group}${i}`}
            className={`${auth.canDownload ? "DL" : ""}`}
          >
            {section.content}
          </section>
        );
      })}
    </main>
  );

  switch (section) {
    case 0:
      return InformesComparadosContainer;
    case 1:
      return MisInformesContainer;
    case 2:
      return ComparadoresContainer;
    //case 1: return <main className="dark no-filters" data-index={-1}><section key={0} data-index={0} id={`sec10`}><Calendario/></section></main>
    case 3:
      return (
        <main className="dark" data-index={-1}>
          <section key={0} data-index={0} id={`sec30`}>
            <MassiveData admin={!!auth.isAdmin} />
          </section>
        </main>
      );
    case 4:
      return (
        <main className="dark" style={{ width: "100%" }} data-index={-1}>
          <Configuracion />
        </main>
      );
    default:
      return <></>;
  }
};

export default Sections;

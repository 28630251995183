import { useState, useEffect, useRef } from "react";
import Line from "react-apexcharts";
import locale from "./locale.es";
//import './style.scss'

const graphBorder = "#303437";
const graphBorderLight = "#7a8085";

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  hue: number;
  annotation: string;
  percentages?: boolean;
};

const LineGraph = ({ data, hue, annotation, percentages }: IBarProps) => {
  let opt = { options: {} };

  try {
    opt = {
      options: {
        colors: data.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            //horizontal: !!data.horizontal,
          },
        },
        chart: {
          id: "linegraph",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: -66,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            export: {},
            autoSelected: "zoom",
          },
          locales: [locale],
          defaultLocale: "es",
          fontFamily:
            "Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial, sans-serif",
          parentHeightOffset: 0,
          height: "auto",
          foreColor: "#ffffff",
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        markers: {
          size: 0,
        },
        grid: {
          borderColor: graphBorder,
        },
        events: {
          click: (e: any) => {
            console.log("click", e);
          },
          update: (e: any) => {
            console.log("update", e);
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 360,
              },
            },
          },
        ],
        tooltip: {
          theme: "dark",
          y: {
            formatter: function (value: number) {
              //return `${value.toString().replaceAll('.',',') + (percentages ? " %" : "")}`
              return value
                ? `${value.toString().replaceAll(".", ",")}${
                    percentages ? " %" : ""
                  }`
                : value;
            },
            title: {
              formatter: () => "",
            },
          },
          x: { show: false },
          style: {
            fontSize: "16px",
            color: "#000",
          },
        },
        /*annotations: {
					yaxis: getAnnotations()
				},*/
        xaxis: {
          categories: data.labels,
          axisBorder: {
            show: true,
            color: graphBorderLight,
            height: 1,
            width: "100%",
            offsetY: -1,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: graphBorderLight,
          },
        },
        yaxis: {
          forceNiceScale: true,
          //tickAmount: data.stepSize&&data.max&&Math.round(tickAmount)*data.stepSize>=data.max?Math.round(tickAmount):Math.round(tickAmount)+1,
          min: data.min !== undefined && data.min >= 0 ? 0 : undefined,
          max: data.max !== undefined && data.max < 10 ? data.max : undefined,
          labels: {
            formatter: function (value: number) {
              return `${
                (!value
                  ? 0
                  : value.toFixed(1).slice(-2) === ".0"
                  ? value
                  : value.toFixed(1)) + (percentages ? "%" : "")
              }`;
            },
          },
        },
        /*
				legend: {
					formatter: function(seriesName:any, opts:any) {
						console.log("XX")
						console.log(opts)
						return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
					}
				}
				*/
      },
    };
  } catch (error) {
    console.log("opterr: " + error);
  } finally {
    //console.log(opt.options)
  }

  const [test, setTest] = useState<any>(null);

  const graphRef = useRef<any>(null);

  useEffect(() => {
    //@ts-ignore -- Limitación de apexcharts?
    if (graphRef.current && graphRef.current.props.series.length > 5) {
      data.series.forEach(
        (serie: { name: string; data: number[] }, i: number) => {
          if (i > 5) graphRef?.current?.chart.hideSeries(serie.name);
        }
      );
    }
  }, [data]);

  useEffect(() => {
    setTest(data);
  }, [data]);

  if (!test) {
    return null;
  }

  return (
    <div className="chart-container">
      {/*@ts-ignore*/}
      <Line
        ref={graphRef}
        options={opt.options}
        series={test.series}
        type="line"
        width="100%"
        height={450}
      />
    </div>
  );
};

export default LineGraph;

import Bar from "react-apexcharts";
import locale from "./locale.es";
//import './style.scss'

const graphBorder = "#303437";
const graphBorderLight = "#7a8085";

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  //hue: number,
  //annotation: string,
  horizontal?: boolean;
  overlapped?: boolean;
  percentages?: boolean;
  forceNiceScale?: boolean;
};

const BarGraph = ({
  data,
  percentages,
  horizontal = false,
  overlapped = false,
  forceNiceScale = false,
}: IBarProps) => {
  const tickAmount =
    data.min !== undefined &&
    data.max !== undefined &&
    data.stepSize !== undefined
      ? (Math.abs(data.min) + Math.abs(data.max)) / data.stepSize
      : 1;
  //console.log(tickAmount, data.min, data.max)
  /*const annotationColors = {"especialidad": "#FEB019", "segmento": "#0094f2", "mercado": "#0F9D58", "other": "#DB4437"}

	function getAnnotations(){
		let annotations:any[] = [];
		try {
			if(data.annotation.length===0) return [];
			data.annotation.forEach((annotation:IAnnotation)=>{
				annotations.push({
					y: annotation.value,
					borderColor: annotationColors[annotation.type],
					label: {
					borderColor: annotationColors[annotation.type],
					style: {
						color: "#fff",
						background: annotationColors[annotation.type],
						fontSize: "13px",
						padding: {
							left: 4,
							right: 4,
							top: 10,
							bottom: 10,
						}
					},

					orientation: "vertical",
					text: annotation.label
					}
				})
			})
		} catch (error) {
			console.log("!!!"+error)
		} finally{
			//console.log("annotations: "+annotations)
			return annotations;
		}


	}
	*/

  let opt = { options: {} };
  try {
    opt = {
      options: {
        colors: data.colors,
        plotOptions: {
          bar: {
            horizontal: horizontal,
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 1,
            },
          },
        },
        chart: {
          id: "bargraph",
          stacked: true,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: -66,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            export: {},
            autoSelected: "zoom",
          },
          locales: [locale],
          defaultLocale: "es",
          fontFamily:
            "Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial, sans-serif",
          parentHeightOffset: 0,
          height: "auto",
          foreColor: "#ffffff",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          formatter: (value: number) =>
            value < 3 && value > -3
              ? `${value + (percentages ? "%" : "")}`
              : `${value + (percentages ? "%" : "")}`,
          style: {
            fontSize: "13px",
            fontFamily:
              "Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
        },
        stroke: {
          curve: "smooth",
          width: 0,
        },
        markers: {
          size: 0,
        },
        grid: {
          borderColor: graphBorder,
          [horizontal ? "xaxis" : "yaxis"]: {
            lines: {
              show: true,
            },
          },
          [horizontal ? "yaxis" : "xaxis"]: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 360,
              },
            },
          },
        ],
        tooltip: {
          theme: "light",
          shared: true,
          intersect: false,
          y: {
            formatter: (value: number) =>
              value
                ? `${value.toString().replaceAll(".", ",") ?? value}${
                    percentages ? " %" : ""
                  }`
                : value,
            title: {
              formatter: () => "",
            },
            shared: true,
            intersect: false,
          },
          x: { show: false },
          style: {
            fontSize: "16px",
            color: "#000",
          },
        },
        // Siempre mostramos la línea del cero con color graphBorderLight:
        annotations: {
          [horizontal ? "xaxis" : "yaxis"]: [
            {
              [horizontal ? "x" : "y"]: 0,
              borderColor: graphBorderLight,
              strokeDashArray: 0,
            },
          ],
        },
        [horizontal ? "yaxis" : "xaxis"]: {
          categories: horizontal ? [] : data.labels,
          axisBorder: {
            show: !horizontal,
            color: graphBorderLight,
            height: 1,
            width: "100%",
            offsetY: -1,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: graphBorderLight,
          },
          labels: {
            style: {
              fontSize: "11px",
            },
          },
          crosshairs: {
            width: 1,
          },
        },
        [horizontal ? "xaxis" : "yaxis"]: {
          forceNiceScale: false || false,
          axisBorder: {
            show: horizontal,
            color: graphBorderLight,
            height: 1,
            width: "100%",
            offsetY: -1,
          },
          categories: horizontal ? data.labels : [],
          tickAmount: "dataPoints",
          min: data.min,
          max: data.max,
          labels: {
            hideOverlappingLabels: false,
            formatter: function (val: number) {
              let value = Math.round(val);
              return `${
                (!value
                  ? 0
                  : value.toFixed(1).slice(-2) === ".0"
                  ? value
                  : value.toFixed(1)) + (percentages ? "%" : "")
              }`;
            },
          },
          crosshairs: {
            width: 1,
          },
        },
        legend: {
          markers: {
            radius: 12,
          },
        },
      },
    };
  } catch (error) {
    console.log("opterr: " + error);
  } finally {
    //console.log(opt.options)
  }

  let ret = <></>;

  try {
    ret = (
      <div className="chart-container">
        {/*@ts-ignore*/}
        <Bar
          options={opt.options}
          series={data.series}
          type="bar"
          width="100%"
          height={500}
        />
      </div>
    );
  } catch (error) {
    console.log("Error return: " + error);
  } finally {
    return ret;
  }
};

export default BarGraph;

import React from 'react';
import Title from '../../components/Title';
import { PrimaryButton } from '@fluentui/react';


const Configuracion = () => {
	return(
		<section>
			<Title title={"Configuración"}/>
			<PrimaryButton
				iconProps={{ iconName: 'SignOut' }}
				text="Cerrar sesión"
				onClick={_logout}
				allowDisabledFocus
			/>
		</section>
	)
}

function _logout(): void {
	window.localStorage.removeItem('auth-token');
	window.location.reload();
}

export default Configuracion;
import React from "react";
import CrudTable from "../../components/CrudTable";
import Title from "../../components/Title";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { normalizeBool } from "../../utils/functions";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { IEspecialidad } from "../../AppContext";
import exportFromJSON from "export-from-json";
import moment from "moment";

const Especialidades = () => {
  const addQuery = gql`
    mutation ($descripcion: String!) {
      setEspecialidad(input: { Descripcion: $descripcion }) {
        UUID
        Descripcion
        Error
      }
    }
  `;
  const setQuery = gql`
    mutation ($currId: String!, $descripcion: String!) {
      setEspecialidad(input: { UUID: $currId, Descripcion: $descripcion }) {
        UUID
        Descripcion
        Error
      }
    }
  `;

  const deleteQuery = gql`
    mutation ($currId: String!) {
      deleteEspecialidad(input: { UUID: $currId }) {
        Completed
        Error
      }
    }
  `;

  const getQuery = gql`
    query {
      getEspecialidad {
        UUID
        Descripcion
        hasCadenas
      }
    }
  `;

  const { data, refetch, loading: getLoading } = useQuery(getQuery);

  const source = {
    datafields: [
      { name: "UUID", type: "string", readonly: true },
      { name: "Descripcion", type: "string" },
      { name: "hasCadenas", type: "bool", readonly: true },
    ],
    datatype: "json",
    root: "",
    id: "UUID",
    sortcolumn: "Descripcion",
    localdata: data,
  };
  const columns = [
    { text: "Descripción", datafield: "Descripcion" },
    {
      text: "Cadenas",
      datafield: "hasCadenas",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
  ];

  const [getData, { loading }] = useLazyQuery(getQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (fetchedData) => {
      const data: any[] = [];
      fetchedData.getEspecialidad.forEach((especialidad: IEspecialidad) => {
        let s = {
          Descripción: especialidad.Descripcion,
          Cadenas: especialidad.hasCadenas ? "Sí" : "No",
        };
        data.push(s);
      });
      exportFromJSON({
        data: data,
        exportType: exportFromJSON.types.xls,
        fileName: `${moment().format("YYYY-MM-DD hh-mm-ss")} - Especialidades`,
      });
    },
  });

  let isLoading = loading || getLoading;

  return (
    <>
      <Title title={"Especialidades"} />
      {isLoading && (
        <div className="spinner-container small">
          <Spinner size={SpinnerSize.medium} />
        </div>
      )}
      <div className={`table-container small ${isLoading ? "loading" : ""}`}>
        <CrudTable
          label={"Especialidad"}
          itemName={"Especialidad"}
          items={data}
          source={source}
          columns={columns}
          addQuery={addQuery}
          setQuery={setQuery}
          deleteQuery={deleteQuery}
          width={60}
          refetch={refetch}
          exportFunc={getData}
        />
      </div>
    </>
  );
};

export default Especialidades;

const locale = {
	"name": "es",
	"options": {
	  "months": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	  "shortMonths": ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
	  "days": ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
	  "shortDays": ["Dom","Lun","Mar","Mié","Jue","Vie","Sáb"],
	  "toolbar": {
		  "exportToSVG": "Descargar SVG",
		  "exportToPNG": "Descargar PNG",
		  "exportToCSV": "Descargar CSV",
		  "menu": "Menú",
		  "selection": "Selección",
		  "selectionZoom": "Zoom",
		  "zoomIn": "Zoom In",
		  "zoomOut": "Zoom Out",
		  "pan": "Panning",
		  "reset": "Resetear Zoom"
	  }
	}
}

export default locale;
import React from "react";
import { Text } from "@fluentui/react";
import ManualIcon from "./ManualIcon";
import styled from "styled-components";
import { IWeeks } from "../AppContext";

const TitleContainer = styled.div`
  letter-spacing: -0.5px;
  padding-bottom: 40px;
  color: var(--text-color);
  user-select: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-size: 28px;
    @media (max-width: 900px) {
      font-size: 23px;
    }
  }
`;

const Title = ({
  title,
  files,
  error,
}: {
  title: string;
  files?: {
    data: any;
    semanas: IWeeks;
    user: string;
    title: string;
    leyenda: string;
    transposed?: boolean;
    secTransposed?: boolean;
  };
  error?: boolean;
}) => {
  return (
    <TitleContainer>
      <Text nowrap block>
        {title}
      </Text>
      {files !== undefined && <ManualIcon files={files} error={!!error} />}
    </TitleContainer>
  );
};

export default Title;

import React, { useContext, useMemo } from "react";
import Title from "../../../components/Title";
import BarGraph from "../../../components/BarGraph";
import { Detalle } from "../../../utils/constants";
import AppContext, { IFilters } from "../../../AppContext";
import { gql, useQuery } from "@apollo/client";
import {
  getStepsFixed as getSteps,
  getMinFixed as getMin,
  getMaxFixed as getMax,
} from "../../../utils/functions";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";

export const title = `Tendencias por ubicación`;
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: false,
  isDesglose: true,
  showEntorno: false,
  showSegmentos: true,
  showCanal: false,
};

let testData = [
  {
    Mercado: {
      Ventas: {
        Calle: 24.6,
        "Centro comercial": 26.6,
        Otros: 14.9,
        Total: 21.7,
      },
      Tickets: {
        Calle: 21.7,
        "Centro comercial": 21.5,
        Otros: 12.6,
        Total: 18.2,
      },
      TicketMedio: {
        Calle: 2.4,
        "Centro comercial": 4.2,
        Otros: 4.4,
        Total: 2.9,
      },
      Error: false,
    },
  },
  {
    Casual: {
      Ventas: {
        Calle: 11.2,
        "Centro comercial": 19.6,
        Otros: 35.9,
        Total: 15.9,
      },
      Tickets: {
        Calle: 0.3,
        "Centro comercial": 11.5,
        Otros: 43.2,
        Total: 8,
      },
      TicketMedio: {
        Calle: 10.8,
        "Centro comercial": 7.3,
        Otros: 7.7,
        Total: 7.3,
      },
      Error: false,
    },
  },
  {
    QSR: {
      Ventas: {
        Calle: 27.8,
        "Centro comercial": 29.1,
        Otros: 14.6,
        Total: 22.7,
      },
      Tickets: {
        Calle: 23.4,
        "Centro comercial": 22.6,
        Otros: 12.3,
        Total: 18.8,
      },
      TicketMedio: {
        Calle: 3.6,
        "Centro comercial": 5.3,
        Otros: 4.3,
        Total: 3.2,
      },
      Error: false,
    },
  },
];

let qry2 = [
  {
    Mercado: {
      Ventas: {
        Calle: 18.2,
        "Centro comercial": 24.7,
        Otros: 16.4,
        Total: 19.2,
      },
      Tickets: {
        Calle: 19.6,
        "Centro comercial": 18.3,
        Otros: 11,
        Total: 16.1,
      },
      TicketMedio: {
        Calle: -1.2,
        "Centro comercial": 5.4,
        Otros: 5.8,
        Total: 2.7,
      },
      Error: false,
    },
  },
  {
    Casual: {
      Ventas: {
        Calle: 0.9,
        "Centro comercial": 16.8,
        Otros: 27.6,
        Total: 8.8,
      },
      Tickets: {
        Calle: -6,
        "Centro comercial": 9,
        Otros: 38.1,
        Total: 2.9,
      },
      TicketMedio: {
        Calle: 7.4,
        "Centro comercial": 7.1,
        Otros: 8.8,
        Total: 5.8,
      },
      Error: false,
    },
  },
  {
    QSR: {
      Ventas: {
        Calle: 22.3,
        "Centro comercial": 27.5,
        Otros: 16.2,
        Total: 21,
      },
      Tickets: {
        Calle: 21.7,
        "Centro comercial": 19.3,
        Otros: 10.8,
        Total: 16.9,
      },
      TicketMedio: {
        Calle: 0.5,
        "Centro comercial": 6.8,
        Otros: 5.7,
        Total: 3.5,
      },
      Error: false,
    },
  },
];

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  let isDesglosado = filters.detalle === Detalle.Desglose;

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
    ) {
      graph(
        Graph: "Tendencias por ubicación"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 0, //filters.variables
    },
  });

  /*
  // get minimum value of an array of arrays
  const getMin = (arr: any) => {
    let min = 0;
    arr.forEach((a: any) => {
      let minA = Math.min(...a);
      if (minA < min) min = minA;
    });
    return min;
  };

  // get maximum value of an array of arrays
  const getMax = (arr: any) => {
    let max = 0;
    arr.forEach((a: any) => {
      let maxA = Math.max(...a);
      if (maxA > max) max = maxA;
    });
    return max;
  };*/

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading;
  let error = !semanas || !!qry.error;
  let labels: string[] = [],
    values_total: any = useMemo(() => [], []),
    values_desglose: any = useMemo(() => [], []);

  let data: any;
  if (!loading && !error && qry.data.graph) {
    try {
      data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      let segmentStr = filters.segmentos[filters.segment] || "Mercado";
      if (segmentStr === "Total") segmentStr = "Mercado";
      let segmentPos = data.filter(
        (d: any) => Object.keys(d)[0] === segmentStr
      );
      let cData: any = Object.values(data[data.indexOf(segmentPos[0])])[0];

      labels = Object.keys(cData.Ventas);

      values_total = [Object.values(cData.Ventas)];
      values_desglose = [
        Object.values(cData.Tickets),
        Object.values(cData.TicketMedio),
      ];
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  let max: number | undefined = useMemo(
    () => getMax([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );
  let min: number | undefined = useMemo(
    () => getMin([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );

  min = getMin([...values_total, ...values_desglose]);
  max = getMax([...values_total, ...values_desglose]);

  const stepSize = useMemo(() => getSteps(min, max), [min, max]);

  // round max to next integer
  max = Math.ceil(max + 1);
  // round min to previous integer
  min = Math.floor(min);

  //

  let values = isDesglosado ? values_desglose : values_total;

  const graphData = {
    datalabels: isDesglosado ? ["Tickets", "Ticket medio"] : ["Ventas"],
    labels: labels,
    values: values,
    colors: isDesglosado ? ["#0C4D7B", "#85b709"] : ["#0e6773"],
    annotation: [],
    series: isDesglosado
      ? [
          { name: "Tickets", data: values[0] ?? [] },
          { name: "Ticket medio", data: values[1] ?? [] },
        ]
      : [{ name: "Ventas", data: values[0] }],
    min: min, //Math.min(...values.flat()) >= 0 ? 0 : min,
    max: max,
    //min: Math.min(...values.flat()) >= 0 ? 0 : min,
    //max: max,
    stepSize: stepSize,
  };

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{ data, semanas, user, title, leyenda: "Ubicación" }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {!error && (
        <BarGraph
          data={graphData}
          horizontal={true}
          percentages
          forceNiceScale
        />
      )}
    </>
  );
};

export default Graph;

import './style.scss'

const AvisoLegal = () => {
	return(
		<div className="table-container table-manual">
			<div className="table">
			<div>
				<div style={{ position: "relative" }}>
					<div data-automation-id="visibleContent">
						<div className="ms-FocusZone ms-CommandBar manual-CommandBar" role="menubar" data-focuszone-id="FocusZone9">
							<div role="none" className="ms-OverflowSet ms-CommandBar-primaryCommand">
								<div className="ms-OverflowSet-item">
									<div role="menuitem" className="ms-Button ms-Button--commandBar ms-CommandBarItem-link" data-is-focusable="true">
										<span className="ms-Button-flexContainer flexContainer-60" data-automationid="splitbuttonprimary">
											<span className="ms-Button-textContainer textContainer-61">
												<span className="ms-Button-label" id="id__10">Para acceder al Monitor de ventas, debe leer y aceptar los siguientes términos:</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				<div className="container">
						<div className="">
						<p>El acceso al servicio denominado Monitor de Ventas en Restauración (MVR) ofrecido a través de la web de Ameba Research S.L. aplica las siguientes premisas en cuanto a su privacidad y condiciones de uso:
	El uso de la información, los datos y reportes contenidos en el MVR es  exclusivo para los clientes del mismo bajo las condiciones establecidas en el contrato. Además, la información expuesta a través de dicho servicio es propiedad exclusiva de Ameba Research S.L. y se encuentra protegida, por lo que su uso indebido o su reproducción, comunicación y publicación, total o parcial, están prohibidos, salvo con permiso expreso de su propietario. 
	El acceso a los datos o reportes del MVR por parte de terceros debe ser comunicado a Ameba Resarch S.L. de inmediato, mediante e-mail enviado a soporte@amebaresearch.com, indicando la vía de acceso a los datos. Si Vd. No es cliente de Ameba Research, S.L. o accede a datos que no ha contratado o que no forman parte de su servicio deberá informar de forma inmediata a Ameba Research S.L. acerca de dicha circunstancia por el mismo procedimiento.
	Ameba Research, S.L. desarrollará los esfuerzos precisos para evitar errores y, en su caso, repararlos o actualizarlos lo antes posible, no pudiendo garantizar su inexistencia, ni que el contenido de la información se encuentre permanentemente actualizado. Si encuentra algún error en sus reportes o en los datos mostrados en la aplicación del MVR, por favor, notifíquenoslo lo antes posible.
	La información que se difunde por este medio, ya sea a título informativo o en el ámbito de alguno de los servicios prestados por Ameba Research S.L. a sus clientes, incluido el Monitor de Ventas en Restauración, incluye la reserva para Ameba Research, S.L. del derecho de eliminar o suspender su difusión, total o parcialmente y de modificar la estructura y contenido de este sitio web sin aviso previo, pudiendo incluso limitar o no permitir el acceso a todo, o parte de dicha información; buscando en el caso de Monitor de Ventas en Restauración un canal alternativo, a través del cual seguir prestando el servicio a sus suscriptores, conforme a las especificaciones técnicas del mismo. 
	El propósito de Ameba Research S.L. es el de mantener la calidad y actualización de esta información, evitando y minimizando posibles errores causados por fallos técnicos. Sin embargo, Ameba Research S.L. no garantiza que este servicio no pueda ser interrumpido o afectado por esos fallos de forma ocasional. En ese caso, Ameba Research S.L. aplicará sus mejores esfuerzos para el pronto restablecimiento del servicio conforme a los establecidos entre esta y sus clientes.
	Se prohíbe la reproducción total o parcial de los contenidos, por cualquier medio, de los datos, informaciones y reportes de este servicio, que es exclusivo para clientes de Ameba Research S.L.
	El acceso los reportes y a la información provista a través del Monitor de Ventas en Restauración (MVR), su aplicación y sus reportes, la información y datos contenidos en ellos, han sido elaborados por Ameba Research S.L. con el único objetivo de aportar valor a sus clientes. Ameba Research S.L. aplica su mejor esfuerzo en proporcionar un reflejo fiel del mercado a través de la agregación, el proceso y el análisis de los datos de los participantes en el servicio. 
	En este sentido, Ameba Research, S.L., no se hace responsable del uso que pueda hacerse de la información que contiene, ya sean reportes adicionales, toma de decisiones o conclusiones en base a dichos datos, que son de la exclusiva responsabilidad del usuario.
	Ameba Research, S.L. no se responsabilizará de ninguna consecuencia, daño o perjuicio que pudieran derivarse del uso de este sitio web, o de sus contenidos, incluidos daños informáticos y la introducción de virus, con excepción de todas aquellas actuaciones que resulten de la aplicación de las disposiciones legales a las que deba someterse el estricto ejercicio de sus competencias.
	Se prohíbe la reproducción total o parcial de los contenidos de los reportes del Monitor de Ventas en Restauración (MVR), su aplicación on-line o su página web sin citar su origen y sin solicitar autorización expresa previa de la empresa.</p>
						</div>
					
				</div>
			</div>
		</div>
	)
}

export default AvisoLegal;

import React from 'react';
import CrudTable from '../../components/CrudTable';
import Title from '../../components/Title';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { normalizeBool } from '../../utils/functions';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { ICompania } from '../../AppContext';
import { Spinner, SpinnerSize } from '@fluentui/react';

const Companias = () => {
	

	const addQuery = gql`mutation($nombre: String!){
		setCompania(
		  input:{
			Nombre: $nombre
		  }
		){
			UUID
			Nombre
			Error
		}
	}`;
	const setQuery = gql`mutation($currId: String!, $nombre: String!){
		setCompania(
		  input:{
			UUID: $currId
			Nombre: $nombre
		  }
		){
			UUID
			Nombre
			Error
		}
	}`;
	const deleteQuery = gql`mutation($currId: String!){
		deleteCompania(
		  input:{
			UUID: $currId
		  }
		){
			Completed
			Error
		}
	}`;

	const getQuery = gql`query {
		getCompania {
			UUID
			Nombre
			hasCadenas
			hasUsuarios
		}
	}`;
	
	let { data, refetch, loading: getLoading } = useQuery(getQuery);

	const source = { 
		datafields: [
			{ name: 'UUID', type: 'string', readonly: true },
			{ name: 'Nombre', type: 'string' },
			{ name: 'hasCadenas', type: 'bool', readonly: true },
			{ name: 'hasUsuarios', type: 'bool', readonly: true }
		],
		datatype: 'json',
		root: '',
		id:'UUID',
		localdata: data
	}
	const columns = [
		{ text: 'Código', datafield: 'Nombre', width: 120},
		{ text: 'Cadenas', datafield: 'hasCadenas', cellsRenderer: (_r:any,_c:any,val:any)=>{return normalizeBool(val)}},
		{ text: 'Usuarios', datafield: 'hasUsuarios', cellsRenderer: (_r:any,_c:any,val:any)=>{return normalizeBool(val)}}
	]


	const [getData, { loading }] = useLazyQuery(getQuery, {
		fetchPolicy: "no-cache",
		onCompleted: fetchedData => {

			const data:any[] = []
			fetchedData.getCompania.forEach((compania:ICompania)=>{
				let c = {
					"Código": compania.Nombre,
					"Cadenas": compania.hasCadenas?"Sí":"No",
					"Usuarios": compania.hasUsuarios?"Sí":"No"
				}
				data.push(c)
			})
			exportFromJSON({
				data: data,
				exportType: exportFromJSON.types.xls,
				fileName: `${moment().format('YYYY-MM-DD hh-mm-ss')} - Grupos de cadenas`,
			})
		}
	});

	let isLoading = loading || getLoading
	
	return(
		<>
			<Title title={"Grupos de cadenas"}/>
			{isLoading && <div className="spinner-container small"><Spinner size={SpinnerSize.medium} /></div>}
			<div className={`table-container small ${isLoading?"loading":""}`}>
			<CrudTable
				label={"Grupo"}
				itemName={"Compania"}
				items={data}
				source={source}
				columns={columns}
				addQuery={addQuery}
				setQuery={setQuery}
				deleteQuery={deleteQuery}
				width={60}
				refetch={refetch}
				exportFunc={getData}
			/>
			</div>
		</>
	)
}

export default Companias;
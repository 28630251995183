import React, { useContext, useMemo, useEffect, useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";
import AppContext, { IFilters } from "../../AppContext";
import { Entorno } from "../../utils/constants";

import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import Chainz from "../../dictionaries/Chainz.json";

import TempJSON from "./test.json";

import Title from "../../components/Title";
import * as XLSX from "xlsx";
import "./style.scss";
import {
  CommandBar,
  ContextualMenuItemType,
  Dropdown,
  ICommandBarItemProps,
  IDropdownOption,
  IDropdownStyles,
  Stack,
  IStackTokens,
  DefaultButton,
  PrimaryButton,
  IIconProps,
} from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";
import Notification, {
  INotification,
} from "../../components/InlineNotification";
import { useDropzone } from "react-dropzone";

export const title = `Comparador de tendencia`;
export const filters: IFilters = {
  limitedPeriodo: true,
  showMedidas: true,
  hasTicketMedio: true,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: true,
  showMyData: false,
};

export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

const ClientesRevo = () => {
  const { semanas, filters, user } = useContext(AppContext);

  const GET_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!) {
      graph(
        Graph: "Get Revo Data"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
      )
    }
  `;

  const [fetchedDataState, setFetchedDataState] = useState<any>("");

  const [getData, { loading, error, data }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      client: "tbt2",
    },
    onCompleted: (fetchedData) => {
      setFetchedDataState(fetchedData.graph.file);
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;

  const dropdownStyles: Partial<IDropdownStyles> = {
    root: { display: "flex", alignSelf: "center", marginLeft: "6px" },
    label: {
      color: "var(--primary-color)",
      fontWeight: 400,
      padding: "7px 0px",
      fontSize: "13px",
      margin: "0px 6px 0px 0px",
    },
    title: {
      borderColor: "transparent",
      transition: "background 0.3s",
    },
    dropdown: {
      selectors: {
        "&:hover .ms-Dropdown-title, &:active .ms-Dropdown-title": {
          borderColor: "transparent",
          background: "#f3f2f1",
        },
        ":focus::after": {
          border: "1px solid transparent",
        },
      },
    },
    callout: {
      minWidth: 130,
    },
  };

  let weekOpts: IDropdownOption[] = [];
  let yearOpts: IDropdownOption[] = [];
  let clientOpts: IDropdownOption[] = [];
  clientOpts = [{ key: 1, text: "Black Turtle" }];
  const stackTokens: IStackTokens = { childrenGap: 40 };

  const containerStyles = {
    root: {
      position: "relative",
      marginBottom: -35,
      zIndex: 1,
      height: 32,
      padding: "0 0 0 8px",
      width: "calc(100% - 120px)",
      left: 120,
    },
  } as const;

  const [selectedKey, setKey] = React.useState(0);
  const downloadIcon: IIconProps = { iconName: "Download" };

  const changeClient = (_: any, item: any) => {
    setKey(item.key);
  };

  const style: any = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
  };

  return (
    <>
      <Title title={"Gestión de datos de clientes integrados con REVO"} />
      <div className="table-style" style={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Dropdown
            label="Cliente"
            placeholder="Seleccione cliente"
            options={clientOpts}
            styles={dropdownStyles}
            onChange={changeClient}
            className="grouped"
          />

          <Stack horizontal tokens={stackTokens}>
            {fetchedDataState === "" && (
              <PrimaryButton
                text="Iniciar consulta"
                onClick={() => {
                  getData();
                }}
                allowDisabledFocus
                disabled={selectedKey === 0 || loading}
                checked={false}
              />
            )}

            {fetchedDataState !== "" && (
              <PrimaryButton
                iconProps={downloadIcon}
                text={"Descargar datos de venta"}
                href={`${window.location.href}${fetchedDataState}`}
                download={true}
                allowDisabledFocus
              />
            )}
          </Stack>
        </div>
        {error && !loading && <p>{errorMsg}</p>}
        {selectedKey === 0 && (
          <p>
            Seleccione el cliente REVO del que desee consultar los datos de
            venta de la semana anterior..
          </p>
        )}
        {loading && (
          <p>Esta operación puede tardar unos minutos. Por favor, espere.</p>
        )}
        {!loading && fetchedDataState === "" && selectedKey > 0 && (
          <p>Pulse el botón para iniciar la consulta.</p>
        )}
        {!loading && fetchedDataState !== "" && (
          <p>Ya puede descargar el fichero de datos de venta.</p>
        )}
        {loading && (
          <Spinner style={{ paddingBottom: "20px" }} size={SpinnerSize.large} />
        )}
      </div>
    </>
  );
};

export default ClientesRevo;

import React, { useEffect } from "react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { FontIcon } from "@fluentui/react";
import { IFilter } from "../../AppContext";
import "./style.scss";

const onRenderCaretDown = (): JSX.Element => {
  return <FontIcon iconName="CaretSolidDown" />;
};

type IItem =
  | "periodo"
  | "medidas"
  | "variables"
  | "entorno"
  | "misdatos"
  | "detalle"
  | "palanca"
  | "leverType"
  | "lever"
  | "segment"
  | "segmentType";
const Picker = ({
  title,
  subtitle,
  values,
  item,
  filters,
  setFilters,
  disabled,
  onClick,
  obfuscate,
}: {
  title?: string;
  subtitle?: string;
  values: string[];
  item: IItem;
  filters: IFilter;
  setFilters: Function;
  disabled: boolean;
  onClick?: Function;
  obfuscate?: number;
}) => {
  useEffect(() => {
    if (values)
      values.forEach((val, i) => {
        if (val === "" && filters[item] === i)
          setFilters({ ...filters, [item]: i + 1 });
      });
  }, [values, item, filters, setFilters]);

  const options = () => {
    if (disabled) return [{ key: "0", text: "No aplica" }];
    let opts: IDropdownOption[] = [
      {
        key: "header",
        text: title || "",
        itemType: DropdownMenuItemType.Header,
      },
    ];
    if (!values) return opts;
    values.forEach((val, i) => {
      if (obfuscate && val.substring(0, 4) === "cad_") {
        val = val.substring(0, 7) + "***";
      }

      if (val !== "") opts.push({ key: `${i}`, text: val });
      //if(val===""&&filters[item]===i) setUpdateKey(i+1)
    });
    return opts;
  };

  const onChange = (_e: any, i: any): void => {
    let currentKey = i ? parseInt(i.key) : 0;
    setFilters({ ...filters, [item]: currentKey });
  };
  const handleTouchEnd = (): void => {
    if (onClick) onClick();
  };

  return (
    <div className="dropdown-container">
      <Dropdown
        selectedKey={`${filters[item]}`}
        onTouchEnd={handleTouchEnd}
        onChange={onChange}
        placeholder={disabled ? "No aplica" : title}
        onRenderCaretDown={onRenderCaretDown}
        options={options()}
        disabled={disabled}
      ></Dropdown>
      {title && (
        <span
          className={`dropdown-title${disabled ? " disabled" : ""}${
            subtitle ? " has-subtitle" : ""
          }`}
        >
          {title}
        </span>
      )}
      {title && (
        <span className={`dropdown-subtitle${disabled ? " disabled" : ""}`}>
          {subtitle}
        </span>
      )}
    </div>
  );
};

export default Picker;

import React from "react";
import CountUp from "react-countup";
import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
} from "@fluentui/react";
import "./style.scss";

const moneda = "€";

const counterShimmer = (
  <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
    <ShimmerElementsGroup
      flexWrap
      width={"100%"}
      shimmerElements={[
        {
          type: ShimmerElementType.line,
          width: 90,
          height: 17,
          verticalAlign: "top",
        },
        {
          type: ShimmerElementType.gap,
          width: "calc(100% - 90px)",
          height: 32,
        },
        {
          type: ShimmerElementType.line,
          width: "70%",
          height: 28,
          verticalAlign: "top",
        },
        { type: ShimmerElementType.gap, width: "30%", height: 31 },
        { type: ShimmerElementType.circle, height: 11 },
        { type: ShimmerElementType.gap, width: 6, height: 11 },
        { type: ShimmerElementType.line, width: 70, height: 11 },
        { type: ShimmerElementType.gap, width: 8, height: 11 },
        {
          type: ShimmerElementType.line,
          width: "calc(100% - 95px)",
          height: 11,
        },
      ]}
    />
  </div>
);

const Counter = ({
  title,
  value,
  showDivisa,
  growthValue,
  growthTitle,
  absoluteGrowth,
  isLoading,
  forcedDecimals,
}: {
  title: string;
  value: number;
  showDivisa?: boolean;
  growthValue?: number;
  growthTitle?: string;
  absoluteGrowth?: boolean;
  isLoading?: boolean;
  forcedDecimals?: number;
}) => {
  if (value === undefined || value === null) return <></>;
  if (isLoading)
    return <Shimmer customElementsGroup={counterShimmer} width="180px" />;
  let growth = absoluteGrowth ? "absolute" : "none";
  if (!absoluteGrowth && growthValue !== undefined)
    growth = growthValue === 0 ? "equal" : growthValue >= 0 ? "up" : "down";
  growthTitle = growthTitle ? growthTitle : "Tendencia";
  let growthValStr = !growthValue
    ? ""
    : growth === "absolute"
    ? growthValue + moneda
    : growthValue.toString().replace(".", ",") + "%";

  let decimals: number = forcedDecimals || 0;

  return (
    <div className="counter">
      <div className="counter-title">{title}</div>
      {/*@ts-ignore*/}
      <CountUp
        delay={0}
        end={value}
        decimal=","
        decimals={decimals ? decimals : Number.isInteger(value) ? 0 : 2}
        separator="."
        suffix={showDivisa ? moneda : ""}
      >
        {({ countUpRef }) => (
          <div className="counter-number">
            <span ref={countUpRef} />
          </div>
        )}
      </CountUp>
      <div className="content-growth">
        <div className={`value ${growth}`}>{growthValStr}</div>
        <div
          className="text"
          style={growthValue === undefined ? { padding: 0 } : {}}
        >
          {growthTitle}
        </div>
      </div>
    </div>
  );
};

export default Counter;

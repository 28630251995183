import { gql, useLazyQuery } from "@apollo/client";
import {
  Checkbox,
  IIconProps,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useContext, useEffect, useState } from "react";
import AppContext, { IFilters } from "../../../AppContext";
import Title from "../../../components/Title";
import Notification from "../../../components/Notification";
import "./style.scss";

export const title = "Descarga masiva de datos";
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: false,
  hasTicketMedio: false,
  isDesglose: false,
  showEntorno: false,
  showSegmentos: false,
  showMyData: false,
  onlyYears: true,
};

const Graph = ({ admin }: { admin?: boolean }) => {
  const { semanas, filters } = useContext(AppContext);
  const [data, setData] = useState<any>([]);
  //const [reloading, setReloading] = useState(false)
  const [links, setLinks] = useState<string[]>([]);
  const [format, setFormat] = useState<string>("XLSX");
  const [horizontal, setHorizontal] = useState(false);

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $yr2: Int!
      $var: String!
      $format: String!
      $alternativo: Boolean
    ) {
      graph(
        Graph: "Descarga masiva"
        WeekRange: {
          Start: { Year: $yr1, Week: 0 }
          End: { Year: $yr2, Week: 0 }
        }
        Filters: { Segmento: $var, Format: $format, Alternativo: $alternativo }
      )
    }
  `;

  const [getData, { loading, error }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    onCompleted: (fetchedData) => {
      setData(fetchedData);
      if (fetchedData?.graph?.Link.length > 0)
        setLinks(fetchedData?.graph?.Link);
    },
  });

  useEffect(() => {
    setData([]);
  }, [filters, semanas]);

  let errorMsg = `Se ha producido un error recuperando los datos`;
  //let loading=(qry.loading || qry.data===undefined)
  //let error=(!semanas || !!qry.error)
  let emptyData = {
    Total: {
      Ventas: { Total: 0, Tendencia: 0 },
      Tickets: { Total: 0, Tendencia: 0 },
      TicketMedio: { Total: 0, Tendencia: 0 },
      PuntosDeVenta: { Total: 0, Tendencia: 0 },
      VentaMediaLocal: { Total: 0, Tendencia: 0 },
    },
    Comparable: {
      Ventas: { Total: 0, Tendencia: 0 },
      Tickets: { Total: 0, Tendencia: 0 },
      TicketMedio: { Total: 0, Tendencia: 0 },
      PuntosDeVenta: { Total: 0, Tendencia: 0 },
      VentaMediaLocal: { Total: 0, Tendencia: 0 },
    },
    Aperturas: { Total: 0, Tendencia: 0 },
    Error: false,
  };
  let graph = emptyData;
  let isError = !semanas || !!error;
  const noData = data.length === 0;

  if (!noData) {
    try {
      graph = data?.graph;
      if (!graph) throw new Error(errorMsg);
      if (graph?.Error) throw new Error(`${data.Error}`);
    } catch (err: any) {
      isError = true;
      errorMsg =
        err.message !== "undefined" ? err.message : errorMsg ?? errorMsg;
    }
  }

  const downloadIcon: IIconProps = { iconName: "Download" };
  const excelIcon: IIconProps = { iconName: "ExcelDocument" };
  const csvIcon: IIconProps = { iconName: "TextDocument" };
  const [showNotification, { toggle: toggleNotification }] = useBoolean(true);

  let cSegmento = () => {
    if (filters.variables === 0) return "el total de los segmentos";
    let name = filters.segmentos[filters.variables];
    if (name.slice(-1) !== name.slice(-1).toUpperCase())
      name = name.toLowerCase();
    return `el segmento ${name}`;
  };

  const handleDetails = (_: any, checked: boolean | undefined) => {
    setHorizontal(!!checked);
  };

  return (
    <>
      <Title title={title} />
      {isError && (
        <Notification
          text={errorMsg}
          type={"error"}
          visible={showNotification}
          toggleVisibility={toggleNotification}
          autoHide={false}
        />
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="modal-container dark in-section"
          style={{
            width: "unset",
            height: "unset",
            padding: "unset",
            background: "unset",
            position: "relative",
          }}
        >
          <div
            className="table-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="table"
              style={{
                padding: 40,
                margin: "0 10px",
                width: "calc(100% - 300px)",
                minWidth: 280,
              }}
            >
              <div className="container" style={{ textAlign: "center" }}>
                {/*reloading && <Spinner label={"Recargando..."} size={SpinnerSize.large} />*/}
                {(loading || (!loading && !noData)) && (
                  <>
                    {loading && (
                      <Spinner
                        label={"Generando informe..."}
                        size={SpinnerSize.large}
                      />
                    )}
                    {!loading && (
                      <>
                        <Label style={{ paddingBottom: "2em" }}>
                          {isError
                            ? "No se ha podido generar el informe, inténtelo de nuevo más tarde"
                            : "Informe generado correctamente"}
                          .
                        </Label>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                          <div className="generate-buttons">
                            {links.map((link, i) => {
                              // if cad es undefined, a regex that gets com_ and 4 numbers from link
                              let com = link.match(/com_\d{4}/)?.[0];
                              // year
                              // get año and 4 numbers from link
                              let year = link.match(/Año \d{4}/)?.[0];

                              return (
                                <PrimaryButton
                                  key={i}
                                  iconProps={downloadIcon}
                                  text={
                                    format === "XLSX"
                                      ? "Descargar informe"
                                      : `${com} - ${year} - ${link
                                          .split(" ")[14]
                                          .replace(".xlsx", "")
                                          .replace(".csv", "")}`
                                  }
                                  href={`${window.location.href}${link}`}
                                  download={true}
                                  allowDisabledFocus
                                />
                              );
                            })}
                          </div>
                        </Stack>
                      </>
                    )}
                  </>
                )}
                {!loading && noData && (
                  <>
                    <Label style={{ paddingBottom: "2em" }}>
                      Se generará un informe para {cSegmento()} comparando el
                      año {semanas[1].year} con el año{" "}
                      {semanas[0].year === semanas[1].year
                        ? semanas[0].year - 1
                        : semanas[0].year}
                      .
                    </Label>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                      <div className="generate-buttons">
                        <PrimaryButton
                          iconProps={excelIcon}
                          text="Generar XLSX"
                          onClick={() => {
                            setFormat("XLSX");
                            getData({
                              variables: {
                                yr1: semanas[0].year,
                                yr2: semanas[1].year,
                                var: filters.segmentos[filters.variables],
                                alternativo: horizontal ?? false,
                                format: "XLSX",
                              },
                            });
                          }}
                          allowDisabledFocus
                        />
                        <PrimaryButton
                          iconProps={csvIcon}
                          text="Generar CSV"
                          onClick={() => {
                            setFormat("CSV");
                            getData({
                              variables: {
                                yr1: semanas[0].year,
                                yr2: semanas[1].year,
                                var: filters.segmentos[filters.variables],
                                alternativo: horizontal ?? false,
                                format: "CSV",
                              },
                            });
                          }}
                          allowDisabledFocus
                        />
                      </div>
                    </Stack>
                    {/*!!admin && (
                      <div className="check-container-small">
                        <Checkbox
                          id={"hrz-percentage"}
                          label={
                            "Descargar con formato de porcentajes horizontales"
                          }
                          className="ms-Checkbox-Filters"
                          checked={horizontal}
                          onChange={handleDetails}
                        />
                      </div>
												)*/}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!error && <></>}
    </>
  );
};

export default Graph;

import "./style.scss";
import { gql, useMutation } from "@apollo/client";
import {
  IStackProps,
  Link,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import Notification from "../../components/InlineNotification";

import { ReactComponent as BgImg } from "./img/bg.svg";
import { ReactComponent as Logo } from "./img/logo.svg";

const LOGIN_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { Email: $email, Password: $password }) {
      Token
      UUID
      canRead
      canWrite
      canDownload
      isAdmin
      Error
    }
  }
`;

const RESET_MUTATION = gql`
  mutation ($email: String!) {
    reset(input: { Email: $email }) {
      Error
    }
  }
`;

let usersWhiteList = [
  "643f2b04-6e13-4d94-99d7-98525fb2e187",
  "90d68906-c852-42c5-8187-99b86bceb311",
  "332be998-94ef-4add-ae22-e3668a6c160b",
  "000000000-0000-0000-0000-00000000000",
  "000000000-0000-0000-0000-00000000001",
  "000000000-0000-0000-0000-00000000002",
];

let hostBlackList = [
  "http://localhost:3000/",
  "https://app.amebaresearch.com",
  "https://test.amebaresearch.com",
  "https://app.amebaresearch.com/",
  "https://test.amebaresearch.com/",
];

const Login = ({ setToken }: { setToken: Function }) => {
  const [errorLogin, setErrorLogin] = useState("");
  const [errorReset, setErrorReset] = useState("");
  const [msgReset, setMsgReset] = useState("");
  const [formState, setFormState] = useState({
    user: "",
    password: "",
    email: "",
  });

  const [login] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: formState.user,
      password: formState.password,
    },
    onCompleted: ({ login }) => {
      let host = window?.location?.href;
      if (!login || (login.Error && login.Error !== "")) {
        setErrorLogin("Datos incorrectos");
        setToken(null);
        return "";
      } else {
        localStorage.setItem("auth-token", login.Token);
        setToken(login.Token);
      }
    },
  });

  const [reset] = useMutation(RESET_MUTATION, {
    variables: {
      email: formState.email,
    },
    onCompleted: ({ reset }) => {
      if (formState.email === "") {
        setErrorReset("Debe introducir un correo electrónico.");
        return;
      }
      if (!reset || (reset.Error && reset.Error !== "")) {
        setErrorReset("Error de conexión. Inténtelo de nuevo más tarde.");
      } else {
        setMsgReset(
          "Recibirá un correo con instrucciones si el correo introducido es válido."
        );
        setErrorReset("");
      }
    },
  });

  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
  };

  const [resetScreen, { toggle: toggleResetScreen }] = useBoolean(false);
  //const [showNotification, { toggle: toggleNotification }] = useBoolean(false);

  const styles = {
    root: {
      fontSize: "12px",
      alignSelf: "flex-end",
      marginTop: "8px !important",
      ":hover": {
        textDecoration: "none",
      },
    },
  } as const;

  return (
    <>
      <main id="login">
        <div className="login-panel">
          <BgImg />
        </div>
        <div className="login-form-container">
          <form className="login-form">
            <div className="logo">
              <Logo />
            </div>
            <Stack {...columnProps}>
              {!resetScreen && (
                <>
                  <TextField
                    label="Usuario"
                    type="email"
                    autoComplete="email"
                    required
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        login();
                      }
                    }}
                    onChange={(e: any) =>
                      setFormState({
                        ...formState,
                        user: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Contraseña"
                    type="password"
                    errorMessage={errorLogin}
                    autoComplete="current-password"
                    canRevealPassword
                    required
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        login();
                      }
                    }}
                    onChange={(e: any) =>
                      setFormState({
                        ...formState,
                        password: e.target.value,
                      })
                    }
                  />
                  <Link styles={styles} onClick={() => toggleResetScreen()}>
                    ¿Ha olvidado su contraseña?
                  </Link>
                  <PrimaryButton
                    id={"loginbtn"}
                    text="Iniciar sesión"
                    onClick={() => login()}
                    allowDisabledFocus
                    type="button"
                  />
                </>
              )}
              {resetScreen && msgReset !== "" && (
                <>
                  <Notification
                    text={msgReset}
                    type={"success"}
                    visible={true}
                    isMultiline={true}
                    toggleVisibility={() => setMsgReset("")}
                    autoHide={false}
                  />
                  <Link styles={styles} onClick={() => toggleResetScreen()}>
                    Volver a iniciar sesión
                  </Link>
                </>
              )}
              {resetScreen && msgReset === "" && (
                <>
                  <TextField
                    label="Correo electrónico"
                    type="email"
                    autoComplete="email"
                    errorMessage={errorReset}
                    required
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        reset();
                      }
                    }}
                    onChange={(e: any) =>
                      setFormState({
                        ...formState,
                        email: e.target.value,
                      })
                    }
                  />
                  <Link styles={styles} onClick={() => toggleResetScreen()}>
                    Volver a iniciar sesión
                  </Link>
                  <PrimaryButton
                    id={"resetbtn"}
                    text="Resetear contraseña"
                    onClick={() => reset()}
                    allowDisabledFocus
                    type="button"
                  />
                </>
              )}
            </Stack>
          </form>
        </div>
      </main>
    </>
  );
};

export default Login;

import "./style.scss";

const BetaWarning = () => {
  return (
    <div className="beta-warning">
      <p>
        <span className="apple">AVISO</span>: El Monitor de Ventas Ameba
        contiene novedades que están marcadas con
        <span
          className="is-new-no-anim"
          title="Este símbolo indica que la sección a la que acompaña contiene novedades"
        >
          &#9733;
        </span>
        <br />
        Puede comunicar sus comentarios, sugerencias e incidencias a través de
        nuestro correo{" "}
        <span className="e-mail">
          <a href="mailto:soporte@amebaresearch.com">
            soporte@amebaresearch.com
          </a>
        </span>
        &nbsp;¡Muchas gracias!
      </p>
    </div>
  );
};

export default BetaWarning;

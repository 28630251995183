import { KeyboardEvent, useEffect, useRef } from 'react';
import { getTheme, IconButton, IIconProps } from '@fluentui/react';

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonStyles = {
	root: {
	  color: theme.palette.neutralPrimary,
	  marginLeft: 'auto',
	  marginTop: '4px',
	  marginRight: '2px',
	},
	rootHovered: {
	  color: theme.palette.neutralDark,
	},
};



const ModalComponent = ({title,isOpen,hideModal,showModal,darkMode,children,hideCloseButton}:{title:string,isOpen:boolean,hideModal:any,showModal:any,darkMode?:boolean,children:any,hideCloseButton?:boolean}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	
	useEffect(() => {
		if(modalRef.current) modalRef.current.focus(); 
	}, [modalRef]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter' || event.key === 'Escape') {
			hideModal()
		}
	}
	
	return(
		<div className={`modal-container${darkMode?" dark":" light"}${isOpen?" visible":" hidden"}`}>
			<div className="modal" ref={modalRef} tabIndex={0} onKeyDown={handleKeyDown}>
				<div className="modal-topbar">
					<span>{title}</span>
					{!hideCloseButton && <IconButton
						styles={iconButtonStyles}
						iconProps={cancelIcon}
						ariaLabel="Close popup modal"
						onClick={hideModal}
					/>}
				</div>
				<div className="modal-content">{children}</div>
			</div>
		</div>
	)
}

export default ModalComponent;
import React, { useContext, useMemo } from "react";
import Title from "../../../components/Title";
import BarGraph from "../../../components/BarGraph";
import { Detalle } from "../../../utils/constants";
import AppContext, { IFilters } from "../../../AppContext";
import { gql, useQuery } from "@apollo/client";
import {
  getStepsFixed as getSteps,
  getMinFixed as getMin,
  getMaxFixed as getMax,
} from "../../../utils/functions";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";

export const title = `Tendencias por canal de servicio`;
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: false,
  isDesglose: true,
  showEntorno: false,
  showSegmentos: true,
  showMyData: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  let isDesglosado = filters.detalle === Detalle.Desglose;

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
    ) {
      graph(
        Graph: "Tendencias por canal de servicio"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 1,
      segment:
        filters.segmentType === 0
          ? filters?.segmentos[filters?.segment]
          : filters?.especialidades[filters?.segment],
      segmentType: filters.segmentType,
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let labels: string[] = [],
    values_total: any = useMemo(() => [], []),
    values_desglose: any = useMemo(() => [], []);

  let data: any;
  if (!loading && !error && qry.data.graph) {
    try {
      data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      let segmentStr = filters.segmentos[filters.segment] || "Mercado";
      if (segmentStr === "Total") segmentStr = "Mercado";
      let segmentPos = data.filter(
        (d: any) => Object.keys(d)[0] === segmentStr
      );
      //debugger
      let cData: any = Object.values(data[data.indexOf(segmentPos[0])])[0];

      labels = Object.keys(cData.Ventas);
      values_total = [Object.values(cData.Ventas)];
      values_desglose = [
        Object.values(cData.Tickets),
        Object.values(cData.TicketMedio),
      ];
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  let max: number | undefined = useMemo(
    () => getMax([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );
  let min: number | undefined = useMemo(
    () => getMin([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );

  const stepSize = useMemo(() => getSteps(min, max), [min, max]);

  // round max to next integer
  max = Math.ceil(max + 1);
  // round min to previous integer
  min = Math.floor(min);

  let values = isDesglosado ? values_desglose : values_total;

  const graphData = {
    datalabels: isDesglosado ? ["Tickets", "Ticket medio"] : ["Ventas"],
    labels: labels,
    values: values,
    colors: isDesglosado ? ["#0C4D7B", "#85b709"] : ["#0e6773"],
    annotation: [],
    series: isDesglosado
      ? [
          { name: "Tickets", data: values[0] ?? [] },
          { name: "Ticket medio", data: values[1] ?? [] },
        ]
      : [{ name: "Ventas", data: values[0] }],
    min: min, //Math.min(...values.flat()) >= 0 ? 0 : min,
    max: max,
    //min: Math.min(...values.flat()) >= 0 ? 0 : min,
    //max: max,
    stepSize: stepSize,
  };

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{ data, semanas, user, title, leyenda: "Canal" }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {!error && <BarGraph data={graphData} horizontal={true} percentages />}
    </>
  );
};

export default Graph;

export const light = {
	defaultFontStyle: { fontFamily: 'Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial', fontWeight: 'regular' },
	palette: {
		themePrimary: "#008299",
		themeLighterAlt: "#f1f9fb",
		themeLighter: "#c8e9ef",
		themeLight: "#9dd6e0",
		themeTertiary: "#4eb0c2",
		themeSecondary: "#148fa5",
		themeDarkAlt: "#00758a",
		themeDark: "#006374",
		themeDarker: "#004956",
		neutralLighterAlt: "#faf9f8",
		neutralLighter: "#f3f2f1",
		neutralLight: "#edebe9",
		neutralQuaternaryAlt: "#e1dfdd",
		neutralQuaternary: "#d0d0d0",
		neutralTertiaryAlt: "#c8c6c4",
		neutralTertiary: "#a19f9d",
		neutralSecondary: "#605e5c",
		neutralPrimaryAlt: "#3b3a39",
		neutralPrimary: "#323130",
		neutralDark: "#201f1e",
		black: "#000000",
		white: "#ffffff"
}}
export const dark = {
	defaultFontStyle: { fontFamily: 'Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial', fontWeight: 'regular' },
	palette: {
		themePrimary: '#0e6773',
		themeLighterAlt: '#020607',
		themeLighter: '#07171b',
		themeLight: '#363737',
		themeTertiary: '#1a5767',
		themeSecondary: '#268096',
		themeDarkAlt: '#3a9bb3',
		themeDark: '#52a9bf',
		themeDarker: '#79bed0',
		neutralLighterAlt: '#242526',
		neutralLighter: '#2e2f2f',
		neutralLight: '#363737',
		neutralQuaternaryAlt: '#1a1b1c',
		neutralQuaternary: '#191a1b',
		neutralTertiaryAlt: '#4c4d4e',
		neutralTertiary: '#fcfcfc',
		neutralSecondary: '#fcfcfc',
		neutralPrimaryAlt: '#fdfdfd',
		neutralPrimary: '#e5e5e5',
		neutralDark: '#ffffff',
		black: '#fefefe',
		white: '#1d1f20',
}};

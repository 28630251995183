import "./jqx.base.css";
import "./jqx.scss";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import JqxGrid, { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import es from "./es";
import {
  CommandBar,
  DefaultButton,
  Dialog,
  DialogType,
  ICommandBarItemProps,
  DialogFooter,
  PrimaryButton,
  Stack,
  TextField,
  IStackProps,
  IDropdownOption,
  DropdownMenuItemType,
  Dropdown,
  ContextualMenu,
  IModalProps,
} from "@fluentui/react";
import { gql, useMutation, DocumentNode, useQuery } from "@apollo/client";
import { useBoolean } from "@fluentui/react-hooks";
import Notification from "../Notification";
require("default-passive-events");

const CrudTable = ({
  label,
  itemName,
  items,
  source,
  columns,
  width,
  addQuery,
  setQuery,
  deleteQuery,
  refetch,
  readonly,
  blankHeader,
  onlyDelete,
  exportFunc,
}: {
  label: string;
  itemName: string;
  items: string | JSON;
  source: any;
  columns: any;
  width?: number;
  addQuery: DocumentNode;
  setQuery: DocumentNode;
  deleteQuery: DocumentNode;
  refetch: Function;
  readonly?: boolean;
  blankHeader?: boolean;
  onlyDelete?: boolean;
  exportFunc?: Function;
}) => {
  const [dialog, setDialog] = useState<{
    type: "add" | "set" | "";
    id: string | undefined;
    visible: boolean;
  }>({ type: "add", id: undefined, visible: false });
  const [showNotification, { toggle: toggleNotification }] = useBoolean(false);
  const [notification, setNotification] = useState<{
    text: string;
    type:
      | "default"
      | "info"
      | "error"
      | "blocked"
      | "severeWarning"
      | "success"
      | "warning";
  }>({ text: "", type: "default" });
  const [confirmation, setConfirmation] = useState<{
    description: string;
    operation: string;
    callback: Function | undefined;
    visible: boolean;
  }>({ description: "", operation: "", callback: undefined, visible: false });
  const [selected, setSelected] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const myGrid = React.createRef<JqxGrid>();

  function askConfirmation(
    description: string,
    operation: string,
    func: Function
  ) {
    setConfirmation({
      description: description,
      operation: operation,
      visible: true,
      callback: func,
    });
  }

  const MAIL_MUTATION = gql`
    mutation ($email: String!) {
      reset(input: { Email: $email, Initial: true }) {
        Error
      }
    }
  `;
  const [initialMail] = useMutation(MAIL_MUTATION);

  function sendMailRowClick() {
    const selectedrowindex = myGrid.current!.getselectedrowindex();
    const rowscount = myGrid.current!.getdatainformation().rowscount;
    let rowData: any = {};
    if (selectedrowindex >= 0 && selectedrowindex < parseFloat(rowscount!)) {
      rowData = myGrid.current!.getrowdata(selectedrowindex);
      askConfirmation(
        "Enviar un correo de bienvenida a este usuario",
        "Enviar",
        () => {
          setLoading(true);
          initialMail({ variables: { email: rowData.Email } }).then(
            (res: any) => {
              let reset = res.data.reset;
              if (!reset || reset?.Error !== "") {
                setNotification({
                  text: "No se ha podido enviar el correo. Inténtelo de nuevo más tarde.",
                  type: "error",
                });
                setTimeout(() => toggleNotification(), 1400);
                setTimeout(() => setLoading(false), 1400);
              } else {
                setNotification({
                  text: "Correo enviado correctamente.",
                  type: "success",
                });
                setTimeout(() => toggleNotification(), 1400);
                setTimeout(() => setLoading(false), 1400);
              }
            }
          );
        }
      );
    }
  }

  const [delRow] = useMutation(deleteQuery);

  const addRowClick = () => {
    setDialog({ type: "add", id: undefined, visible: true });
    if (myGrid && myGrid.current) myGrid.current.refresh();
  };

  const deleteRowClick = () => {
    const selectedrowindex = myGrid.current!.getselectedrowindex();
    const rowscount = myGrid.current!.getdatainformation().rowscount;
    let id = "";
    if (selectedrowindex >= 0 && selectedrowindex < parseFloat(rowscount!)) {
      id = myGrid.current!.getrowid(selectedrowindex);
      askConfirmation("Eliminar el registro", "Eliminar", () => {
        setLoading(true);
        delRow({ variables: { currId: id } }).then((e) => {
          let obj = e.data[Object.entries(e.data)[0][0]];
          if (obj && obj.Completed) {
            refetch();
            setNotification({
              text: "Se ha eliminado el registro con éxito.",
              type: "success",
            });
            setTimeout(() => toggleNotification(), 1400);
            setTimeout(() => setLoading(false), 1400);
          } else {
            let error = obj
              ? obj.Error
              : "No se ha podido eliminar el registro.";
            setNotification({ text: error, type: "error" });
            setTimeout(() => toggleNotification(), 1400);
            setTimeout(() => setLoading(false), 1400);
          }
        });
      });
    }
  };

  const updateRowClick = () => {
    const selectedrowindex = myGrid.current!.getselectedrowindex();
    const rowscount = myGrid.current!.getdatainformation().rowscount;
    let id = "";
    if (selectedrowindex >= 0 && selectedrowindex < parseFloat(rowscount!)) {
      id = myGrid.current!.getrowid(selectedrowindex);
      setDialog({ type: "set", id: id, visible: true });
      myGrid.current!.refresh();
    }
  };

  let deleteBtn: ICommandBarItemProps[] = [
    {
      key: "delete",
      text: "Eliminar",
      iconProps: { iconName: "Delete" },
      disabled: !(selected !== undefined && selected >= 0),
      onClick: deleteRowClick,
    },
  ];
  let buttons: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: "Añadir",
      cacheKey: "myCacheKey",
      iconProps: { iconName: "Add" },
      onClick: addRowClick,
    },
    {
      key: "edit",
      text: "Editar",
      disabled: !(selected !== undefined && selected >= 0),
      iconProps: { iconName: "Edit" },
      onClick: updateRowClick,
    },
    ...deleteBtn,
  ];

  if (columns.filter((column: any) => column.text === "Correo").length > 0) {
    buttons.push({
      key: "send",
      text: "Correo de bienvenida",
      iconProps: { iconName: "MailReplyMirrored" },
      disabled: !(selected !== undefined && selected >= 0),
      onClick: sendMailRowClick,
    });
  }
  let farButtons: ICommandBarItemProps[] = [
    {
      key: "export",
      text: "Exportar",
      iconProps: { iconName: "Download" },
      onClick: () => {
        if (!!exportFunc) exportFunc();
        if (!exportFunc) {
          myGrid.current!.exportdata(
            "xls",
            `Registros ${label.toLowerCase()}`,
            true,
            undefined,
            false,
            "http://jquerygrid.net/export_server/dataexport.php"
          );

          //console.log("Por implementar")
          //myGrid.current.props.source.records
          //myGrid.current.props.columns
        }
      },
    },
  ];

  const jqxdata = {
    columns: columns,
    source: new jqx.dataAdapter(source),
  };

  /*
	const makeExcel = async (columns: string[], data: any[][], title:string) => {
		if(!data) return

		const pink:IExcelColor = {
			main: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00EAB8CB' } }, font: {color: {argb: "00ffffff"}} },
			title: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00E19BB6' } }, font: {color: {argb: "00000000"}} }
		}
		const green:IExcelColor = {
			main: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '009CC97D' } }, font: {color: {argb: "00ffffff"}} },
			title: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '007FB957' } }, font: {color: {argb: "00ffffff"}} }
		}
		const blue:IExcelColor = {
			main: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00DDEBF7' } }, font: {color: {argb: "00000000"}} },
			title: { bg: { type: 'pattern', pattern: 'solid', fgColor: { argb: '001F4E78' } }, font: {color: {argb: "00BDD7EE"}} }
		}

		const end:Fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00000000' } }

		const vals: any[][] = data.map((d:any[])=>Object.values(d)[0])
		const tabs: string[] = data.map((d:any[])=>Object.keys(d)[0])

		try{
			const workbook = new Workbook();

			tabs.forEach((tab,t)=>{
				let sheet = workbook.addWorksheet(tab)
				let col = 2

				let cols: string[] = (Object.keys(vals[t])).filter(col=>col!=="Error")
				let rows: string[] = ["", ...Object.keys(Object.values(vals[t])[0])]

				sheet.getColumn(1).width = 15
				for (let c = 2;c<cols.length+col;c++){
					sheet.getColumn(c).width = 18
				}


				for (let row=1;row<=rows.length+1;row++){
					if(row===rows.length+1){
						sheet.getRow(row).getCell(1).fill = end
					}else{
						if(row===2) {
							sheet.getRow(row).getCell(1).font = pink.title.font
						}
						sheet.getRow(row).getCell(1).fill = row===2?pink.title.bg:pink.main.bg
						let key = rows[row-1]
						if(typeof key==="string"&&key.slice(0,5)==="temp_") key=""
						sheet.getRow(row).getCell(1).value = key
					}
				}

				let colStart = col
				let colEnd = cols.length+col

				for (let row=1;row<=rows.length+1;row++){
					for (let c = colStart;c<colEnd;c++){
						if(row===rows.length+1){
							sheet.getRow(row).getCell(c).fill = end
						}else{
							if(row===2) {
								sheet.getRow(row).getCell(c).font = green.title.font
								sheet.getRow(row).getCell(c).value = cleanTitle(cols[c-2])
							}
							let cData = data.filter((d:any)=>Object.keys(d)[0]===tab)[0][tab]

							// @ts-ignore
							let val:string|undefined = Object.values(cData[cols[c-2]])[row-3]

							//if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

							sheet.getRow(row).getCell(c).fill = row===2?green.title.bg:green.main.bg
							if(!!val) {
								let noPercentage = typeof val !== "number" && val.includes("#")
								sheet.getRow(row).getCell(c).value = `${noPercentage?val.replace("#",""):val}${noPercentage?"":"%"}`
							}
						}
					}
				}

				// Titles:
				sheet.getCell('B1').value = tab;
				sheet.getCell('B1').font = {color: {argb: "00375623"}}
				sheet.getCell('B1').font.bold = true;
				sheet.mergeCells(1,2,1,cols.length+1);
				sheet.getRow(1).alignment = {horizontal: 'center'}
				sheet.getRow(2).alignment = {horizontal: 'center'}
			})


			const buf = await workbook.xlsx.writeBuffer()
			saveAs(new Blob([buf]), `${title}.xlsx`)
		}catch(err:any){
			console.log(err)
		}
	}
	*/

  const rendertoolbar = (toolbar: any): void => {
    ReactDOM.render(
      <CommandBar
        items={blankHeader ? [] : onlyDelete ? deleteBtn : buttons}
        farItems={blankHeader ? [] : farButtons}
      />,
      toolbar[0]
    );
  };

  return (
    <>
      <Notification
        text={notification.text}
        type={notification.type}
        visible={showNotification}
        toggleVisibility={toggleNotification}
      />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <CrudDialog
        label={label}
        itemName={itemName}
        items={items}
        columns={columns}
        source={source}
        dialog={dialog}
        setDialog={setDialog}
        refetch={refetch}
        addQuery={addQuery}
        setQuery={setQuery}
        setLoading={setLoading}
        setNotification={setNotification}
        toggleNotification={toggleNotification}
      />
      <div className={`jqx-container ${loading ? "loading" : ""}`}>
        {/*@ts-ignore*/}
        <JqxGrid
          ref={myGrid}
          localization={es}
          theme={"metro"}
          sortable={true}
          width={`${width ? width : 100}%`}
          autoheight={true}
          altrows={true}
          columnsresize={true}
          source={jqxdata.source}
          columns={jqxdata.columns}
          showtoolbar={!readonly}
          sortmode="many"
          // @ts-ignore
          rendertoolbar={readonly ? undefined : rendertoolbar}
          onRowselect={(row: any) => {
            setSelected(row.args.rowindex >= 0 ? row.args.rowindex : undefined);
          }}
        />
      </div>
    </>
  );
};

export default CrudTable;

export const CrudDialog = ({
  label,
  itemName,
  items,
  columns,
  source,
  dialog,
  setDialog,
  addQuery,
  setQuery,
  refetch,
  setLoading,
  setNotification,
  toggleNotification,
}: {
  label: string;
  itemName: string;
  items: any;
  columns: any;
  source: any;
  dialog: {
    type: "add" | "set" | "";
    id: string | undefined;
    visible: boolean;
  };
  setDialog: Function;
  addQuery: DocumentNode;
  setQuery: DocumentNode;
  refetch: Function;
  setLoading: Function;
  setNotification: Function;
  toggleNotification: Function;
}) => {
  const [addRow] = useMutation(addQuery);
  const [setRow] = useMutation(setQuery);

  const cDialogID = dialog.id;

  function cleanVarName(name: string) {
    return name.replace(/( |:)/g, "").toLowerCase();
  }
  function sendQuery() {
    let vars: any = {};
    let cVal: any;
    let cId: string;
    source.datafields.forEach((elem: { name: string; type: string }) => {
      cId = cleanVarName(itemName + elem.name);
      let obj: any = document.querySelector(`#${cId}`);
      if (obj) {
        if (obj.ariaHasPopup === "listbox") {
          let isPlaceholder = obj
            .querySelector("span")
            .classList.contains("ms-Dropdown-titleIsPlaceHolder");
          cVal = isPlaceholder ? "0" : obj.querySelector("span").innerText;
        } else {
          cVal = obj.value;
        }
        vars[cleanVarName(elem.name)] = getValidated(cVal, itemName, elem.name);
      }
    });

    if (dialog.type === "add") {
      setLoading(true);
      addRow({ variables: { ...vars } }).then((ev) => {
        refetch();
        setNotification({
          text: "Se ha añadido el registro con éxito.",
          type: "success",
        });
        setTimeout(() => toggleNotification(), 1400);
        setTimeout(() => setLoading(false), 1400);
      });
    } else if (dialog.type === "set") {
      setLoading(true);

      setRow({ variables: { currId: dialog.id, ...vars } }).then(() => {
        refetch();
        setTimeout(() => setLoading(false), 1400);
      });
    }

    toggleVisibility();
  }

  function getValidated(value: string, table: string, field: string) {
    switch (table) {
      case "Compania":
        if (field === "Nombre")
          return "com_" + value.padStart(6, "0").slice(0, 6);
        if (field === "Compania") return getUUID(value, companias);
        if (field === "Especialidad")
          return getUUIDs(value, especialidades, "string");
        if (field === "Segmento") return getUUIDs(value, segmentos, "string");
        if (field === "Ratio") return parseInt(value);
        return value;
      case "Cadena":
        if (field === "Nombre")
          return "cad_" + value.padStart(6, "0").slice(0, 6);
        if (field === "Compania") return getUUID(value, companias);
        if (field === "Especialidad")
          return getUUIDs(value, especialidades, "string");
        if (field === "Segmento") return getUUIDs(value, segmentos, "string");
        if (field === "Ratio") return parseInt(value);
        return value;
      case "Usuario":
        if (field === "Rol") return getUUID(value, roles);
        if (field === "Compania") return getUUID(value, companias);
        if (field === "Cadena") return getUUID(value, cadenas);
        return value;
      default:
        return value;
    }
  }

  const cleanType = { "": "", add: "Añadir", set: "Editar" };
  const dialogContentProps = {
    type: DialogType.normal,
    title: `${cleanType[dialog.type]} ${label.toLowerCase()}`,
    subText: `${
      dialog.type === "add"
        ? "Cumplimenta el siguiente formulario para añadir un nuevo registro."
        : "Modifica el siguiente formulario para editar el registro seleccionado."
    }`,
  };
  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
      dragOptions: {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
        keepInBounds: true,
      },
    }),
    []
  );
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 0 },
  };

  function cleanOpts(header: string, qry: { [x: string]: any }) {
    let result: IDropdownOption[] = [];
    if (!qry || !Object.keys(qry)[0]) return result;
    let elems = qry[Object.keys(qry)[0]];
    let isDesc = !!elems[0]?.Descripcion;
    let item: IDropdownOption;
    elems.forEach(
      (elem: { UUID: string; Descripcion: string; Nombre: string }) => {
        item = {
          key: elem.UUID,
          text: isDesc ? elem.Descripcion : elem.Nombre,
        };
        result.push(item);
      }
    );

    return header !== ""
      ? [
          {
            key: `${header}Header`,
            text: header,
            itemType: DropdownMenuItemType.Header,
          },
          ...result.sort((a, b) => a.text.localeCompare(b.text)),
        ]
      : result.sort((a, b) => a.text.localeCompare(b.text));
  }

  const qCompania = useQuery(gql`
    query {
      getCompania {
        UUID
        Nombre
      }
    }
  `);
  const qCadena = useQuery(gql`
    query {
      getCadena {
        UUID
        Nombre
      }
    }
  `);
  const qEspecialidad = useQuery(gql`
    query {
      getEspecialidad {
        UUID
        Descripcion
      }
    }
  `);
  const qSegmento = useQuery(gql`
    query {
      getSegmento {
        UUID
        Descripcion
      }
    }
  `);
  const qRol = useQuery(gql`
    query {
      getRolUsuario {
        UUID
        Descripcion
      }
    }
  `);

  let companias: IDropdownOption[] = qCompania.data
    ? cleanOpts("Compañía", qCompania.data)
    : [];
  let cadenas: IDropdownOption[] = qCadena.data
    ? cleanOpts("Cadena", qCadena.data)
    : [];
  let especialidades: IDropdownOption[] = qEspecialidad.data
    ? cleanOpts("Especialidad", qEspecialidad.data)
    : [];
  let segmentos: IDropdownOption[] = qSegmento.data
    ? cleanOpts("Segmento", qSegmento.data)
    : [];
  let roles: IDropdownOption[] = qRol.data
    ? cleanOpts("Rol de usuario", qRol.data)
    : [];

  function getPrefix() {
    switch (itemName) {
      case "Compania":
        return "com_";
      case "Cadena":
        return "cad_";
      default:
        return "";
    }
  }

  const fieldsRef: any = useRef();

  function getUUIDs(
    elem: any,
    opts: IDropdownOption[],
    type: "string" | "object" = "object"
  ) {
    let val = typeof elem === "string" ? elem : getVal(elem);
    if (!val || val.length === 0) return "";

    if (type === "string") {
      let vals = val.split(", ");
      let result: string = "";
      for (let v of vals) {
        let aux = opts.filter((e) => e.text === v)[0]?.key;
        result += aux + ",";
      }
      return result.slice(0, -1);
    } else {
      let key =
        val[0] && Object.keys(val[0])?.includes("Descripcion")
          ? "Descripcion"
          : "Nombre";
      let result: string[] = [];
      for (let v of val) {
        if (v) {
          let aux = opts.filter((e) => e.text === v[key])[0]?.key;
          if (aux !== undefined) result.push(aux.toString());
        }
      }
      return result;
    }
  }

  function getUUID(elem: any, opts: IDropdownOption[]) {
    let val = typeof elem === "string" ? elem : getVal(elem);
    /*if(itemName==="Usuario"){
			if(!val&&dialog.id&&elem.map==="Cadena>Nombre") setUserGroupType("compania")
			if(!val&&dialog.id&&elem.map==="Compania>Nombre") setUserGroupType("cadena")
		}*/

    if (!val) return "";
    let result = opts.filter((e) => e.text === val)[0]?.key;
    return result ? result : "0";
  }

  const [limitedValue, setLimitedValue] = useState<{
    val: string;
    id: string | undefined;
  }>({ val: "", id: undefined });
  const handleLimitedValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
    elem?: { name: string; type: string }
  ) => {
    if (!newValue || newValue.length <= 6) {
      setLimitedValue({
        val: newValue ? newValue.padStart(6, "0").slice(0, 6) : "",
        id: cDialogID,
      });
    }
  };

  function getVal(datafield: { name: string; map?: string; type: string }) {
    if (!items || !dialog.id) return undefined;
    let elems = items[Object.keys(items)[0]];
    let elem = elems.filter(
      (item: { UUID: string }) => item.UUID === dialog.id
    )[0];
    let newID = datafield.map ? datafield.map : datafield.name;
    let cElem = elem;

    if (!cElem || !newID) return undefined;
    if (newID.includes(">")) {
      while (newID.includes(">")) {
        cElem = cElem[newID.slice(0, newID.indexOf(">"))];
        newID = newID.slice(newID.indexOf(">") + 1);
      }
    }
    if (!cElem || !newID) return undefined;

    if (
      (elem["__typename"] === "Compania" || elem["__typename"] === "Cadena") &&
      datafield.name === "Nombre"
    ) {
      return cElem[newID].slice(4);
    }

    return cElem[newID];
  }

  const eCompania = {
    name: "Compania",
    map: "Compania>Nombre",
    type: "string",
    inputonly: true,
  };
  const eCadena = {
    name: "Cadena",
    map: "Cadena>Nombre",
    type: "string",
    inputonly: true,
  };

  const defaultGroupID = {
    cadena: { value: undefined, disabled: false },
    compania: { value: undefined, disabled: false },
  };

  const [groupID, setGroupID] = useState<{
    cadena: { value: any; disabled: boolean };
    compania: { value: any; disabled: boolean };
  }>(defaultGroupID);

  function toggleVisibility() {
    setDialog({ type: dialog.type, id: dialog.id, visible: !dialog.visible });
    setTimeout(() => setGroupID(defaultGroupID), 500);
  }

  function getDefaultKeys(
    elem: { name: string; type: string; readonly: boolean },
    opts: IDropdownOption[]
  ) {
    let UUIDs = getUUIDs(
      elem,
      elem.name === "Especialidad" ? especialidades : segmentos
    );
    if (typeof UUIDs === "object") return UUIDs;
    return [];
  }
  return (
    <>
      <Dialog
        hidden={!dialog.visible}
        onDismiss={toggleVisibility}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <div ref={fieldsRef}>
          <Stack {...columnProps}>
            {source.datafields.map(function (
              elem: { name: string; type: string; readonly: boolean },
              i: number
            ) {
              let cId: string = cleanVarName(itemName + elem.name);

              if (elem.readonly) return <div key={cId}></div>;
              let name = columns.filter(
                (e: { datafield: string }) => e.datafield === elem.name
              )[0]?.text;

              switch (elem.type) {
                case "string":
                  switch (elem.name) {
                    case "Nombre":
                      if (itemName === "Compania" || itemName === "Cadena") {
                        return (
                          <TextField
                            key={cId}
                            id={cId}
                            label={name}
                            type="number"
                            prefix={getPrefix()}
                            value={
                              limitedValue.id === cDialogID
                                ? limitedValue.val
                                : getVal(elem)
                                ? getVal(elem).padStart(6, "0").slice(0, 6)
                                : ""
                            }
                            onChange={(e, val) =>
                              handleLimitedValue(e, val, elem)
                            }
                            onKeyPress={(e) =>
                              setLimitedValue({
                                ...limitedValue,
                                val: parseInt(limitedValue.val).toString(),
                              })
                            }
                            min={0}
                            max={999999}
                            required
                          />
                        );
                      } else {
                        return (
                          <TextField
                            key={cId}
                            id={cId}
                            label={name}
                            defaultValue={getVal(elem)}
                            required
                          />
                        );
                      }
                    case "Rol":
                      return (
                        <Dropdown
                          key={cId}
                          id={cId}
                          placeholder="Elige un rol"
                          label="Rol del usuario"
                          options={roles}
                          defaultSelectedKey={getUUID(elem, roles)}
                        />
                      );
                    case "Compania":
                      if (itemName === "Usuario") {
                        let isCadena =
                          getUUID(eCompania, companias) === "" &&
                          getUUID(eCadena, cadenas) !== "";
                        if (isCadena)
                          return (
                            <Dropdown
                              disabled={true}
                              key={cId}
                              id={cId}
                              placeholder="Cadena asignada"
                              label="Compañía"
                              options={companias}
                            />
                          );
                        return (
                          <Dropdown
                            disabled={groupID.compania.disabled}
                            key={cId}
                            id={cId}
                            placeholder={"Elige una compañía"}
                            label="Compañía"
                            options={companias}
                            onChange={(e, i) => {
                              if (i) {
                                setGroupID({
                                  cadena: { value: undefined, disabled: true },
                                  compania: { value: i.key, disabled: false },
                                });
                              }
                            }}
                            selectedKey={
                              groupID.compania.value || getUUID(elem, companias)
                            }
                          />
                        );
                      }
                      return (
                        <Dropdown
                          key={cId}
                          id={cId}
                          placeholder="Elige una compañía"
                          label="Compañía"
                          options={companias}
                          defaultSelectedKey={getUUID(elem, companias)}
                        />
                      );
                    case "Cadena":
                      if (itemName === "Usuario") {
                        let isCompania =
                          getUUID(eCompania, companias) !== "" &&
                          getUUID(eCadena, cadenas) === "";
                        if (isCompania)
                          return (
                            <Dropdown
                              disabled={true}
                              key={cId}
                              id={cId}
                              placeholder="Compañía asignada"
                              label="Cadena"
                              options={cadenas}
                            />
                          );
                        return (
                          <Dropdown
                            disabled={groupID.cadena.disabled}
                            key={cId}
                            id={cId}
                            placeholder="Elige una cadena"
                            label="Cadena"
                            options={cadenas}
                            onChange={(e, i) => {
                              if (i) {
                                setGroupID({
                                  cadena: { value: i.key, disabled: false },
                                  compania: {
                                    value: undefined,
                                    disabled: true,
                                  },
                                });
                              }
                            }}
                            selectedKey={
                              groupID.cadena.value || getUUID(elem, cadenas)
                            }
                          />
                        );
                      }
                      return (
                        <Dropdown
                          key={cId}
                          id={cId}
                          placeholder="Elige una cadena"
                          label="Cadena"
                          options={cadenas}
                          defaultSelectedKey={getUUID(elem, cadenas)}
                        />
                      );
                    case "Especialidad":
                      return (
                        <Dropdown
                          key={cId}
                          id={cId}
                          placeholder="Elige especialidades"
                          label="Especialidades"
                          multiSelect
                          options={especialidades}
                          defaultSelectedKeys={getDefaultKeys(
                            elem,
                            especialidades
                          )}
                        />
                      );
                    case "Segmento":
                      return (
                        <Dropdown
                          key={cId}
                          id={cId}
                          placeholder="Elige segmentos"
                          label="Segmentos"
                          multiSelect
                          options={segmentos}
                          defaultSelectedKeys={getDefaultKeys(elem, segmentos)}
                        />
                      );
                    case "Contacto: Nombre":
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          label={"Nombre de contacto"}
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                    case "Contacto: Email":
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          type="email"
                          label={"Correo de contacto"}
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                    case "Contacto: Teléfono":
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          type="tel"
                          label={"Teléfono de contacto"}
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                    default:
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          label={name}
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                  }
                case "number":
                  switch (elem.name) {
                    case "Contacto: Teléfono":
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          type="tel"
                          label={"Teléfono de contacto"}
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                    case "Ratio":
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          label={"Ratio (porcentaje de corrección)"}
                          type="number"
                          min={0}
                          defaultValue={getVal(elem) || 100}
                          required
                        />
                      );
                    case "Anyo":
                      //debugger
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          label={"Año"}
                          type="number"
                          defaultValue={
                            getVal(elem) || new Date().getFullYear()
                          }
                          required
                        />
                      );
                    default:
                      return (
                        <TextField
                          key={cId}
                          id={cId}
                          label={name}
                          type="number"
                          defaultValue={getVal(elem)}
                          required
                        />
                      );
                  }
                case "boolean":
                  return <div key={cId}></div>; //return <Checkbox id={cId} label={name} />
                default:
                  return (
                    <TextField
                      key={cId}
                      id={cId}
                      label={name}
                      defaultValue={getVal(elem)}
                      required
                    />
                  );
              }
            })}
          </Stack>
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={sendQuery}
            text={`${cleanType[dialog.type]}`}
          />
          <DefaultButton onClick={toggleVisibility} text={`Cancelar`} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const ConfirmationDialog = ({
  confirmation,
  setConfirmation,
}: {
  confirmation: {
    description: string;
    operation: string;
    callback: Function | undefined;
    visible: boolean;
  };
  setConfirmation: Function;
}) => {
  function toggleVisibility() {
    setConfirmation({ ...confirmation, visible: !confirmation.visible });
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Confirmación",
    subText: `¿Seguro que desea ${confirmation.description.toLowerCase()}?`,
  };

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: { main: { maxWidth: 450 } },
      dragOptions: {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
        keepInBounds: true,
      },
    }),
    []
  );

  return (
    <>
      <Dialog
        hidden={!confirmation.visible}
        onDismiss={toggleVisibility}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              if (confirmation.callback) {
                confirmation.callback();
              }
              setConfirmation({
                description: "",
                operation: "",
                callback: undefined,
                visible: false,
              });
            }}
            text={confirmation.operation}
          />
          <DefaultButton
            onClick={toggleVisibility}
            text={`No ${confirmation.operation.toLowerCase()}`}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

import { gql, useQuery } from "@apollo/client";
import { MessageBar } from "@fluentui/react";
import React, { useContext } from "react";
import AppContext, { IFilters } from "../../../AppContext";
import Counter from "../../../components/Counter";
import Title from "../../../components/Title";
import { Entorno } from "../../../utils/constants";
import "./style.scss";

export const title = "Tamaño del mercado";
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: false,
  hasTicketMedio: false,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: true,
  showMyData: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
    ) {
      graph(
        Graph: "Tamano del mercado"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      //segmento: filters.segmentos[filters.variables] || "Mercado",
      var: 1,
      segment:
        filters.segmentType === 0
          ? filters?.segmentos[filters?.segment]
          : filters?.especialidades[filters?.segment],
      segmentType: filters.segmentType,
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let emptyData = {
    Total: {
      Ventas: { Total: 0, Tendencia: 0 },
      Tickets: { Total: 0, Tendencia: 0 },
      TicketMedio: { Total: 0, Tendencia: 0 },
      PuntosDeVenta: { Total: 0, Tendencia: 0 },
      VentaMediaLocal: { Total: 0, Tendencia: 0 },
    },
    Comparable: {
      Ventas: { Total: 0, Tendencia: 0 },
      Tickets: { Total: 0, Tendencia: 0 },
      TicketMedio: { Total: 0, Tendencia: 0 },
      PuntosDeVenta: { Total: 0, Tendencia: 0 },
      VentaMediaLocal: { Total: 0, Tendencia: 0 },
    },
    Aperturas: { Total: 0, Tendencia: 0 },
    Error: false,
  };
  let data = emptyData;
  let exportData: any = [];

  if (!!qry?.data) {
    try {
      data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      exportData = getExportData(data, semanas[0].year === 2019);
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  const isComparable = filters.entorno === Entorno.Comparable;

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{
          data: exportData,
          semanas,
          user,
          title,
          leyenda: "",
          transposed: true,
        }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {!error && (
        <div className="counter-container">
          <Counter
            title={"Ventas"}
            value={
              isComparable
                ? data.Comparable.Ventas.Total
                : data.Total.Ventas.Total
            }
            growthValue={
              isComparable
                ? data.Comparable.Ventas.Tendencia
                : data.Total.Ventas.Tendencia
            }
            isLoading={loading}
            showDivisa
          />
          <Counter
            title={"Tickets"}
            value={
              isComparable
                ? data.Comparable.Tickets.Total
                : data.Total.Tickets.Total
            }
            growthValue={
              isComparable
                ? data.Comparable.Tickets.Tendencia
                : data.Total.Tickets.Tendencia
            }
            isLoading={loading}
          />
          <Counter
            title={"Ticket medio"}
            value={
              isComparable
                ? data.Comparable.TicketMedio.Total
                : data.Total.TicketMedio.Total
            }
            growthValue={
              isComparable
                ? data.Comparable.TicketMedio.Tendencia
                : data.Total.TicketMedio.Tendencia
            }
            isLoading={loading}
            showDivisa
          />
          <Counter
            title={"Aperturas netas"}
            value={data.Aperturas.Total}
            //growthValue={data.Aperturas.Tendencia}
            growthTitle={"diferencia"}
            isLoading={loading}
          />
          <Counter
            title={"Puntos de venta"}
            value={
              isComparable
                ? data.Comparable.PuntosDeVenta.Total
                : data.Total.PuntosDeVenta.Total
            }
            growthValue={
              isComparable
                ? data.Comparable.PuntosDeVenta.Tendencia
                : data.Total.PuntosDeVenta.Tendencia
            }
            isLoading={loading}
          />
          {filters.entorno === 0 && (
            <Counter
              title={"Venta media/local"}
              value={
                isComparable
                  ? data.Comparable.VentaMediaLocal.Total
                  : data.Total.VentaMediaLocal.Total
              }
              growthValue={
                isComparable
                  ? data.Comparable.VentaMediaLocal.Tendencia
                  : data.Total.VentaMediaLocal.Tendencia
              }
              isLoading={loading}
              showDivisa
            />
          )}
        </div>
      )}
    </>
  );
};

export default Graph;

const getExportData = (data: any, isLeap: boolean) => {
  let exportData: any = [];
  let tendencias = {
    totales: {
      Ventas: { Total: data.Total.Ventas.Tendencia },
      Tickets: { Total: data.Total.Tickets.Tendencia },
      "Ticket medio": { Total: data.Total.TicketMedio.Tendencia },
      "Venta media/local": { Total: data.Total.VentaMediaLocal.Tendencia },
      "Puntos de venta": { Total: data.Total.PuntosDeVenta.Tendencia },
      Aperturas: { Total: data.Aperturas.Tendencia },
    },
    comparables: {
      Ventas: { Comparable: data.Comparable.Ventas.Tendencia },
      Tickets: { Comparable: data.Comparable.Tickets.Tendencia },
      "Ticket medio": { Comparable: data.Comparable.TicketMedio.Tendencia },
      "Venta media/local": {
        Comparable: data.Comparable.VentaMediaLocal.Tendencia,
      },
      "Puntos de venta": { Comparable: "-#" },
      Aperturas: { Comparable: "-#" },
    },
  };
  let absoluto = {
    totales: {
      Ventas: { Total: data.Total.Ventas.Total + "€#" },
      Tickets: { Total: data.Total.Tickets.Total + "#" },
      "Ticket medio": { Total: data.Total.TicketMedio.Total + "€#" },
      "Venta media/local": { Total: data.Total.VentaMediaLocal.Total + "€#" },
      "Puntos de venta": { Total: data.Total.PuntosDeVenta.Total + "#" },
      Aperturas: { Total: data.Aperturas.Total + "#" },
    },
    comparables: {
      Ventas: { Comparable: data.Comparable.Ventas.Total + "€#" },
      Tickets: { Comparable: data.Comparable.Tickets.Total + "#" },
      "Ticket medio": { Comparable: data.Comparable.TicketMedio.Total + "€#" },
      "Venta media/local": {
        Comparable: data.Comparable.VentaMediaLocal.Total + "€#",
      },
      "Puntos de venta": { Comparable: "-#" },
      Aperturas: { Comparable: "-#" },
    },
  };
  exportData.push({
    "Tamaño del mercado": {
      Ventas: Object.assign(
        {},
        tendencias.totales["Ventas"],
        isLeap ? {} : tendencias.comparables["Ventas"]
      ),
      Tickets: Object.assign(
        {},
        tendencias.totales["Tickets"],
        isLeap ? {} : tendencias.comparables["Tickets"]
      ),
      "Ticket medio": Object.assign(
        {},
        tendencias.totales["Ticket medio"],
        isLeap ? {} : tendencias.comparables["Ticket medio"]
      ),
      "Venta media/local": Object.assign(
        {},
        tendencias.totales["Venta media/local"],
        isLeap ? {} : tendencias.comparables["Venta media/local"]
      ),
      "Puntos de venta": Object.assign(
        {},
        tendencias.totales["Puntos de venta"],
        isLeap ? {} : tendencias.comparables["Puntos de venta"]
      ),
      Aperturas: Object.assign(
        {},
        tendencias.totales["Aperturas"],
        isLeap ? {} : tendencias.comparables["Aperturas"]
      ),
    },
  });
  exportData.push({
    "Tamaño del mercado (absoluto)": {
      Ventas: Object.assign(
        {},
        absoluto.totales["Ventas"],
        isLeap ? {} : absoluto.comparables["Ventas"]
      ),
      Tickets: Object.assign(
        {},
        absoluto.totales["Tickets"],
        isLeap ? {} : absoluto.comparables["Tickets"]
      ),
      "Ticket medio": Object.assign(
        {},
        absoluto.totales["Ticket medio"],
        isLeap ? {} : absoluto.comparables["Ticket medio"]
      ),
      "Venta media/local": Object.assign(
        {},
        absoluto.totales["Venta media/local"],
        isLeap ? {} : absoluto.comparables["Venta media/local"]
      ),
      "Puntos de venta": Object.assign(
        {},
        absoluto.totales["Puntos de venta"],
        isLeap ? {} : absoluto.comparables["Puntos de venta"]
      ),
      Aperturas: Object.assign(
        {},
        absoluto.totales["Aperturas"],
        isLeap ? {} : absoluto.comparables["Aperturas"]
      ),
    },
  });

  /*exportData.push({
		"Tamaño del mercado (absoluto)": {
			"Ventas": {
				Total: data.Total.Ventas.Total+"€#",
				Comparable: data.Comparable.Ventas.Total+"€#"
			},
			"Tickets": {
				Total: data.Total.Tickets.Total+"#",
				Comparable: data.Comparable.Tickets.Total+"#"
			},
			"Ticket medio": {
				Total: data.Total.TicketMedio.Total+"€#",
				Comparable: data.Comparable.TicketMedio.Total+"€#"
			},
			"Venta media/local": {
				Total: data.Total.VentaMediaLocal.Total+"€#",
				Comparable: data.Comparable.VentaMediaLocal.Total+"€#"
			},
			"Puntos de venta": {
				Total: data.Total.PuntosDeVenta.Total+"#",
				Comparable: "-#"
			},
			"Aperturas": {
				Total: data.Aperturas.Total+"#",
				Comparable: "-#"
			}
		}
	})
	*/

  return exportData;
};

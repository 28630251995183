import React, { useContext, useMemo } from "react";
import Title from "../../../components/Title";
import BarGraph from "../../../components/BarGraph";
import { Detalle, Entorno } from "../../../utils/constants";
import AppContext, { IFilters } from "../../../AppContext";
import { gql, useQuery } from "@apollo/client";
import {
  getStepsFixed as getSteps,
  getMinFixed as getMin,
  getMaxFixed as getMax,
} from "../../../utils/functions";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";

export const title = `Mercado por segmentos`;
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: false,
  isDesglose: true,
  showEntorno: true,
  showSegmentos: false,
  showMyData: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  let entorno: "Comparable" | "Total" =
    filters.entorno === Entorno.Comparable ? "Comparable" : "Total";
  let isDesglosado = filters.detalle === Detalle.Desglose;

  const GET_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!) {
      graph(
        Graph: "Mercado por segmentos"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let labels: string[] = [],
    values: any = [],
    values_total: any = useMemo(() => [], []),
    values_desglose: any = useMemo(() => [], []);
  //let annotation:any=[]

  let data: any;
  let exportData: any;
  if (!loading && !error && qry.data.graph) {
    try {
      data = qry?.data?.graph;

      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      if (!data?.Error) {
        labels = data.map((d: any) => Object.keys(d)[0]);
        values_total = [
          data.map((d: any[]) => Object.values(d)[0][entorno]["Ventas"]),
        ];
        values_desglose = [
          data.map((d: any[]) => Object.values(d)[0][entorno]["Tickets"]),
          data.map((d: any[]) => Object.values(d)[0][entorno]["TicketMedio"]),
        ];

        values = isDesglosado ? values_desglose : values_total;
      }
      exportData =
        semanas[0].year === 2019
          ? getExportData(labels, [...values_total, ...values_desglose])
          : data;
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  let max: number | undefined = useMemo(
    () => getMax([...values_desglose, ...values_total]),
    [values_total, values_desglose]
  );
  let min: number | undefined = useMemo(
    () => getMin([...values_desglose, ...values_total]),
    [values_total, values_desglose]
  );

  const stepSize = useMemo(() => getSteps(min, max), [min, max]);

  // round max to next integer
  max = Math.ceil(max + 1);
  // round min to previous integer
  min = Math.floor(min);

  const graphData = {
    datalabels: isDesglosado ? ["Tickets", "Ticket medio"] : ["Ventas"],
    labels: labels,
    values: values,
    colors: isDesglosado ? ["#0C4D7B", "#85b709"] : ["#0e6773"],
    annotation: [],
    series: isDesglosado
      ? [
          { name: "Tickets", data: values[0] ?? [] },
          { name: "Ticket medio", data: values[1] ?? [] },
        ]
      : [{ name: "Ventas", data: values[0] }],
    min: min,
    max: max,
    stepSize: stepSize,
  };

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{
          data: exportData,
          semanas,
          user,
          title,
          leyenda: "Entorno",
          transposed: true,
        }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {/*!error &&
				<BarGraph
					stacked={true}
					datalabels={isDesglosado?["Tickets", "Ticket medio"]:["Ventas"]}
					labels={labels}
					values={values}
					annotation={annotation}
					colors={isDesglosado?["#0C4D7B","#85b709"]:["#0e6773"]}
					horizontal={false}
					stepSize={stepSize}
					percentages
				/>
			*/}
      {!error && <BarGraph data={graphData} percentages />}
    </>
  );
};

export default Graph;

const getExportData = (labels: string[], values: any[]) => {
  let exportData: any = [];

  for (let label in labels) {
    exportData.push({
      [labels[label]]: {
        Total: {
          Ventas: values[0][label],
          Tickets: values[1][label],
          TicketMedio: values[2][label],
        },
      },
    });
  }

  return exportData;
};

import React, { useContext, useState } from "react";
import Title from "../../../components/Title";
import AppContext, { IFilters } from "../../../AppContext";
import { gql, useQuery } from "@apollo/client";
import MapGraph from "../../../components/MapGraph";
import ReactTooltip from "react-tooltip";
import { MessageBar } from "@fluentui/react";
import "./style.scss";

export const title = `Tendencias por región`;
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: true,
  isDesglose: false,
  showEntorno: false,
  showSegmentos: true,
  showMyData: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  //let isDesglosado = filters.detalle===Detalle.Desglose

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
    ) {
      graph(
        Graph: "Tendencias por region"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 0, //filters.variables
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let values: any = [];

  let data: any;
  if (!loading && !error && qry.data.graph) {
    try {
      data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      let segmentStr = filters.segmentos[filters.segment] || "Mercado";
      if (segmentStr === "Total") segmentStr = "Mercado";
      let segmentPos = data.filter(
        (d: any) => Object.keys(d)[0] === segmentStr
      );
      let cData: any = Object.values(data[data.indexOf(segmentPos[0])])[0];

      let aux: any = Object.entries(cData)[filters.medidas][1];

      values = Object.values(aux);
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  const [content, setContent] = useState("");
  let isLoading = loading || values.length === 0 || isNaN(values[0]);

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{ data, semanas, user, title, leyenda: "Zona" }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {!error && (
        <>
          <MapGraph
            className="map-container"
            setTooltipContent={setContent}
            values={values}
            loading={!!isLoading}
          />
          {/*@ts-ignore*/}
          <ReactTooltip type="light" className="mapTooltip">
            {content}
          </ReactTooltip>
        </>
      )}
    </>
  );
};

export default Graph;

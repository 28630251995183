export const fontFamily = 'Segoe UI, Webly Sleek, Helvetica Neue, Helvetica, Arial'
export const Periodo={
	Semana: 0,
	Mes: 1,
	Trimestre: 2,
	Anyo: 3
}
export const Medidas={
	Ventas: 0,
	Tickets: 1,
	TicketMedio: 2
}
export const Variables={
	Total: 0,
	Segmento: 1,
	Especialidad: 2
}
export const Entorno={
	Total: 0,
	Comparable: 1
}
export const Canal={
	Total: 0,
	Local: 1,
	Llevar: 2,
	Domicilio: 3
}
export const Detalle={
	Total: 0,
	Desglose: 1
}
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Picker from "../../components/Picker";
import { SecondLevelPicker } from "../../components/SLPicker";
import {
  Calendar,
  Checkbox,
  DateRangeType,
  DayOfWeek,
  FirstWeekOfYear,
  FontIcon,
  IDatePickerStrings,
  mergeStyles,
} from "@fluentui/react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import "../../utils/date";
import moment from "moment";
import "moment/locale/es";
import QuarterPicker from "../../components/QuarterPicker";
import YearPicker from "../../components/YearPicker";
import AppContext, {
  defaults,
  IFilters,
  IWeeks,
  ICadena,
} from "../../AppContext";
import { Entorno, Medidas, Periodo } from "../../utils/constants";
import {
  DescargaMasiva,
  InformesComparados,
  MisInformes,
  Comparadores,
} from "../Sections";
moment.locale("es");

const dayPickerStrings: IDatePickerStrings = {
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  shortMonths: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  days: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  shortDays: ["D", "L", "M", "X", "J", "V", "S"],
  //weekNumberFormatString: 'Semana {0}',
  prevYearRangeAriaLabel: "Rango de años anterior",
  nextYearRangeAriaLabel: "Rango de años siguiente",
  goToToday: "Ir a hoy",
  prevMonthAriaLabel: "Ir al mes anterior",
  nextMonthAriaLabel: "Ir al mes siguiente",
  prevYearAriaLabel: "Ir al año anterior",
  nextYearAriaLabel: "Ir al año siguiente",
  closeButtonAriaLabel: "Cerrar selector de fechas",
  monthPickerHeaderAriaLabel: "{0}, elige para cambiar de año",
  yearPickerHeaderAriaLabel: "{0}, elige para cambiar de mes",
};

const emptyFilters: IFilters = {
  limitedPeriodo: false,
  showMedidas: false,
  hasTicketMedio: false,
  isDesglose: false,
  showEntorno: false,
  showSegmentos: false,
  showMyData: false,
};

const ALL_SEGMENTOS = gql`
  query {
    getSegmentos
  }
`;

const ALL_CADENAS = gql`
  query {
    getCadena {
      UUID
      Nombre
      Contacto {
        Nombre
        Email
        Telefono
      }
      Compania {
        Nombre
      }
      Especialidad {
        Descripcion
      }
      Segmento {
        Descripcion
      }
      CargaIVA
      Ratio
      hasUsuarios
      hasVentas
    }
  }
`;

const GET_CADENAS_WITH_VENTAS = gql`
  query (
    $yr1: Int!
    $wk1: Int!
    $yr2: Int!
    $wk2: Int!
    $var: Int
    $segment: String
    $segmentType: Int
  ) {
    graph(
      Graph: "Get cadenas con ventas"
      WeekRange: {
        Start: { Year: $yr1, Week: $wk1 }
        End: { Year: $yr2, Week: $wk2 }
      }
      Filters: {
        Variables: $var
        Segmento: $segment
        SegmentType: $segmentType
      }
    )
  }
`;

const ALL_ESPECIALIDADES = gql`
  query {
    getEspecialidades
  }
`;

const Filters = ({
  filtersOpen,
  setFiltersOpen,
}: {
  filtersOpen: boolean;
  setFiltersOpen: Function;
}) => {
  const {
    semanas,
    setSemanas,
    filters,
    setFilters,
    section,
    semanasDatos,
    cadenas,
    userName,
  } = useContext(AppContext);
  // Era .year(semanasDatos[0].year === 2019 ? 2019 : semanasDatos[0].year)
  const minDate = moment()
    .year(2022) // ERA 2019
    .isoWeek(semanasDatos[0].week)
    .startOf("isoWeek")
    .toDate();
  const maxDate = moment()
    .year(semanasDatos[1].year)
    .isoWeek(semanasDatos[1].week - 1)
    .endOf("isoWeek")
    .toDate();

  const minDateM = moment()
    .year(2022) // FIX ERA .year(semanasDatos[0].year === 2019 ? 2020 : semanasDatos[0].year)
    .isoWeek(semanasDatos[0].week)
    .endOf("isoWeek")
    .toDate();
  const maxDateM = moment()
    .year(semanasDatos[1].year)
    .isoWeek(semanasDatos[1].week - 1)
    .startOf("isoWeek")
    .toDate();

  const [selectedDate, setSelectedDate] = useState<Date>(maxDate);

  const [compare19, setCompare19] = useState<boolean>(false);
  const [showCompare19, setShowCompare19] = useState<boolean>(
    moment(selectedDate).isoWeekYear() >= 2021 ||
      (moment(selectedDate).isoWeekYear() === 2020 &&
        moment(selectedDate).isoWeek() === 53)
  );

  const [dayNavigatedDate, setDayNavigatedDate] = useState(selectedDate);
  const [navInit, setNavInit] = useState(false);
  const [currPos, setCurrPos] = useState(0);

  // Picker population
  const [especialidades, setEspecialidades] = useState([]);
  const [chainsData, setChainsData] = useState<any>([]);

  const getGraphFilters = (section: number) => {
    switch (section) {
      case 0:
        return (
          InformesComparados.filter((s) => s.group === section)[currPos]
            ?.filters || emptyFilters
        );
      case 1:
        return (
          MisInformes.filter((s) => s.group === section)[currPos]?.filters ||
          emptyFilters
        );
      case 2:
        return Comparadores[0].filters || emptyFilters;
      case 3:
        return DescargaMasiva[0].filters;
    }
  };

  const graphFilters = getGraphFilters(section);
  let segmentos = useQuery(ALL_SEGMENTOS);

  const [getChainsData] = useLazyQuery(ALL_CADENAS, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log("ERROR: ", error);
    },
    onCompleted: (fetchedData) => {
      const data: any[] = [...chainsData];

      fetchedData.getCadena.forEach((cadena: ICadena) => {
        let c = {
          Nombre: cadena.Nombre,
        };
        if (cadena.hasVentas) {
          data.push(c.Nombre);
        }
      });
      // sort data from least to most
      data.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      setChainsData(data);
    },
  });

  React.useEffect(() => {
    getChainsData();
  }, [getChainsData]);

  const [getSpecialities] = useLazyQuery(ALL_ESPECIALIDADES, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log("ERROR: ", error);
    },
    onCompleted: (fetchedData) => {
      if (fetchedData?.getEspecialidades) {
        setEspecialidades(fetchedData.getEspecialidades);
      }
    },
  });

  React.useEffect(() => {
    getSpecialities();
  }, [getSpecialities]);

  useEffect(() => {
    if (filters.leverType) {
      setFilters({ ...filters, lever: 0 });
    }
  }, [filters.leverType]);

  useEffect(() => {
    if (filters.segmentType) {
      setFilters({ ...filters, variables: 0, segment: 0 });
    }
  }, [filters.segmentType]);

  useEffect(() => {
    if (
      filters.segmentos.length === 0 &&
      !segmentos.loading &&
      segmentos.data &&
      especialidades.length > 0
    )
      setFilters({
        ...filters,
        segmentos: segmentos?.data?.getSegmentos,
        especialidades: especialidades,
      });
  }, [especialidades, segmentos]);

  // Primera navegación a la fecha seleccionada:
  useEffect(() => {
    if (
      semanasDatos.length !== 0 &&
      !(
        semanasDatos[0].year === semanasDatos[1].year &&
        semanasDatos[0].week === semanasDatos[1].week
      ) &&
      !navInit
    ) {
      const cSelectDate = moment()
        .year(semanasDatos[1].year)
        .isoWeek(semanasDatos[1].week - 1)
        .endOf("isoWeek")
        .toDate();
      setSelectedDate(cSelectDate);
      setDayNavigatedDate(cSelectDate);
      setNavInit(true);
    }
  }, [
    dayNavigatedDate,
    setDayNavigatedDate,
    setSelectedDate,
    navInit,
    setNavInit,
    semanasDatos,
  ]);

  // Código duplicado en Header por cuestiones de rendimiento:
  useEffect(() => {
    const comparePositions = () => {
      let selected = -1;
      if (section === 0) {
        for (let i in InformesComparados) {
          let obj = document.querySelector(
            `#sec${InformesComparados[i].group}${i}`
          );
          if (obj) {
            let shownRatio =
              obj.getBoundingClientRect().top / window.innerHeight;
            if (shownRatio < -0.1 || shownRatio > 0.5) continue;
            selected = parseInt(i);
          }
          break;
        }
        if (selected !== -1) setCurrPos(selected);
      }

      if (section === 1) {
        for (let i in MisInformes) {
          let obj = document.querySelector(`#sec${MisInformes[i].group}${i}`);
          if (obj) {
            let shownRatio =
              obj.getBoundingClientRect().top / window.innerHeight;
            if (shownRatio < -0.1 || shownRatio > 0.5) continue;
            selected = parseInt(i);
          }
          break;
        }
        if (selected !== -1) setCurrPos(selected);
      }
    };

    //var prevPosition = 0;
    var ticking = false;
    window.addEventListener("scroll", function (e) {
      //prevPosition = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function () {
          comparePositions();
          ticking = false;
        });
      }
      ticking = true;
    });
  });

  const getMonthWeeks = (month: number) => {
    switch (month) {
      case 0: // Enero
        return { start: 2, end: 6 }; // 1 MENOS EN CADA, TODOS
      case 1: // Febrero
        return { start: 7, end: 10 };
      case 2: // Marzo
        return { start: 11, end: 14 };
      case 3: // Abril
        return { start: 15, end: 19 };
      case 4: // Mayo
        return { start: 20, end: 23 };
      case 5: // Junio
        return { start: 24, end: 27 };
      case 6: // Julio
        return { start: 28, end: 32 };
      case 7: // Agosto
        return { start: 33, end: 36 };
      case 8: // Septiembre
        return { start: 37, end: 40 };
      case 9: // Octubre
        return { start: 41, end: 45 };
      case 10: // Noviembre
        return { start: 46, end: 49 };
      case 11: // Diciembre
      default:
        return { start: 50, end: 53 };
    }
  };

  const onSelectDate = (date: Date, _: any, c19: boolean = compare19): void => {
    let year = moment(date).isoWeekYear();
    let week = moment(date).isoWeek();
    let month = moment(date).month();
    let quarter = moment(date).quarter();
    let semanas: IWeeks;

    if (year >= 2021 && year <= 2024) week++;
    if (year === 2020 && week === 53) {
      year = 2021;
      week = 1;
    }
    /*if (year === 2023 && week === 53) {
      year = 2024;
      week = 1;
    }*/
    if (year < 2021) {
      setCompare19(false);
      c19 = false;
    }

    function getQuarterWeeks(quarter: number) {
      if (quarter === 1) return [2, 14];
      if (quarter === 2) return [15, 27];
      if (quarter === 3) return [28, 40];
      return [41, 53];
      // ERA
      /*
			 if (quarter === 1) return [1, 13];
      if (quarter === 2) return [14, 26];
      if (quarter === 3) return [27, 39];
      return [40, 53];
			*/
    }

    //setShowCompare19(year >= 2021);

    switch (filters.periodo) {
      case Periodo.Semana:
        semanas = [
          { year: c19 ? 2019 : year, week: week },
          { year: year, week: week },
        ];
        break;
      case Periodo.Mes:
        semanas = [
          { year: c19 ? 2019 : year, week: getMonthWeeks(month).start },
          { year: year, week: getMonthWeeks(month).end },
        ];
        break;
      case Periodo.Trimestre:
        semanas = [
          {
            year: c19 ? 2019 : moment(date).year(),
            week: getQuarterWeeks(quarter)[0],
          },
          { year: moment(date).year(), week: getQuarterWeeks(quarter)[1] },
        ];
        break;
      case Periodo.Anyo:
        // Toruzzada
        /* ESTO ES LO QUE ESTABA HASTA 2023-03-31 */
        /*
			semanas = [
          { year: c19 ? 2019 : year, week: 1 },
          { year: year, week: moment(year.toString()).weeksInYear() },
        ];*/
        // ESTE ES EL PARCHE DE TORUZZ
        semanas = [
          { year: c19 ? 2019 : year, week: 2 }, // ERA WEEK: 1
          { year, week: 53 },
        ];

        break;
      default:
        semanas = defaults.weeks;
    }

    setSemanas(semanas);
    if (selectedDate !== date) setSelectedDate(date);
  };

  const strRange = (d1: any, d2: any) => {
    return `${d1.format("L")} - ${d2.format("L")}`;
  };

  const isRangeCorrect = () => {
    if (graphFilters.onlyYears) return true;
    let rangeStart = moment()
      .year(compare19 ? semanas[1].year : semanas[0].year)
      .isoWeek(semanas[0].week - 1);
    let rangeEnd = moment()
      .year(semanas[1].year)
      .isoWeek(semanas[1].week - 1);

    let rango = rangeEnd.diff(rangeStart, "weeks");
    switch (filters.periodo) {
      case Periodo.Semana:
        return rango === 0;
      case Periodo.Mes:
        return rango >= 3 && rango <= 6;
      case Periodo.Trimestre:
        return rango > 6 && rango < 24;
      case Periodo.Anyo:
        return rango >= 50;
      default:
        return false;
    }
  };

  const strWeeks = () => {
    if (!!graphFilters.onlyYears) return `Año ${semanas[1].year}`;
    if (!semanas) return "";
    let wk1 = semanas[0];
    let wk2 = semanas[1];

    if (wk1.year === 2024 && wk2.year === 2024) {
      let d1 = moment(wk1.year.toString())
        .add(wk1.week - 2, "weeks") // FIX ERA - 1 en lugar de - 2
        .startOf("week");
      let d2 = moment(wk2.year.toString())
        .add(wk2.week - 2, "weeks") // FIX ERA - 1 en lugar de - 2
        .endOf("week");
      return strRange(d1, d2);
    } else {
      let d1 = moment(wk1.year.toString())
        .add(wk1.week - 1, "weeks") // FIX ERA - 1 en lugar de - 2
        .startOf("week");
      let d2 = moment(wk2.year.toString())
        .add(wk2.week - 1, "weeks") // FIX ERA - 1 en lugar de - 2
        .endOf("week");
      return strRange(d1, d2);
    }
  };

  useEffect(() => {
    setCompare19(false);
  }, [section]);

  // Al navegar fuera de Tendencias por región con Ticket/medio activo, se pasa a ventas
  useEffect(() => {
    if (
      !graphFilters.hasTicketMedio &&
      filters.medidas === Medidas.TicketMedio
    ) {
      setFilters({ ...filters, medidas: Medidas.Ventas });
    }
  }, [graphFilters.hasTicketMedio, filters, setFilters]);

  const handleCompare19 = (_: any, checked: boolean | undefined) => {
    if (!!checked && filters.entorno !== Entorno.Total)
      setFilters({ ...filters, entorno: Entorno.Total });
    onSelectDate(selectedDate, undefined, !!checked);
    setCompare19(!!checked);
  };

  const handleDayNavigation = (date: Date, focusOnNavigatedDay: boolean) => {
    setDayNavigatedDate(date);
  };

  useEffect(() => {
    setDayNavigatedDate(selectedDate);
  }, [selectedDate]);

  // Arreglo para ajustar el calendario estándar al calendario de Ameba
  useEffect(() => {
    const year = moment(dayNavigatedDate).year();
    const month = moment(dayNavigatedDate).month();
    const weekCells = document.querySelectorAll<HTMLInputElement>(
      'th[class^="weekNumberCell"]'
    );
    if (weekCells && weekCells.length > 0) {
      weekCells.forEach((weekCell, i) => {
        let span: HTMLSpanElement | null = weekCell.querySelector("span");
        // Si es el último span que imprime el número de semana en el último mes, es semana 53/1
        if (span && i === weekCells.length - 2 && month === 11) {
          //  span.innerHTML = `53/1`; // AQUI VA LO DEL AÑO (era 53/1)
          return;
        }
        // Si el span imprime una semana >=52 en enero, es semana 1
        if (span && parseInt(span.innerHTML) >= 52 && month === 0) {
          //span.innerHTML = `0`;
        }
        // 2020 sigue el calendario Ameba (¿por ser bisiesto?)
        if (year === 2020) return;
        if (year === 2022) return;
        if (year === 2023) return;
        if (year === 2024) return;
        /*
        if (year === 2024) return;
        if (year === 2025) return;
        if (year === 2026) return;
				*/

        let num: string = span?.innerHTML || "";
        if (span && !span?.hasAttribute("data-week")) {
          span.dataset.week = num;
        } else if (span) {
          num = span.dataset.week || "";
        }

        // Sumamos 1 al span de impresión de número de semana para ajustarnos al calendario Ameba
        if (span && span.innerHTML !== "")
          span.innerHTML = `${num === "53" ? "1" : parseInt(num) + 1}`;
      });
    }
  }, [dayNavigatedDate]);

  useEffect(() => {
    if (graphFilters.onlyYears && filters.periodo !== Periodo.Anyo) {
      setFilters({ ...filters, periodo: Periodo.Anyo });
    }
  }, [section, filters, setFilters, graphFilters.onlyYears]);

  //if(compare19&&filters.entorno!==Entorno.Total) setFilters({...filters, entorno:Entorno.Total})

  const iconClass = mergeStyles({
    fontSize: 14,
    margin: "0 4px",
    transform: "translateY(2px)",
  });

  return (
    <div
      id="filters-container"
      className={`${filtersOpen ? "open" : "closed"} f${
        graphFilters.onlyYears ? 2 : 5
      }`}
    >
      {/*<label>Filtros</label>*/}
      <div className="mobile-backdrop"></div>
      <div className="filter-group">
        <Picker
          title={"Periodo"}
          subtitle={strWeeks()}
          disabled={false}
          values={
            graphFilters.limitedPeriodo
              ? ["Semana", "Mes"]
              : graphFilters.onlyYears
              ? ["Acumulado anual"]
              : ["Semana", "Mes", "Trimestre", "Acumulado anual"]
          }
          item="periodo"
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <div id="calendar-container">
        {filters.periodo === Periodo.Semana && !graphFilters.onlyYears && (
          <Calendar
            onSelectDate={onSelectDate}
            showGoToToday={false}
            value={selectedDate}
            firstDayOfWeek={DayOfWeek.Monday}
            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
            strings={dayPickerStrings}
            isMonthPickerVisible={false}
            isDayPickerVisible={true}
            showMonthPickerAsOverlay={false}
            showWeekNumbers={true}
            dateRangeType={DateRangeType.Week}
            minDate={minDate}
            maxDate={maxDate}
            calendarDayProps={{
              navigatedDate: dayNavigatedDate,
              onNavigateDate: handleDayNavigation,
            }}
            /*calendarDayProps={{
					navigatedDate: dayNavigatedDate,
					onNavigateDate: handleDayNavigation,

				}}*/
          />
        )}
        {filters.periodo === Periodo.Mes && !graphFilters.onlyYears && (
          <Calendar
            onSelectDate={onSelectDate}
            //autoNavigateOnSelection={true}
            showGoToToday={false}
            value={selectedDate}
            firstDayOfWeek={DayOfWeek.Monday}
            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
            strings={dayPickerStrings}
            isMonthPickerVisible={true}
            isDayPickerVisible={false}
            showMonthPickerAsOverlay={false}
            showWeekNumbers={true}
            highlightSelectedMonth={isRangeCorrect()}
            dateRangeType={DateRangeType.Month}
            minDate={minDateM}
            maxDate={maxDateM}

            /*calendarMonthProps={{

					selectedDate: selectedDate,
					onSelectDate: onSelectDate,
					navigatedDate: monthNavigatedDate,
					onNavigateDate: handleMonthNavigation
				}}*/
          />
        )}
        {filters.periodo === Periodo.Trimestre && !graphFilters.onlyYears && (
          <QuarterPicker
            onSelectDate={onSelectDate}
            value={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            annotationSelected={isRangeCorrect()}
          />
        )}
        {(filters.periodo === Periodo.Anyo || graphFilters.onlyYears) && (
          <YearPicker
            onSelectDate={onSelectDate}
            value={selectedDate}
            annotationSelected={isRangeCorrect()}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}

        {/*<div className={`check-container${showCompare19 ? "" : " hidden"}`}>
          <Checkbox
            id={"checkbox-filters"}
            label={"Comparar con 2019"}
            className="ms-Checkbox-Filters"
            checked={compare19}
            onChange={handleCompare19}
          />
				</div>*/}
      </div>
      {!graphFilters.onlyYears && (
        <>
          {!graphFilters.isDesglose && (
            <div className="filter-group">
              <Picker
                title={"Medidas"}
                disabled={!graphFilters.showMedidas}
                values={
                  graphFilters.hasTicketMedio
                    ? ["Ventas", "Tickets", "Ticket medio"]
                    : ["Ventas", "Tickets"]
                }
                item={"medidas"}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          )}
          {graphFilters.isDesglose && (
            <div className="filter-group">
              <Picker
                title={"Medidas"}
                disabled={!graphFilters.showMedidas}
                values={["Venta total", "Venta desglosada"]}
                item={"detalle"}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          )}
          <div className="filter-group">
            <Picker
              title={"Entorno"}
              disabled={!graphFilters.showEntorno || compare19}
              values={["Total", "Comparable"]}
              item="entorno"
              filters={filters}
              setFilters={setFilters}
            />
          </div>
          <div className="filter-group">
            <Picker
              title={"Segmento"}
              disabled={!graphFilters.showSegmentos}
              //values={limitedAgrupado[section][currPos]?["", "Segmento", "Especialidad"]:["Total", "Segmento", "Especialidad"]}
              values={
                window?.location?.href === "https://app.amebaresearch.com/" ||
                window?.location?.href === "https://test.amebaresearch.com/"
                  ? ["Servicio", "Producto"] // aquí va producto
                  : ["Servicio"] // ELIMINAR PRODUCTO
              } // values={["Servicio", "Producto"]}
              item="segmentType"
              filters={filters}
              setFilters={setFilters}
            />
            <SecondLevelPicker
              title={"Segmentos"}
              disabled={!graphFilters.showSegmentos}
              values={
                filters.segmentType === 0
                  ? segmentos?.data?.getSegmentos
                  : especialidades
              }
              item="segment"
              filters={filters}
              setFilters={setFilters}
            />
          </div>
          <div className="filter-group">
            <Picker
              title={"Variable"}
              disabled={!graphFilters.showVariable}
              values={["Total", "Canal", "Momento", "Región", "Ubicación"]}
              item="leverType"
              filters={filters}
              setFilters={setFilters}
            />
            {filters.leverType === 1 && (
              <SecondLevelPicker
                title={"Canal"}
                disabled={graphFilters.showCanal}
                values={["Total", "Local", "Llevar", "Domicilio"]}
                item="lever"
                filters={filters}
                setFilters={setFilters}
              />
            )}
            {filters.leverType === 2 && (
              <SecondLevelPicker
                title={"Momento"}
                disabled={graphFilters.showCanal}
                values={[
                  "Total",
                  "Mañana",
                  "Tarde",
                  "Comida diario",
                  "Cena diario",
                  "Comida fin de semana",
                  "Cena fin de semana",
                ]}
                item="lever"
                filters={filters}
                setFilters={setFilters}
              />
            )}
            {filters.leverType === 3 && (
              <SecondLevelPicker
                title={"Región"}
                disabled={graphFilters.showCanal}
                values={[
                  "Total",
                  "Madrid",
                  "Barcelona",
                  "Norte",
                  "Centro",
                  "Noreste",
                  "Sur",
                ]}
                item="lever"
                filters={filters}
                setFilters={setFilters}
              />
            )}
            {filters.leverType === 4 && (
              <SecondLevelPicker
                title={"Ubicación"}
                disabled={graphFilters.showCanal}
                values={["Total", "Calle", "Centro Comercial", "Resto"]}
                item="lever"
                filters={filters}
                setFilters={setFilters}
              />
            )}
          </div>
        </>
      )}
      {section === 2 && (
        <div className="filter-group">
          <Picker
            title={"Cadena"}
            disabled={false}
            values={[...chainsData]}
            item="misdatos"
            filters={filters}
            setFilters={setFilters}
            obfuscate={0}
          />
        </div>
      )}
      <label onClick={() => setFiltersOpen(!filtersOpen)}>
        <FontIcon
          aria-label="Calendar"
          iconName="Calendar"
          className={iconClass}
        />
        <span> calendario</span>
      </label>
    </div>
  );
};

export default Filters;

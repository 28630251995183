import React from "react";
import { Text } from "@fluentui/react";
import styled from "styled-components";
import { IWeeks } from "../AppContext";

const TitleContainer = styled.div`
  letter-spacing: -0.5px;
  padding-bottom: 24px;
  color: var(--text-color);
  user-select: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-size: 18px;
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
`;

const MiniTitle = ({
  title,
  files,
  error,
  onClick,
}: {
  title: string;
  files?: {
    data: any;
    semanas: IWeeks;
    user: string;
    title: string;
    leyenda: string;
    transposed?: boolean;
    secTransposed?: boolean;
  };
  error?: boolean;
  onClick?: () => void;
}) => {
  return (
    <TitleContainer onClick={onClick}>
      <Text nowrap block>
        {title}
      </Text>
    </TitleContainer>
  );
};

export default MiniTitle;

import { IGridLocalizationobject } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
let localizationobj:IGridLocalizationobject = {};

localizationobj.percentsymbol = "%";
localizationobj.currencysymbol = "$";
localizationobj.currencysymbolposition = "anterior";
localizationobj.decimalseparator = ".";
localizationobj.thousandsseparator = ",";
localizationobj.pagergotopagestring = "Ir a página";
localizationobj.pagershowrowsstring = "Número de filas";
localizationobj.pagerrangestring = " de ";
localizationobj.pagerpreviousbuttonstring = "previo";
localizationobj.pagernextbuttonstring = "siguiente";
//localizationobj.groupsheaderstring = "Arrastre una columna para que se agrupe por ella";
localizationobj.sortascendingstring = "Ascendente";
localizationobj.sortdescendingstring = "Descendente";
localizationobj.sortremovestring = "Eliminar orden";
//localizationobj.groupbystring = "Agrupar por esta columna";
//localizationobj.groupremovestring = "Quitar de grupos";
//localizationobj.filterclearstring = "Limpiar";
//localizationobj.filterstring = "Filtro";
//localizationobj.filtershowrowstring = "Mostrar filas donde=";
//localizationobj.filtershowrowdatestring = "Mostrar filas donde fecha=";
//localizationobj.filterorconditionstring = "O";
//localizationobj.filterandconditionstring = "Y";
//localizationobj.filterselectallstring = "(Seleccionar Todo)";
//localizationobj.filterchoosestring = "Por favor seleccione:";
localizationobj.filterstringcomparisonoperators = ['vacío', 'no vacío', 'contenga', 'contenga(coincidir mayús./minús.)',
'no contenga', 'no contenga(coincidir mayús./minús.)', 'empiece por', 'empiece por(coincidir mayús./minús.)',
'termine en', 'termine en(coincidir mayús./minús.)', 'igual', 'igual(coincidir mayús./minús.)', 'null', 'no null'
];
localizationobj.filternumericcomparisonoperators = ['=', '!=', '<', '<=', '>', '>=', 'null', 'no null'];
localizationobj.filterdatecomparisonoperators = ['=', '!=', '<', '<=', '>', '>=', 'null', 'no null'];
localizationobj.filterbooleancomparisonoperators = ['=', '!='];
//localizationobj.validationstring = "Valor no válido";
localizationobj.emptydatastring = "No hay datos que mostrar";
//localizationobj.filterselectstring = "Seleccione un Filtro";
//localizationobj.loadtext = "Cargando...";
//localizationobj.clearstring = "Limpiar";
//localizationobj.todaystring = "hoy";

var days = {
	names: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
	namesAbbr: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
	namesShort: ["D", "L", "M", "X", "J", "V", "S"]
};
localizationobj.days = days;
var months = {
	names: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", ""],
	namesAbbr: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic", ""]
};
localizationobj.months = months;

export default localizationobj;
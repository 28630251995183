import './style.scss';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const yearPicker = ({onSelectDate, value, minDate, maxDate, annotationSelected}:{onSelectDate:Function, value:Date, minDate:Date, maxDate:Date, annotationSelected?: boolean}) => {
	const moment = require('moment');

	let minYear:number = moment(minDate).isoWeekYear()
	let maxYear:number = moment(maxDate).isoWeekYear()

	const handleChange = (e:any) => {
		let year = e.target.dataset["year"] || moment().year()
		//onSelectDate(moment(year.toString()).toDate())
		onSelectDate(moment().isoWeekYear(year.toString()).toDate())
	}

	const isSelected = (year:number)=>{
		return moment(value).year()===year&&annotationSelected
	}

	const years = Array.from(Array(maxYear-minYear+1), (_,c) => c+minYear)

	return(
		<div className="ms-DatePicker"><div className="ms-DatePicker-picker"><div className="ms-DatePicker-holder"><div className="ms-DatePicker-frame"><div className="ms-DatePicker-wrap-year">
			<div className="ms-DatePicker-yearPicker">
				<div className="ms-FocusZone">
					<div className="ms-DatePicker-optionGrid" role="grid" aria-readonly="true">
						<div className="ms-DatePicker-yearRow" role="row">
						{years.map((year)=>{
							return(<button
								key={year}
								onClick={handleChange}
								data-year={year}
								role="gridcell"
								className={`ms-DatePicker-yearOption${isSelected(year)?" active":""}`}
								aria-label={`Año ${year}`}
								type="button"
								tabIndex={0}
								data-is-focusable="true">
							{year}
							</button>)
						})}
						</div>
					</div>
				</div>
			</div>
		</div></div></div></div></div>
	)
}

export default yearPicker;
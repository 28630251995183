import React, { useContext, useMemo, useState } from "react";
import Title from "../../../components/Title";
import BarGraph from "../../../components/BarGraph";
import { Detalle } from "../../../utils/constants";
import AppContext, { IFilters } from "../../../AppContext";
import { gql, useQuery } from "@apollo/client";
import {
  getStepsFixed as getSteps,
  getMinFixed as getMin,
  getMaxFixed as getMax,
} from "../../../utils/functions";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import LocalFilter from "../../../components/LocalFilter";

export const title = `Segmentos por canal de servicio`;
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: false,
  isDesglose: true,
  showEntorno: false,
  showSegmentos: false,
  showMyData: false,
};

type ICanal =
  | { key: "0"; text: "Total" }
  | { key: "1"; text: "Domicilio" }
  | { key: "2"; text: "Llevar" }
  | { key: "3"; text: "Local" };

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  let isDesglosado = filters.detalle === Detalle.Desglose;
  //let canal=getCanal(filters.canal)

  const [canal, setCanal] = useState<ICanal>({ key: "0", text: "Total" });

  const GET_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!) {
      graph(
        Graph: "Segmentos por canal de servicio"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
    },
  });

  const EXPORT_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!, $var: Int) {
      graph(
        Graph: "Segmentos por canal de servicio export"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: { Variables: $var }
      )
    }
  `;

  const exportQry = useQuery(EXPORT_GRAPH, {
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 0, //filters.variables
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let labels: string[] = [],
    values_total: any = useMemo(() => [], []),
    values_desglose: any = useMemo(() => [], []);
  let annotation: any = [];

  if (!loading && !error && qry.data.graph) {
    try {
      const data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      if (!data?.Error) {
        labels = data.map((d: any) => Object.keys(d)[0]);
        values_total = [
          data.map(
            (d: any[]) =>
              Object.values(d)[0][canal.text ?? "Total"].Ventas.Tendencia
          ),
        ];
        values_desglose = [
          data.map(
            (d: any[]) =>
              Object.values(d)[0][canal.text ?? "Total"].Tickets.Tendencia
          ),
          data.map(
            (d: any[]) =>
              Object.values(d)[0][canal.text ?? "Total"].TicketMedio.Tendencia
          ),
        ];
      }
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  let max: number | undefined = useMemo(
    () => getMax([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );
  let min: number | undefined = useMemo(
    () => getMin([...values_total, ...values_desglose]),
    [values_total, values_desglose]
  );
  min = getMin([...values_total, ...values_desglose]);
  max = getMax([...values_total, ...values_desglose]);

  // round max to next integer
  max = Math.ceil(max);
  // round min to previous integer
  min = Math.floor(min);

  const stepSize = useMemo(() => getSteps(min, max), [min, max]);

  let exportData: any = [];
  if (
    !(exportQry.loading || exportQry.data === undefined) &&
    !(!semanas || !!exportQry.error) &&
    exportQry.data.graph
  ) {
    try {
      let tempData = exportQry?.data?.graph;

      if (tempData && tempData[0]) {
        Object.keys(tempData[0]).forEach((key, i) => {
          exportData.push({ [key]: Object.values(tempData[0])[i] });
        });
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }

  let values = isDesglosado ? values_desglose : values_total;

  const graphData = {
    datalabels: isDesglosado ? ["Tickets", "Ticket medio"] : ["Ventas"],
    labels: labels,
    values: values,
    colors: isDesglosado ? ["#0C4D7B", "#85b709"] : ["#0e6773"],
    annotation: annotation,
    series: isDesglosado
      ? [
          { name: "Tickets", data: values[0] ?? [] },
          { name: "Ticket medio", data: values[1] ?? [] },
        ]
      : [{ name: "Ventas", data: values[0] }],
    min: min,
    max: max,
    stepSize: stepSize,
  };

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{
          data: exportData,
          semanas,
          user,
          title,
          leyenda: "Medida",
          transposed: true,
        }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {!error && (
        <>
          <LocalFilter
            title={"Canal"}
            fulltitle={"Canal de servicio"}
            values={["Total", "Domicilio", "Llevar", "Local"]}
            selected={canal}
            setSelected={setCanal}
          />
          <BarGraph data={graphData} percentages />
        </>
      )}
    </>
  );
};

export default Graph;

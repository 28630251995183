import React, { useEffect, useState } from 'react';
import { Layer, MessageBar, MessageBarType } from '@fluentui/react';
import './style.scss'

export type INotification = "default"|"info"|"error"|"blocked"|"severeWarning"|"success"|"warning"
const Notification = ({text,type,visible,toggleVisibility,autoHide=true}:{text:string,type:INotification, visible: boolean, toggleVisibility: Function, autoHide?:boolean}) => {
	const [localVisible,setLocalVisible] = useState(false);

	useEffect(()=>{
		setTimeout(()=>{setLocalVisible(visible)},visible?20:180)
		if(visible&&autoHide) setTimeout(()=>{toggleVisibility()},10000)
	},[visible,toggleVisibility,autoHide])

	return (
		<Layer>
				<MessageBar
					messageBarType={type==="default"?undefined:MessageBarType[type]}
					isMultiline={false}
					onDismiss={()=>toggleVisibility()}
					dismissButtonAriaLabel="Close"
					className={`message-bar ${localVisible?"visible":"hidden"}`}
				>{text}</MessageBar>
		</Layer>
	);
}

export default Notification;


import { gql, useQuery } from "@apollo/client";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useContext, useMemo } from "react";
import AppContext, { IFilters } from "../../../AppContext";
import Title from "../../../components/Title";
import { Entorno, Variables } from "../../../utils/constants";
import { IAnnotation } from "../../../components/BarGraphChartJS2";
import "./style.scss";
import {
  getMedidaSQL,
  mergeKeyData,
  roundGraphNew as roundGraph,
} from "../../../utils/functions";
import {
  getStepsFixed as getSteps,
  getMinFixed as getMin,
  getMaxFixed as getMax,
} from "../../../utils/functions";
import BarGraph from "../../../components/BarGraphChartJS2";

type IValuePair = { label: string; value: number };
type IEntorno = { Totales: number; Comparables: number };
type ICadenas = {
  Nombre: string;
  Ventas: IEntorno;
  Tickets: IEntorno;
  TicketMedio: IEntorno;
  MiEspecialidad: boolean;
  MiSegmento: boolean;
  Segmentos: string[];
  Especialidades: string[];
};

export const title = "Mapa de posición relativa";
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: true,
  hasTicketMedio: true,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: true,
  showCanal: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  const medida = getMedidaSQL(filters.medidas);
  const entorno =
    filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
    ) {
      graph(
        Graph: "Mapa de posicion relativa"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 1,
      segment:
        filters.segmentType === 0
          ? filters?.segmentos[filters?.segment]
          : filters?.especialidades[filters?.segment],
      segmentType: filters.segmentType,
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let values: IValuePair[] = [],
    annotation: IAnnotation[] = [],
    sorted: IValuePair[] = [];

  let exportData: any = [];
  let annotationPos: "start" | "end" = "start";
  let max = 100;
  let min = 0;
  let maxSection = 0;
  const data = qry?.data?.graph;
  const mercadoValue = data?.Total ? data?.Total[medida][entorno] : 0;
  if (!!qry?.data) {
    try {
      if (!qry?.data?.graph)
        throw new Error("Se ha producido un error recuperando los datos");
      if (data?.Error) throw new Error(`${data.Error}`);

      console.warn("RESULT: ", data);
      const allData = filters.segment === Variables.Total;

      if (filters?.segmentType === 0) {
        data.Cadenas.forEach((d: ICadenas) => {
          if (
            allData ||
            d.Segmentos.includes(filters.segmentos[filters.segment])
          ) {
            if (typeof d[medida][entorno] === "number") {
              values.push({
                label: d.Nombre,
                value: Number(d[medida][entorno].toFixed(2)),
              });
            } else {
              let mierda = d[medida][entorno] as any;
              values.push({
                label: d.Nombre,
                value: Number(mierda.Totales.toFixed(2)),
              });
            }
          }
        });
      } else {
        data.Cadenas.forEach((d: ICadenas) => {
          if (
            allData ||
            d.Especialidades.includes(filters.especialidades[filters.segment])
          ) {
            if (typeof d[medida][entorno] === "number") {
              values.push({
                label: d.Nombre,
                value: Number(d[medida][entorno].toFixed(2)),
              });
            } else {
              let mierda = d[medida][entorno] as any;
              values.push({
                label: d.Nombre,
                value: Number(mierda.Totales.toFixed(2)),
              });
            }
          }
        });
      }

      if (filters.segmentType === 0) {
        Object.entries(data.Segmentos).forEach((segmento: any, i: number) => {
          if (
            allData ||
            segmento[0].includes(filters.segmentos[filters.segment])
          ) {
            maxSection =
              segmento[1][medida][entorno] > maxSection
                ? segmento[1][medida][entorno]
                : maxSection;
            annotation.push({
              label: segmento[0],
              type: "segmento",
              value: segmento[1][medida][entorno],
            });
          }
        });
      } else {
        Object.entries(data.Especialidades).forEach(
          (segmento: any, i: number) => {
            if (
              allData ||
              segmento[0].includes(filters.especialidades[filters.segment])
            ) {
              maxSection =
                segmento[1][medida][entorno] > maxSection
                  ? segmento[1][medida][entorno]
                  : maxSection;
              annotation.push({
                label: segmento[0],
                type: "segmento",
                value: segmento[1][medida][entorno],
              });
            }
          }
        );
      }

      sorted = values.sort((a, b) => {
        return b.value - a.value;
      });

      annotation.push({
        label: "Mercado",
        type: "mercado",
        value: mercadoValue,
      });
      annotationPos = mercadoValue > 0 ? "end" : "start";

      if (sorted.length > 0)
        max = roundGraph(Math.max(...sorted.map((d) => d.value)));
      if (sorted.length > 0)
        min = roundGraph(Math.min(...sorted.map((d) => d.value)));
      //  if (min > 0) min = 0;

      exportData = getExportData(data, semanas[0].year === 2019);
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }
  /* SE COMENTÓ ESTO PORQUE DABA PROBLEMAS DE ESCALA */
  //let m8 = Math.round(roundGraph(Math.abs(mercadoValue) * 8));
  //if (max > m8 && m8 > min) max = m8;
  //if (maxSection > max) max = roundGraph(maxSection);
  /* HASTA AQUÍ */
  if (max < 0) max = 0;
  const stepSize = useMemo(() => getSteps(min, max), [min, max]);
  const allCadenas = useMemo(
    () =>
      data === undefined || data.Cadenas === undefined
        ? []
        : data?.Cadenas.filter((cad: ICadenas) => cad.Nombre !== "").map(
            (cad: ICadenas) => cad.Nombre
          ) ?? [],
    [data]
  );

  let labels = sorted.map((d) => d.label);
  let cadenas = labels
    .filter((label) => label !== "")
    .sort((a, b) => a.localeCompare(b));

  let fmax =
    Math.round(max / stepSize) * stepSize >= max
      ? Math.round(max / stepSize) * stepSize
      : (Math.round(max / stepSize) + 1) * stepSize;

  return (
    <div className="graph-container">
      <Title
        title={title}
        error={!!error}
        files={{
          data: exportData,
          semanas,
          user,
          title,
          leyenda: "",
          transposed: false,
          secTransposed: true,
        }}
      />
      {error && <MessageBar>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {!error && (
        <BarGraph
          labels={labels}
          values={loading ? [] : [sorted.map((d) => d.value)]}
          annotation={loading ? [] : annotation}
          annotationPos={annotationPos}
          colors={["#8c0039"]}
          highlights={
            labels.length === cadenas.length
              ? undefined
              : labels.map((l) => allCadenas.indexOf(l))
          }
          stepSize={stepSize}
          min={min}
          max={max}
          responsive
          percentages
        />
      )}
    </div>
  );
};

export default Graph;

const getExportData = (data: any, isLeap: boolean) => {
  let exportData: any = [];
  let names = data.Cadenas.map((d: ICadenas) => d.Nombre); //+"#"+d.Segmentos[0])
  let segmentos: any = [];

  Object.keys(data.Segmentos).forEach((segmento: string) => {
    segmentos[segmento] = isLeap
      ? {
          "Ventas totales": data.Segmentos[segmento].Ventas.Totales,
          "Tickets totales": data.Segmentos[segmento].Tickets.Totales,
        }
      : {
          "Ventas totales": data.Segmentos[segmento].Ventas.Totales,
          "Ventas comparables": data.Segmentos[segmento].Ventas.Comparables,
          "Tickets totales": data.Segmentos[segmento].Tickets.Totales,
          "Tickets comparables": data.Segmentos[segmento].Tickets.Comparables,
          "Ticket medio total": data.Segmentos[segmento].TicketMedio.Totales,
          "Ticket medio comparable":
            data.Segmentos[segmento].TicketMedio.Comparables,
        };
  });
  segmentos["Total"] = isLeap
    ? {
        "Ventas totales": data.Total.Ventas.Totales,
        "Tickets totales": data.Total.Tickets.Totales,
      }
    : {
        "Ventas totales": data.Total.Ventas.Totales,
        "Ventas comparables": data.Total.Ventas.Comparables,
        "Tickets totales": data.Total.Tickets.Totales,
        "Tickets comparables": data.Total.Tickets.Comparables,
        "Ticket medio total": data.Total.TicketMedio.Totales,
        "Ticket medio comparable": data.Total.TicketMedio.Comparables,
      };

  exportData.push(
    isLeap
      ? {
          Cadenas: {
            "Ventas totales": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Ventas.Totales)
              ),
            },
            "Tickets totales": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Tickets.Totales)
              ),
            },
          },
        }
      : {
          Cadenas: {
            "Ventas totales": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Ventas.Totales)
              ),
            },
            "Ventas comparables": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Ventas.Comparables)
              ),
            },
            "Tickets totales": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Tickets.Totales)
              ),
            },
            "Tickets comparables": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.Tickets.Comparables)
              ),
            },
            "Ticket medio total": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.TicketMedio.Totales)
              ),
            },
            "Ticket medio comparable": {
              ...mergeKeyData(
                names,
                data.Cadenas.map((d: any) => d.TicketMedio.Comparables)
              ),
            },
          },
        }
  );
  exportData.push({
    Segmentos: { ...segmentos },
  });

  exportData.push({
    sKeys: data.Cadenas.map((d: any) => d.Segmentos[0]),
  });

  exportData.push({
    isMapaPosicionRelativa: true,
  });

  return exportData;
};

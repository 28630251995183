import moment from "moment";
import { IExcelColor, IWeeks } from "../AppContext";
import { Fill, Workbook } from "exceljs";
import { saveAs } from "file-saver";
import Colors from "../dictionaries/colors.json";

import { IFilter } from "../AppContext";
import { Entorno } from "./constants";

export const leverTypeKeys: any = [
  "Totales",
  "Canal",
  "Momento",
  "Region",
  "Ubicacion",
];
export const leverKeys: any = {
  Canal: { C0: "Total", C1: "Local", C2: "TakeAway", C3: "Delivery" },
  Momento: {
    M0: "Total",
    M1: "Mañanas",
    M2: "Tardes",
    M3: "Comidas (L-J)",
    M4: "Cenas (L-J)",
    M5: "Comidas (V-D)",
    M6: "Cenas (V-D)",
  },
  Region: {
    Z0: "Total",
    Z1: "Madrid",
    Z2: "Barcelona",
    Z3: "Norte",
    Z4: "Centro",
    Z5: "Noreste",
    Z6: "Sur",
  },
  Ubicacion: { U0: "Total", U1: "Calle", U3: "CC", U4: "Resto" },
};

export const normalize = (value: string) => {
  return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${value}</div>`;
};
export const normalizeBool = (value: boolean) => {
  return normalize(
    `<div class="check-img check-${value ? `yes` : `no`}"></div>`
  );
};
export const normalizeBoolJSX = (value: boolean) => {
  return (
    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <div className={`check-img check-${value ? "yes" : "no"}`}></div>
    </div>
  );
};
export const getPromedio = (vals: number[]) => {
  let media = 0;
  vals.forEach((val) => (media += val));
  return +(media / vals.length).toFixed(2);
};
export function zip(arr1: any[], arr2: any[], out: any = {}) {
  arr1.forEach((val, idx) => {
    out[val] = arr2[idx];
  });
  return out;
}

export const sumArrays = (...arrays: number[][]) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
};

export const avgArrays = (...arrays: number[][]): number[] => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays
      .map((xs) => xs[i] || 0)
      .reduce((sum, x) => +((sum + x) / arrays.length).toFixed(1), 0)
  );
};

/*
const countDecimals = function (value:number) {
	if(Math.floor(value) === value) return 0;
	return value.toString().split(".")[1].length || 0;
}
*/

const getFive = (n: number, step = 10): number => {
  //// Antigua solución
  //let pad=(n-Number(n.toFixed(1))>0.02)?0.1:0
  //return Number(n.toFixed(1))+pad
  let inv = 1.0 / step;
  let result = Math.round(n * inv) / inv;

  return result >= n ? result : result + step;
};
/*
export const roundGraph=(n:number) => {
	const isInteger = (num:number) => Math.trunc(num)===num
	const s = n>=0?1:-1
	if(isInteger(n)) return Math.ceil(n*0.5)/0.5
	let firstPass = Math.round(getFive(Math.abs(n))*s*100)/100
	//if(isInteger(firstPass)) return firstPass
	return Math.round(firstPass*0.2)/0.2
}
*/
export const roundGraph = (n: number) => {
  const isInteger = (num: number) => Math.trunc(num) === num;
  const s = n >= 0 ? 1 : -1;
  if (isInteger(n)) return Math.ceil(n * 0.5) / 0.5;
  let firstPass =
    Math.round(
      getFive(Math.abs(n), Math.abs(n) < 10 ? Math.trunc(n) + 1 : n) * s * 100
    ) / 100;
  //if(isInteger(firstPass)) return firstPass

  return n;
  //return Math.abs(n) < 10 ? firstPass : Math.round(firstPass * 0.2) / 0.2;
};

export const roundGraphNew = (n: number) => {
  const isInteger = (num: number) => Math.trunc(num) === num;
  const s = n >= 0 ? 1 : -1;
  if (isInteger(n)) return Math.ceil(n * 0.5) / 0.5;
  let firstPass =
    Math.round(
      getFive(Math.abs(n), Math.abs(n) < 10 ? Math.trunc(n) + 1 : n) * s * 100
    ) / 100;
  //if(isInteger(firstPass)) return firstPass

  return n;
  //return Math.abs(n) < 10 ? firstPass : Math.round(firstPass * 0.2) / 0.2;
};

export const getTicks = (min: number | undefined, max: number | undefined) => {
  if (typeof max !== "number" || typeof min !== "number")
    return { min: 0, max: 10, stepSize: 2 };

  let distancia = Math.abs(max - min);
  let stepSize = Math.pow(10, Math.round(distancia).toString().length - 1);

  let finalMin = 0;
  for (let i = 0; i > min; i -= stepSize) {
    finalMin = finalMin - stepSize;
  }

  let finalMax = 0;
  for (let i = 0; i < max; i += stepSize) {
    finalMax = finalMax + stepSize;
  }

  return { min: finalMin, max: finalMax, stepSize: stepSize };
};

export const getSteps = (min: number | undefined, max: number | undefined) => {
  if (typeof max !== "number" || typeof min !== "number") return 3;

  let distancia = Math.abs(max - min);
  let stepSize = Math.pow(10, Math.round(distancia).toString().length - 1);

  if (distancia / stepSize < 3) stepSize = stepSize / 2;
  if (distancia / stepSize > 6) stepSize = stepSize * 2;

  return stepSize;
};

export const getStepsFixed = (
  min: number | undefined,
  max: number | undefined
) => {
  if (typeof max !== "number" || typeof min !== "number") return 3;

  // get next integer from max
  let maxInt = Math.ceil(max);
  // get previous integer from min
  let minInt = Math.floor(min);
  let distance = Math.abs(maxInt) + Math.abs(minInt);

  if (distance >= 40) {
    return 4;
  } else if (distance >= 20) {
    return 2;
  } else {
    return 1;
  }
};

export const getMin = (values: any[]) => {
  if (!values || values.length === 0) return undefined;
  let result = roundGraph(
    Math.min(...values.reduce((a: any, b: any) => [...a, ...b]))
  );

  return result > 0 ? 0 : result;
};
export const getMax = (values: any[]) => {
  if (!values || values.length === 0) return undefined;
  return roundGraph(
    Math.max(...values.reduce((a: any, b: any) => [...a, ...b]))
  );
};

// get minimum value of an array of arrays
export const getMinFixed = (arr: any) => {
  let min = 0;
  arr.forEach((a: any) => {
    let minA = Math.min(...a);
    if (minA < min) min = minA;
  });
  return min;
};

// get maximum value of an array of arrays
export const getMaxFixed = (arr: any) => {
  let max = 0;
  arr.forEach((a: any) => {
    let maxA = Math.max(...a);
    if (maxA > max) max = maxA;
  });
  return max;
};

/*
function MCD(a:number,b:number){
	let resto,aux;
	if(a < b){
		aux=a;
		a=b;
		b=aux;
	}
	if ((a%b)==0) resto=b;
	while((a%b)!=0){
		resto=a%b;
		a=b;
		b=resto;
	}
	return resto||0;
}

function MCM(a:number,b:number){
	return (a*b)/MCD(a,b)
}
*/

export const cleanTitle = (title: string) => {
  const titles = {
    TicketMedio: "Ticket medio",
    VentasTotales: "Ventas totales",
    VentasComparables: "Ventas comparables",
    TicketsTotales: "Tickets totales",
    TicketsComparables: "Tickets comparables",
  };
  if (!Object.keys(titles).includes(title)) return title;
  //@ts-ignore
  return titles[title];
};

export const readablePeriod = (semanas: IWeeks) => {
  return strWeeks(semanas);
};

const strWeeks = (semanas: IWeeks) => {
  let wk1 = semanas[0];
  let wk2 = semanas[1];
  let d1 = moment(wk1.year.toString())
    .add(wk1.year.toString() === "2024" ? wk1.week - 2 : wk1.week - 1, "weeks") // era -1
    .startOf("week");
  let d2 = moment(wk2.year.toString())
    .add(wk2.year.toString() === "2024" ? wk2.week - 2 : wk2.week - 1, "weeks")
    .endOf("week");
  return strRange(d1, d2);
};
const strRange = (d1: any, d2: any) => {
  return `${d1.format("L")} - ${d2.format("L")}`;
};

export const makeExcel = async (
  format = "XLSX",
  data: any,
  semanas: IWeeks,
  user: string,
  title: string,
  leyenda: string,
  transposed?: boolean,
  secTransposed?: boolean,
  filters?: IFilter
) => {
  if (!data) return;
  //const isSegmento = (title.split(" ")[0]==="Segmentos")

  if (data[3]?.isMapaPosicionRelativa) {
    // make a copy of the data but pop the last element
    let dataCopy = [...data];
    dataCopy.pop();
    makeExcel2(
      format,
      dataCopy,
      semanas,
      user,
      title,
      leyenda,
      transposed,
      secTransposed,
      filters
    );
    return;
  }

  const pink: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };
  const green: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };
  const blue: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };

  const ameba: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "003ECDCB" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };

  const end: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "00000000" },
  };

  const vals: any[][] = data.map((d: any[]) => Object.values(d)[0]);
  let tabs: string[] = data.map((d: any[]) => Object.keys(d)[0]);

  let sKeys: any[] = [];
  if (tabs.includes("sKeys")) {
    sKeys = vals[tabs.indexOf("sKeys")];
    tabs = tabs.filter((t) => t !== "sKeys");
  }

  try {
    const workbook = new Workbook();
    let sheet = workbook.addWorksheet("Leyenda");
    sheet.getColumn(1).width = 22;
    sheet.getColumn(2).width = 28;
    ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "A9"].forEach((key) => {
      sheet.getCell(key).fill = blue.title.bg;
      sheet.getCell(key).font = blue.title.font;
    });
    ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9"].forEach((key) => {
      sheet.getCell(key).fill = blue.main.bg;
      sheet.getCell(key).font = blue.main.font;
    });

    sheet.getCell("A10").fill = end;
    sheet.getCell("B10").fill = end;

    sheet.getCell("A1").value = "Tipo de informe";
    sheet.getCell("A2").value = "Fecha de la descarga";
    sheet.getCell("A3").value = "Periodo";
    sheet.getCell("A4").value =
      user.slice(0, 3) === "com" ? "Compañía" : "Cadena";
    sheet.getCell("A5").value = "Medida";
    sheet.getCell("A6").value = "Entorno";
    sheet.getCell("A7").value = "Segmento";
    sheet.getCell("A8").value = "Variable";
    sheet.getCell("A9").value = "Subvariable";

    sheet.getCell("B1").value = title;
    sheet.getCell("B2").value = moment().format("L LTS");
    sheet.getCell("B3").value = readablePeriod(semanas);
    sheet.getCell("B4").value = user.slice(4);
    let medidaLabel = getMedidaSQL(filters?.medidas || 0);
    sheet.getCell("B5").value = medidaLabel || "Desconocida";
    let entornoLabel =
      filters?.entorno === Entorno?.Comparable ? "Comparables" : "Totales";
    sheet.getCell("B6").value = entornoLabel || "Desconocido";
    let segmentoLabel =
      filters?.segmentos[filters?.segment || 0] || "Desconocido";
    sheet.getCell("B7").value = segmentoLabel;

    let lever: string = leverTypeKeys[filters?.leverType || 0];
    sheet.getCell("B8").value = lever || "Desconocida";

    let leverName: any =
      lever !== "Totales"
        ? Object.values(leverKeys[lever])[filters?.lever || 0]
        : "Total";
    sheet.getCell("B9").value = leverName || "Desconocida";

    tabs.forEach(async (tab, t) => {
      let sheet = workbook.addWorksheet(tab);
      let hasSKeys = sKeys.length > 0 && tab === "Cadenas";
      let col = hasSKeys ? 3 : 2;

      let transpose = transposed;
      if (t > 0)
        transpose = secTransposed !== undefined ? secTransposed : transpose;

      let cols: string[] = transpose
        ? Object.keys(Object.values(vals[t])[0])
        : Object.keys(vals[t]).filter((col) => col !== "Error");

      let rows: string[] = transpose
        ? [
            "",
            leyenda,
            ...Object.keys(vals[t]).filter((col) => col !== "Error"),
          ]
        : ["", leyenda, ...Object.keys(Object.values(vals[t])[0])];

      sheet.getColumn(1).width = 15;
      for (let c = col; c < cols.length + col; c++) {
        sheet.getColumn(c).width = 18;
      }

      for (let row = 1; row <= rows.length + 1; row++) {
        if (row === rows.length + 1) {
          sheet.getRow(row).getCell(1).fill = end;
          if (hasSKeys) sheet.getRow(row).getCell(2).fill = end;
        } else {
          sheet.getRow(row).getCell(1).fill = pink.main.bg;
          sheet.getRow(row).getCell(1).font = ameba.title.font;
          if (hasSKeys) sheet.getRow(row).getCell(2).fill = pink.main.bg;
          if (row === 2) {
            sheet.getRow(row).getCell(1).font = ameba.title.font;
            sheet.getRow(row).getCell(1).fill = pink.title.bg;

            if (hasSKeys) sheet.getRow(row).getCell(2).font = ameba.title.font;
            if (hasSKeys) sheet.getRow(row).getCell(2).fill = ameba.title.bg;
          }
          let key = rows[row - 1];

          if (typeof key === "string" && key.slice(0, 5) === "temp_") key = "";
          sheet.getRow(row).getCell(1).value = key;
          if (hasSKeys && row >= 3)
            sheet.getRow(row).getCell(2).value = sKeys[row - 3];
        }
      }

      let colStart = col;
      let colEnd = cols.length + col;

      for (let row = 1; row <= rows.length + 1; row++) {
        for (let c = colStart; c < colEnd; c++) {
          if (row === rows.length + 1) {
            sheet.getRow(row).getCell(c).fill = end;
          } else {
            if (row === 2) {
              sheet.getRow(row).getCell(c).font = ameba.title.font;
              let cleanTit = cleanTitle(cols[c - col]);

              // regex to insert space before capital letters except the first one and then write them in lowercase
              cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
              // write first letter in uppercase
              cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
              sheet.getRow(row).getCell(c).value = cleanTit;
            }
            let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
              tab
            ];

            let val: any | undefined;
            if (transpose) {
              if (cData[rows[row - 1]]) {
                //@ts-ignore
                val = Object.values(cData[rows[row - 1]])[c - col];
              }
            } else {
              //@ts-ignore
              val = Object.values(cData[cols[c - col]])[row - 3];
            }

            //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

            sheet.getRow(row).getCell(c).fill =
              row <= 2 ? ameba.title.bg : ameba.main.bg;

            sheet.getRow(row).getCell(c).font = ameba.title.font;

            if (val !== null && val !== undefined) {
              if (val?.Totales) {
                val = val.Totales;
              } else if (val?.Total) {
                val = val.Total;
              } else if (typeof val === "object") {
                val = Object.values(val)[0] || 0;
              }
              let noPercentage =
                typeof val !== "number" &&
                typeof val === "string" &&
                val?.includes("#");
              /*
							if(noPercentage){
								sheet.getRow(row).getCell(c).value = val.replace("#","")
							}else{
								sheet.getRow(row).getCell(c).numFmt = '0.00%'
								sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
							}
							*/
              sheet.getRow(row).getCell(c).value = `${
                noPercentage ? val?.replace("#", "") : val
              }${noPercentage ? "" : "%"}`;
            }
          }
        }
      }

      // Titles:
      sheet.getCell(1, col).value = tab;
      sheet.getCell(1, col).font = { color: { argb: "00ffffff" } };
      sheet.getCell(1, col).font.bold = true;
      sheet.mergeCells(1, col, 1, cols.length + col - 1);
      sheet.getRow(1).alignment = { horizontal: "center" };
      sheet.getRow(2).alignment = { horizontal: "center" };

      if (format === "CSV") {
        let workbookCSV = new Workbook();
        let worksheet = workbookCSV.addWorksheet(sheet.name);
        worksheet.state = "visible";
        worksheet.model = Object.assign({}, sheet.model, {
          // @ts-ignore -- Bug en ExcelJS?
          mergeCells: sheet.model.merges,
        });
        let buf = await workbookCSV.csv.writeBuffer();
        saveAs(new Blob([buf]), `${sheet.name}.csv`);
      }
    });

    if (format === "XLSX") {
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        `${title}-medida-${medidaLabel.toLowerCase()}-entorno-${entornoLabel.toLowerCase()}-segmento-${segmentoLabel.toLowerCase()}-variable-${lever.toLowerCase()}-${leverName.toLowerCase()}.xlsx`
      );
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const mergeKeyData = (keys: string[], vals: any[]) => {
  let result: any = [];

  keys.forEach((key, i) => {
    result[key === "" ? `temp_${i}` : key] = vals[i];
  });

  return result;
};

export const getMedidaSQL = (medida: number) => {
  switch (medida) {
    default:
    case 0:
      return "Ventas";
    case 1:
      return "Tickets";
    case 2:
      return "TicketMedio";
  }
};

export const randomColor = (): string => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const getItemHexColor = (itemName: string, index: number = 0) => {
  if (itemName === "market") {
    return Colors.items.market;
  } else if (itemName === "total") {
    return Colors.items.total;
  } else if (itemName === "company") {
    return Colors.items.company;
  } else if (itemName === "chain") {
    if (index < Object.keys(Colors.items.chains).length) {
      return Object.values(Colors.items.chains)[index];
    } else {
      return randomColor();
    }
  } else if (itemName === "comparator") {
    return Object.values(Colors.items.comparator)[index];
  } else {
    if (index < Object.keys(Colors.items.segments).length) {
      return Object.values(Colors.items.segments)[index];
    } else {
      return randomColor();
    }
  }
};

// Okay, lets do this

export const makeExcel2 = async (
  format = "XLSX",
  data: any,
  semanas: IWeeks,
  user: string,
  title: string,
  leyenda: string,
  transposed?: boolean,
  secTransposed?: boolean,
  filters?: IFilter
) => {
  if (!data) return;
  //const isSegmento = (title.split(" ")[0]==="Segmentos")

  // Sort all data values in ascending order

  const pink: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };
  const green: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };
  const blue: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };

  const ameba: IExcelColor = {
    main: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "003ECDCB" } },
      font: { color: { argb: "00ffffff" } },
    },
    title: {
      bg: { type: "pattern", pattern: "solid", fgColor: { argb: "000B6564" } },
      font: { color: { argb: "00ffffff" } },
    },
  };

  const end: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "00000000" },
  };

  const vals: any[][] = data.map((d: any[]) => Object.values(d)[0]);
  let tabs: string[] = data.map((d: any[]) => Object.keys(d)[0]);

  let sKeys: any[] = [];
  if (tabs.includes("sKeys")) {
    sKeys = vals[tabs.indexOf("sKeys")];
    tabs = tabs.filter((t) => t !== "sKeys");
  }

  try {
    const workbook = new Workbook();
    let sheet = workbook.addWorksheet("Leyenda");
    sheet.getColumn(1).width = 22;
    sheet.getColumn(2).width = 28;
    ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "A9"].forEach((key) => {
      sheet.getCell(key).fill = blue.title.bg;
      sheet.getCell(key).font = blue.title.font;
    });
    ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9"].forEach((key) => {
      sheet.getCell(key).fill = blue.main.bg;
      sheet.getCell(key).font = blue.main.font;
    });

    sheet.getCell("A10").fill = end;
    sheet.getCell("B10").fill = end;

    sheet.getCell("A1").value = "Tipo de informe";
    sheet.getCell("A2").value = "Fecha de la descarga";
    sheet.getCell("A3").value = "Periodo";
    sheet.getCell("A4").value =
      user.slice(0, 3) === "com" ? "Compañía" : "Cadena";
    sheet.getCell("A5").value = "Medida";
    sheet.getCell("A6").value = "Entorno";
    sheet.getCell("A7").value = "Segmento";
    sheet.getCell("A8").value = "Variable";
    sheet.getCell("A9").value = "Subvariable";

    sheet.getCell("B1").value = title;
    sheet.getCell("B2").value = moment().format("L LTS");
    sheet.getCell("B3").value = readablePeriod(semanas);
    sheet.getCell("B4").value = user.slice(4);
    let medidaLabel = getMedidaSQL(filters?.medidas || 0);
    sheet.getCell("B5").value = medidaLabel || "Desconocida";
    let entornoLabel =
      filters?.entorno === Entorno?.Comparable ? "Comparables" : "Totales";
    sheet.getCell("B6").value = entornoLabel || "Desconocido";
    let segmentoLabel =
      filters?.segmentos[filters?.segment || 0] || "Desconocido";
    sheet.getCell("B7").value = segmentoLabel;

    let lever: string = leverTypeKeys[filters?.leverType || 0];
    sheet.getCell("B8").value = lever || "Desconocida";

    let leverName: any =
      lever !== "Totales"
        ? Object.values(leverKeys[lever])[filters?.lever || 0]
        : "Total";
    sheet.getCell("B9").value = leverName || "Desconocida";

    tabs.forEach(async (tab, t) => {
      let sheet = workbook.addWorksheet(tab);
      let hasSKeys = sKeys.length > 0 && tab === "Cadenas";
      let col = hasSKeys ? 3 : 2;

      let transpose = transposed;
      if (t > 0)
        transpose = secTransposed !== undefined ? secTransposed : transpose;

      let cols: string[] = transpose
        ? Object.keys(Object.values(vals[t])[0])
        : Object.keys(vals[t]).filter((col) => col !== "Error");

      let rows: string[] = transpose
        ? [
            "",
            leyenda,
            ...Object.keys(vals[t]).filter((col) => col !== "Error"),
          ]
        : ["", leyenda, ...Object.keys(Object.values(vals[t])[0])];

      sheet.getColumn(1).width = 18;
      for (let c = col; c < cols.length + col; c++) {
        sheet.getColumn(c).width = 18;
      }
      for (let row = 1; row <= rows.length + 1; row++) {
        if (row === rows.length + 1) {
          sheet.getRow(row).getCell(1).fill = end;
          if (hasSKeys) sheet.getRow(row).getCell(2).fill = end;
        } else {
          sheet.getRow(row).getCell(1).fill = pink.main.bg;
          sheet.getRow(row).getCell(1).font = ameba.title.font;
          if (hasSKeys) sheet.getRow(row).getCell(2).fill = pink.main.bg;
          if (row === 2) {
            sheet.getRow(row).getCell(1).font = ameba.title.font;
            sheet.getRow(row).getCell(1).fill = pink.title.bg;

            if (hasSKeys) sheet.getRow(row).getCell(2).font = ameba.title.font;
            if (hasSKeys) sheet.getRow(row).getCell(2).fill = ameba.title.bg;
          }
          let key = rows[row - 1];
          if (tab === "Cadenas") {
            // FIX ERA SIN ESTE BLOQUE IF
            if (typeof key === "string" && key.slice(0, 5) === "temp_")
              key = "";
            sheet.getRow(row).getCell(1).value = sKeys[row - 3];
            if (hasSKeys && row >= 3) sheet.getRow(row).getCell(2).value = key;

            sheet.getColumn(1).width = 15;
            sheet.getColumn(2).width = 15;
            // Change text color of colum 2 to white
            sheet.getColumn(2).eachCell((cell) => {
              cell.font = { color: { argb: "00ffffff" } };
            });
          } else {
            // FIX ERA SOLO ESTO
            if (typeof key === "string" && key.slice(0, 5) === "temp_")
              key = "";
            sheet.getRow(row).getCell(1).value = key;
            if (hasSKeys && row >= 3)
              sheet.getRow(row).getCell(2).value = sKeys[row - 3];
          }
        }
      }

      let colStart = col;
      let colEnd = cols.length + col;
      //return; // hasta aqui solo lista de cadenas

      for (let row = 1; row <= rows.length + 1; row++) {
        for (let c = colStart; c < 4; c++) {
          if (row === rows.length + 1) {
            sheet.getRow(row).getCell(c).fill = end;
          } else {
            if (row === 2) {
              sheet.getRow(row).getCell(c).font = ameba.title.font;
              let cleanTit = cleanTitle(cols[c - col]);

              // regex to insert space before capital letters except the first one and then write them in lowercase
              cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
              // write first letter in uppercase
              cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
              sheet.getRow(row).getCell(c).value = cleanTit;
            }
            let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
              tab
            ];

            let val: any | undefined;
            if (transpose) {
              if (cData[rows[row - 1]]) {
                //@ts-ignore
                val = Object.values(cData[rows[row - 1]])[c - col];
              }
            } else {
              //@ts-ignore
              val = Object.values(cData[cols[c - col]])[row - 3];
            }

            //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

            sheet.getRow(row).getCell(c).fill =
              row <= 1 ? ameba.title.bg : ameba.main.bg;

            sheet.getRow(row).getCell(c).font = ameba.title.font;

            if (val !== null && val !== undefined) {
              if (val?.Totales) {
                val = val.Totales;
              } else if (val?.Total) {
                val = val.Total;
              } else if (typeof val === "object") {
                val = Object.values(val)[0] || 0;
              }
              let noPercentage =
                typeof val !== "number" &&
                typeof val === "string" &&
                val?.includes("#");
              /*
							if(noPercentage){
								sheet.getRow(row).getCell(c).value = val.replace("#","")
							}else{
								sheet.getRow(row).getCell(c).numFmt = '0.00%'
								sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
							}
							*/
              sheet.getRow(row).getCell(c).value = `${
                noPercentage ? val?.replace("#", "") : val
              }${noPercentage ? "" : "%"}`;
            }
          }
          // if first char != %, add it
        }

        if (tab === "Cadenas") {
          for (let c = 4; c < 5; c++) {
            sheet.getRow(row).getCell(c).fill = end;
          }

          /* Se repite */

          // Set width of column 4 to 5
          sheet.getColumn(4).width = 5;
          sheet.getColumn(4).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffffffff" },
            };
          });

          // fill column 5 with black
          sheet.getColumn(5).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });
          sheet.getColumn(6).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });

          // copy column 1 to column 5
          let col1 = sheet.getColumn(1).values;
          sheet.getColumn(5).values = col1;

          let col2 = sheet.getColumn(2).values;
          sheet.getColumn(6).values = col2;

          /* fin de se repite */

          // VENTAS COMPARABLES

          for (let c = 7; c < 8; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[1]);

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[1];
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[1]])[row - 3];
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
							if(noPercentage){
								sheet.getRow(row).getCell(c).value = val.replace("#","")
							}else{
								sheet.getRow(row).getCell(c).numFmt = '0.00%'
								sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
							}
							*/
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }
            // if first char != %, add it
          }

          /* Se repite */

          // Set width of column 4 to 5
          sheet.getColumn(8).width = 5;
          sheet.getColumn(8).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffffffff" },
            };
          });

          // copy column 1 to column 5
          sheet.getColumn(9).values = col1;
          sheet.getColumn(9).width = 15;
          sheet.getColumn(10).values = col2;
          sheet.getColumn(10).width = 15;
          sheet.getColumn(9).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });
          sheet.getColumn(10).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });

          /* fin de se repite */

          // TICKETS TOTALES
          sheet.getColumn(11).width = 18;
          for (let c = 11; c < 12; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[2]);

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[2];
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[2]])[row - 3];
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
							if(noPercentage){
								sheet.getRow(row).getCell(c).value = val.replace("#","")
							}else{
								sheet.getRow(row).getCell(c).numFmt = '0.00%'
								sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
							}
							*/
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }
            // if first char != %, add it
          }
          // Set width of column 4 to 5
          sheet.getColumn(12).width = 5;
          sheet.getColumn(12).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffffffff" },
            };
          });

          // copy column 1 to column 5
          sheet.getColumn(13).values = col1;
          sheet.getColumn(13).width = 15;
          sheet.getColumn(14).values = col2;
          sheet.getColumn(14).width = 15;
          sheet.getColumn(13).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });
          sheet.getColumn(14).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });

          /* fin de se repite */

          // TICKETS TOTALES
          sheet.getColumn(15).width = 18;
          for (let c = 15; c < 16; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[3]);

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[3];
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[3]])[row - 3];
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
							 if(noPercentage){
								 sheet.getRow(row).getCell(c).value = val.replace("#","")
							 }else{
								 sheet.getRow(row).getCell(c).numFmt = '0.00%'
								 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
							 }
							 */
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }

            // Set width of column 4 to 5
            sheet.getColumn(16).width = 5;
            sheet.getColumn(16).eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "ffffffff" },
              };
            });

            // copy column 1 to column 5
            sheet.getColumn(17).values = col1;
            //
            sheet.getColumn(17).width = 15;
            sheet.getColumn(18).values = col2;
            sheet.getColumn(18).width = 15;
            sheet.getColumn(17).eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF096564" },
              };
              cell.font = { color: { argb: "00ffffff" } };
            });
            sheet.getColumn(18).eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF096564" },
              };
              cell.font = { color: { argb: "00ffffff" } };
            });

            /* fin de se repite */

            // TICKETS TOTALES
            sheet.getColumn(19).width = 18;
            for (let c = 19; c < 20; c++) {
              if (row === rows.length + 1) {
                sheet.getRow(row).getCell(c).fill = end;
              } else {
                if (row === 2) {
                  sheet.getRow(row).getCell(c).font = ameba.title.font;
                  let cleanTit = cleanTitle(cols[4]); // AQUII

                  // regex to insert space before capital letters except the first one and then write them in lowercase
                  cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                  // write first letter in uppercase
                  cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                  sheet.getRow(row).getCell(c).value = cleanTit;
                }
                let cData = data.filter(
                  (d: any) => Object.keys(d)[0] === tab
                )[0][tab];

                let val: any | undefined;
                if (transpose) {
                  if (cData[rows[row - 1]]) {
                    //@ts-ignore
                    val = Object.values(cData[rows[row - 1]])[4]; // AQUI
                  }
                } else {
                  //@ts-ignore
                  val = Object.values(cData[cols[4]])[row - 3]; // AQUI
                }

                //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

                sheet.getRow(row).getCell(c).fill =
                  row <= 1 ? ameba.title.bg : ameba.main.bg;

                sheet.getRow(row).getCell(c).font = ameba.title.font;

                if (val !== null && val !== undefined) {
                  if (val?.Totales) {
                    val = val.Totales;
                  } else if (val?.Total) {
                    val = val.Total;
                  } else if (typeof val === "object") {
                    val = Object.values(val)[0] || 0;
                  }
                  let noPercentage =
                    typeof val !== "number" &&
                    typeof val === "string" &&
                    val?.includes("#");
                  /*
								 if(noPercentage){
									 sheet.getRow(row).getCell(c).value = val.replace("#","")
								 }else{
									 sheet.getRow(row).getCell(c).numFmt = '0.00%'
									 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
								 }
								 */
                  sheet.getRow(row).getCell(c).value = `${
                    noPercentage ? val?.replace("#", "") : val
                  }${noPercentage ? "" : "%"}`;
                }
              }
            }
          }

          // Set width of column 4 to 5
          sheet.getColumn(20).width = 5;
          sheet.getColumn(20).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFFFF" },
            };
          });

          // copy column 1 to column 5
          sheet.getColumn(21).values = col1;
          sheet.getColumn(21).width = 15;
          sheet.getColumn(22).values = col2;
          sheet.getColumn(22).width = 15;
          sheet.getColumn(21).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });
          sheet.getColumn(22).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF096564" },
            };
            cell.font = { color: { argb: "00ffffff" } };
          });

          // TICKETS TOTALES
          sheet.getColumn(23).width = 18;
          for (let c = 23; c < 24; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[5]); // AQUII

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[5]; // AQUI
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[5]])[row - 3]; // AQUI
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
								 if(noPercentage){
									 sheet.getRow(row).getCell(c).value = val.replace("#","")
								 }else{
									 sheet.getRow(row).getCell(c).numFmt = '0.00%'
									 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
								 }
								 */
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }
          }
          for (let c = 1; c < 23; c++) {
            if (row === rows.length + 1) {
              if (c === 4 || c === 8 || c === 12 || c === 16 || c === 20) {
                // paint it white
                const start: Fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FFFFFFFF" },
                };
                sheet.getRow(row).getCell(c).fill = start;
              } else {
                sheet.getRow(row).getCell(c).fill = end;
              }
            }
            if (row === 1) {
              if (c === 4 || c === 8 || c === 12 || c === 16 || c === 20) {
                // paint it white
                const start: Fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FFFFFFFF" },
                };
                sheet.getRow(row).getCell(c).fill = start;
              } else {
                sheet.getRow(row).getCell(c).fill = ameba.title.bg;
              }
            }
          }
        } else {
          // la otra tab
          // MORRALLA

          // TICKETS TOTALES
          sheet.getColumn(4).width = 18;
          for (let c = 4; c < 5; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[2]);

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[2];
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[2]])[row - 3];
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
		if(noPercentage){
			sheet.getRow(row).getCell(c).value = val.replace("#","")
		}else{
			sheet.getRow(row).getCell(c).numFmt = '0.00%'
			sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
		}
		*/
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }
            // if first char != %, add it
          }

          // TICKETS TOTALES
          sheet.getColumn(5).width = 18;
          for (let c = 5; c < 6; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[3]);

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[3];
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[3]])[row - 3];
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
		 if(noPercentage){
			 sheet.getRow(row).getCell(c).value = val.replace("#","")
		 }else{
			 sheet.getRow(row).getCell(c).numFmt = '0.00%'
			 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
		 }
		 */
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }

            // TICKETS TOTALES
            sheet.getColumn(6).width = 18;
            for (let c = 6; c < 7; c++) {
              if (row === rows.length + 1) {
                sheet.getRow(row).getCell(c).fill = end;
              } else {
                if (row === 2) {
                  sheet.getRow(row).getCell(c).font = ameba.title.font;
                  let cleanTit = cleanTitle(cols[4]); // AQUII

                  // regex to insert space before capital letters except the first one and then write them in lowercase
                  cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                  // write first letter in uppercase
                  cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                  sheet.getRow(row).getCell(c).value = cleanTit;
                }
                let cData = data.filter(
                  (d: any) => Object.keys(d)[0] === tab
                )[0][tab];

                let val: any | undefined;
                if (transpose) {
                  if (cData[rows[row - 1]]) {
                    //@ts-ignore
                    val = Object.values(cData[rows[row - 1]])[4]; // AQUI
                  }
                } else {
                  //@ts-ignore
                  val = Object.values(cData[cols[4]])[row - 3]; // AQUI
                }

                //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

                sheet.getRow(row).getCell(c).fill =
                  row <= 1 ? ameba.title.bg : ameba.main.bg;

                sheet.getRow(row).getCell(c).font = ameba.title.font;

                if (val !== null && val !== undefined) {
                  if (val?.Totales) {
                    val = val.Totales;
                  } else if (val?.Total) {
                    val = val.Total;
                  } else if (typeof val === "object") {
                    val = Object.values(val)[0] || 0;
                  }
                  let noPercentage =
                    typeof val !== "number" &&
                    typeof val === "string" &&
                    val?.includes("#");
                  /*
			 if(noPercentage){
				 sheet.getRow(row).getCell(c).value = val.replace("#","")
			 }else{
				 sheet.getRow(row).getCell(c).numFmt = '0.00%'
				 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
			 }
			 */
                  sheet.getRow(row).getCell(c).value = `${
                    noPercentage ? val?.replace("#", "") : val
                  }${noPercentage ? "" : "%"}`;
                }
              }
            }
          }

          // TICKETS TOTALES
          sheet.getColumn(7).width = 18;
          for (let c = 7; c < 8; c++) {
            if (row === rows.length + 1) {
              sheet.getRow(row).getCell(c).fill = end;
            } else {
              if (row === 2) {
                sheet.getRow(row).getCell(c).font = ameba.title.font;
                let cleanTit = cleanTitle(cols[5]); // AQUII

                // regex to insert space before capital letters except the first one and then write them in lowercase
                cleanTit = cleanTit.replace(/([A-Z])/g, " $1").toLowerCase();
                // write first letter in uppercase
                cleanTit = cleanTit[1].toUpperCase() + cleanTit.slice(2);
                sheet.getRow(row).getCell(c).value = cleanTit;
              }
              let cData = data.filter((d: any) => Object.keys(d)[0] === tab)[0][
                tab
              ];

              let val: any | undefined;
              if (transpose) {
                if (cData[rows[row - 1]]) {
                  //@ts-ignore
                  val = Object.values(cData[rows[row - 1]])[5]; // AQUI
                }
              } else {
                //@ts-ignore
                val = Object.values(cData[cols[5]])[row - 3]; // AQUI
              }

              //if(typeof val==="string"&&val.slice(0,5)==="temp_") val=""

              sheet.getRow(row).getCell(c).fill =
                row <= 1 ? ameba.title.bg : ameba.main.bg;

              sheet.getRow(row).getCell(c).font = ameba.title.font;

              if (val !== null && val !== undefined) {
                if (val?.Totales) {
                  val = val.Totales;
                } else if (val?.Total) {
                  val = val.Total;
                } else if (typeof val === "object") {
                  val = Object.values(val)[0] || 0;
                }
                let noPercentage =
                  typeof val !== "number" &&
                  typeof val === "string" &&
                  val?.includes("#");
                /*
			 if(noPercentage){
				 sheet.getRow(row).getCell(c).value = val.replace("#","")
			 }else{
				 sheet.getRow(row).getCell(c).numFmt = '0.00%'
				 sheet.getRow(row).getCell(c).value = typeof val === "number"?val/100:val
			 }
			 */
                sheet.getRow(row).getCell(c).value = `${
                  noPercentage ? val?.replace("#", "") : val
                }${noPercentage ? "" : "%"}`;
              }
            }
          }
        }
      }

      return;
      // Titles:
      sheet.getCell(1, col).value = tab;
      sheet.getCell(1, col).font = { color: { argb: "00ffffff" } };
      sheet.getCell(1, col).font.bold = true;
      sheet.mergeCells(1, col, 1, cols.length + col - 1);
      sheet.getRow(1).alignment = { horizontal: "center" };
      sheet.getRow(2).alignment = { horizontal: "center" };

      if (tab === "Cadenas") {
        /*
        sheet.getColumn(1).width = 15;
        sheet.getColumn(2).width = 15;
        // Make a deep copy of column 1
        let col1 = sheet.getColumn(1).values;
        // Make a deep copy of column 2
        let col2 = sheet.getColumn(2).values;
        // Copy column 1 to column 2
        sheet.getColumn(2).values = col1;
        // Copy column 2 to column 1
        sheet.getColumn(1).values = col2;
				*/
        /*
        let col1 = sheet.getColumn(1).values;
        let col2 = sheet.getColumn(2).values;
        let col3 = sheet.getColumn(3).values;
        let col4 = sheet.getColumn(4).values;
        let col5 = sheet.getColumn(5).values;
        let col6 = sheet.getColumn(6).values;
        let col7 = sheet.getColumn(7).values;
        let col8 = sheet.getColumn(8).values;

        sheet.getColumn(4).values = col1;
        sheet.getColumn(5).values = col2;
        sheet.getColumn(6).values = col4;

        sheet.getColumn(7).values = col1;
        sheet.getColumn(8).values = col2;
        sheet.getColumn(9).values = col5;

        sheet.getColumn(10).values = col1;
        sheet.getColumn(11).values = col2;
        sheet.getColumn(12).values = col6;

        sheet.getColumn(13).values = col1;
        sheet.getColumn(14).values = col2;
        sheet.getColumn(15).values = col7;

        sheet.getColumn(16).values = col1;
        sheet.getColumn(17).values = col2;
        sheet.getColumn(18).values = col8;
				*/
      }

      if (format === "CSV") {
        let workbookCSV = new Workbook();
        let worksheet = workbookCSV.addWorksheet(sheet.name);
        worksheet.state = "visible";
        worksheet.model = Object.assign({}, sheet.model, {
          // @ts-ignore -- Bug en ExcelJS?
          mergeCells: sheet.model.merges,
        });
        let buf = await workbookCSV.csv.writeBuffer();
        saveAs(new Blob([buf]), `${sheet.name}.csv`);
      }
    });

    if (format === "XLSX") {
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        `${title}-medida-${medidaLabel.toLowerCase()}-entorno-${entornoLabel.toLowerCase()}-segmento-${segmentoLabel.toLowerCase()}-variable-${lever.toLowerCase()}-${leverName.toLowerCase()}.xlsx`
      );
    }
  } catch (err: any) {
    console.log(err);
  }
};

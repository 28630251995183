import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import ApolloLinkTimeout from "apollo-link-timeout";
import App from "./App";
import "./index.scss";

const timeoutLink = new ApolloLinkTimeout(60 * 15 * 1000); // 15 min timeout

let backUri =
  process.env.NODE_ENV === "production"
    ? "/api"
    : `http://${window.location.hostname || "localhost"}:4000/api`;

//backUri = `http://${window.location.hostname || "localhost"}:4000/api`;
const httpLink = createHttpLink({
  uri: backUri,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
});

const client = new ApolloClient({
  // @ts-ignore -- Parece haber algún tipo de error con el tipado de ApolloLink
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

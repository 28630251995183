import { Dropdown, DropdownMenuItemType, FontIcon, IDropdownOption } from '@fluentui/react';
import React, { useState } from 'react';
import './style.scss'

const onRenderCaretDown = (): JSX.Element => {
	return <FontIcon iconName="CaretSolidDown" />;
};

const LocalFilter = ({title, values, selected, fulltitle, setSelected}:{title: string, values: string[], selected: {key:string, text:string}, fulltitle:string, setSelected: Function}) => {
	const options = () => {
		//if (disabled) return [{key: "0", text:"No aplica"}]
		let opts: IDropdownOption[] = [{ key: 'header', text: fulltitle, itemType: DropdownMenuItemType.Header }]
		if(!values) return opts
		values.forEach((val,i)=>{
			if(val!=="") opts.push({ key: `${i}`, text: val })
		})
		return opts;
	}

	const onChange = (_e:any, i:any): void => {
		setSelected(i)
		setSelection(i)
	};

	const [selection, setSelection] = useState(selected)

	return (
		<>
		<div className="local-filter">
			<Dropdown
					selectedKey={selection.key}
					//onTouchEnd={handleTouchEnd}
					onChange={onChange}
					placeholder={title}
					onRenderCaretDown={onRenderCaretDown}
					options={options()}
					disabled={false}
				></Dropdown>
			<span className={`dropdown-title`}>{title}</span>
		</div>
		</>
	);
}

export default LocalFilter;


import React, { useEffect, useState } from "react";
import {
  initializeIcons,
  Layer,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import { loadTheme } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HeaderAdmin from "./admin/Header";
import SectionsAdmin from "./admin/sections";
import Header from "./layout/Header";
import Sections from "./layout/Sections";
import Login from "./layout/Login";
import Reset from "./layout/Reset";
import { light, dark } from "./utils/themes";
import { gql, useMutation, useQuery } from "@apollo/client";
import "./App.scss";
import Filters from "./layout/Filters";
import AppContext, { IWeeks, IFilter, defaults, IWeek } from "./AppContext";
import FullScreenLoading from "./components/FullScreenLoading";
import FullScreenError from "./components/FullScreenError";
import Modal from "./components/Modal";
import AvisoLegal from "./components/AvisoLegal";
import BetaWarning from "./components/BetaWarning";

loadTheme(dark);
initializeIcons();

const GET_PERMISSIONS = gql`
  mutation ($token: String!) {
    login(input: { Token: $token }) {
      canRead
      canWrite
      canDownload
      isAdmin
      isUser
      Error
      Parent
      userName
    }
  }
`;

const INITIAL_DATA = gql`
  query ($showAll: Boolean) {
    getInitialData(showAll: $showAll)
  }
`;

function App() {
  const [user, setUser] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState(
    "No se ha podido conectar con la base de datos."
  );
  //const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("auth-token")
  );
  const [auth, setAuth] = useState<{
    Token: string;
    canRead: boolean;
    canWrite: boolean;
    canDownload: boolean;
    isAdmin: boolean;
    isUser: boolean;
  }>(); // Esto no es seguro por diseño. La seguridad ha de estar siempre relegada al lado servidor
  const [theme, setTheme] = useState<"light" | "dark">("dark");
  const [locked, setLocked] = useState<boolean>(true);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const [resetPage, setResetPage] = useState(false);
  const [maintenance, setMaintenance] = useState(false);

  const [section, setSection] = useState<number>(0);
  const [sectionItem, setSectionItem] = useState<any>(0);
  const [semanasDatos, setSemanasDatos] = useState<IWeeks>(defaults.weeks);

  const [semanas, setSemanas] = useState<IWeeks>(defaults.weeks);
  const [cadenas, setCadenas] = useState<string[]>([]);
  const [filters, setFilters] = useState<IFilter>(defaults.filters);
  const [isWeekSet, setIsWeekSet] = useState(false);

  const [getPermissions] = useMutation(GET_PERMISSIONS);
  let init = useQuery(INITIAL_DATA, {
    fetchPolicy: "network-only",
    variables: { showAll: false },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    let cTheme = theme === "light" ? light : dark;
    loadTheme(cTheme);
    document.documentElement.style.setProperty(
      "--text-color",
      cTheme.palette.black
    );
    document.documentElement.style.setProperty(
      "--bg-color",
      cTheme.palette.white
    );
    document.documentElement.style.setProperty(
      "--primary-color",
      cTheme.palette.themePrimary
    );
  }, [theme]);

  useEffect(() => {
    if (window.location.search.includes("reset")) setResetPage(true);
    if (!isWeekSet) {
      try {
        let date: { min: IWeek; max: IWeek } = init?.data?.getInitialData?.date;
        if (!!date?.min?.year) {
          setSemanasDatos([
            { year: date.min.year, week: date.min.week },
            { year: date.max.year, week: date.max.week },
          ]);
          setSemanas([
            { year: date.max.year, week: date.max.week },
            { year: date.max.year, week: date.max.week },
          ]);
          setIsWeekSet(true);
        }
        if (!!init?.data?.getInitialData?.cadenas)
          setCadenas(
            init?.data?.getInitialData?.cadenas.map((cadena: any) =>
              cadena.replace("cad_", "Cadena ")
            )
          );
      } catch (err: any) {
        console.log(err);
      }
      if (!!init.error) setError(true);
    }
  }, [init, isWeekSet]);

  useEffect(() => {
    setMaintenance(!!init?.data?.getInitialData?.maintenance);
    if (
      token &&
      init?.data?.getInitialData?.maintenance &&
      auth &&
      !auth?.isAdmin
    ) {
      setErrorMsg("Estamos realizando mejoras en la aplicación.");
      setError(true);
    }
  }, [init, token, auth, init?.data?.getInitialData?.maintenance]);

  useEffect(() => {
    if (token && auth) {
      setTheme(auth.isAdmin && !auth.isUser ? "light" : "dark");
      if (auth.Token !== token) {
        setToken(null);
        setAuth(undefined);
      }
    }

    if (token && !auth) {
      getPermissions({ variables: { token: token } }).then(
        (res: any) => {
          let p = res.data.login;

          if (p && p.Error === "") {
            let cAuth = {
              Token: "",
              canRead: false,
              canWrite: false,
              canDownload: false,
              isAdmin: false,
              isUser: true,
            };
            cAuth.Token = token;
            cAuth.canRead = p.canRead;
            cAuth.canWrite = p.canWrite;
            cAuth.canDownload = p.canDownload;
            cAuth.isAdmin = p.isAdmin;
            cAuth.isUser = p.isUser;
            setUser(p.Parent);
            setAuth(cAuth);
            setUserName(p.userName);
          }
        },
        function (err: any) {
          setError(true);
        }
      );
    }
  }, [token, auth, getPermissions, isWeekSet]);

  //useEffect(()=>{
  //	if(process.env.REACT_APP_ENV) document.querySelector('body')?.classList.add(`${process.env.REACT_APP_ENV}`.trim())
  //},[])

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(true);

  const handleHide = () => {
    setLocked(false);
    hideModal();
  };
  const handleShow = () => {
    setLocked(true);
    showModal();
  };

  return (
    <AppContext.Provider
      value={{
        semanas,
        setSemanas,
        filters,
        setFilters,
        section,
        setSection,
        sectionItem,
        setSectionItem,
        semanasDatos,
        cadenas,
        user,
        userName,
      }}
    >
      {resetPage && <Reset setToken={setToken}></Reset>}
      {!resetPage && (
        <>
          {error && (
            <FullScreenError
              title={errorMsg}
              subtitle="Por favor, acceda de nuevo más tarde."
            />
          )}

          {init?.loading && <FullScreenLoading />}

          {!auth && !token && <Login setToken={setToken}></Login>}

          {auth && token && (
            <>
              {auth.isAdmin && !auth.isUser && (
                <>
                  <HeaderAdmin section={section} setSection={setSection} />
                  <SectionsAdmin section={section} />
                  {maintenance && (
                    <Layer>
                      <MessageBar
                        messageBarType={MessageBarType.severeWarning}
                        className={`message-bar visible`}
                      >
                        La aplicación está en modo mantenimiento. Sólo el
                        administrador puede acceder.
                      </MessageBar>
                    </Layer>
                  )}
                </>
              )}
              {auth.isUser && (
                <>
                  <BetaWarning />
                  <Modal
                    title="Aviso legal y política de privacidad del Monitor de Ventas en Restauración"
                    isOpen={isModalOpen}
                    hideModal={handleHide}
                    showModal={handleShow}
                    hideCloseButton={true}
                    darkMode
                  >
                    <AvisoLegal />
                    <div className="acceptterms-container">
                      <PrimaryButton
                        text="Acepto los términos"
                        onClick={() => {
                          hideModal();
                          setLocked(false);
                        }}
                        allowDisabledFocus
                      />
                    </div>
                  </Modal>
                  <Header
                    auth={auth}
                    setLocked={setLocked}
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                  />
                  <Sections auth={auth} locked={locked} />
                  {auth?.canRead && (
                    <Filters
                      filtersOpen={filtersOpen}
                      setFiltersOpen={setFiltersOpen}
                    />
                  )}
                  {auth?.isAdmin && maintenance && (
                    <Layer>
                      <MessageBar
                        messageBarType={MessageBarType.severeWarning}
                        className={`message-bar visible`}
                      >
                        La aplicación está en modo mantenimiento. Sólo el
                        administrador puede acceder.
                      </MessageBar>
                    </Layer>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </AppContext.Provider>
  );
}

export default App;

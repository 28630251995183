import React, { useRef, useContext } from "react";
import { IWeeks } from "../../AppContext";
import { makeExcel } from "../../utils/functions";
import "./style.scss";
import AppContext from "../../AppContext";

const ManualIcon = ({
  files,
  error,
}: {
  files: {
    data: any;
    semanas: IWeeks;
    user: string;
    title: string;
    leyenda: string;
    transposed?: boolean;
    secTransposed?: boolean;
  };
  error?: boolean;
}) => {
  const menuRef = useRef<any>(null);
  const scrimRef = useRef<any>(null);
  const { filters } = useContext(AppContext);

  const toggleDownload = () => {
    //@ts-ignore
    if (menuRef?.current)
      menuRef.current.classList.toggle("apexcharts-menu-open");
    //@ts-ignore
    if (scrimRef?.current) scrimRef.current.classList.toggle("open");
  };

  const toggleButton = (e: any) => {
    toggleDownload();
  };

  return (
    <>
      <div className="manual-icon" onClick={toggleDownload}>
        <div className="apexcharts-toolbar">
          <div className="apexcharts-menu-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </div>
          <div ref={menuRef} className="apexcharts-menu">
            {!error && (
              <>
                <div
                  onClick={() =>
                    makeExcel(
                      "XLSX",
                      files.data,
                      files.semanas,
                      files.user,
                      files.title,
                      files.leyenda,
                      files.transposed,
                      files.secTransposed,
                      filters
                    )
                  }
                  className="apexcharts-menu-item exportXLS"
                  title="Descargar todos los datos"
                >
                  Exportar datos a Excel
                </div>
                <div
                  onClick={() =>
                    makeExcel(
                      "CSV",
                      files.data,
                      files.semanas,
                      files.user,
                      files.title,
                      files.leyenda,
                      files.transposed,
                      files.secTransposed
                    )
                  }
                  className="apexcharts-menu-item exportCSV"
                  title="Descargar todos los datos"
                >
                  Exportar datos a CSV
                </div>
              </>
            )}
            {!!error && (
              <div className="apexcharts-menu-item">Datos no disponibles</div>
            )}
          </div>
        </div>
      </div>
      <div ref={scrimRef} onClick={toggleButton} className="scrim"></div>
    </>
  );
};

export default ManualIcon;

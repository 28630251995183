import React, { useContext, useMemo, useEffect } from "react";
import Title from "../../../components/Title";
import LineGraph from "../../../components/ResponsiveLine";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import AppContext, { IFilters } from "../../../AppContext";
import { Entorno } from "../../../utils/constants";
import {
  getMax,
  getMedidaSQL,
  getMin,
  getSteps,
  getTicks,
  mergeKeyData,
  roundGraph,
} from "../../../utils/functions";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { GraphNotCompatible } from "../../../components/GraphNotCompatible";
import "./style.scss";

export const title = `Cuota de mercado`;
export const filters: IFilters = {
  limitedPeriodo: true,
  showMedidas: true,
  hasTicketMedio: false,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: true,
  showMyData: false,
  showCanal: false,
  showVariable: true,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  let currentFilters = React.useRef<any>(null);
  const medida = getMedidaSQL(filters.medidas);
  let entorno =
    filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

  let leverTypeKeys: any = [
    "Totales",
    "Canal",
    "Momento",
    "Region",
    "Ubicacion",
  ];
  let leverKeys: any = {
    Canal: ["Total", "Local", "TakeAway", "Delivery"],
    Momento: [
      "Total",
      "Mañanas",
      "Tardes",
      "Comidas (L-J)",
      "Cenas (L-J)",
      "Comidas (V-D)",
      "Cenas (V-D)",
    ],
    Region: ["Total", "Z1", "Z2", "Z3", "Z4", "Z5", "Z6"],
    Ubicacion: ["Total", "Calle", "CC", "Resto"],
  };

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
      $leverType: String
      $lever: String
    ) {
      graph(
        Graph: "Mi cuota de mercado"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
          LeverType: $leverType
          Lever: $lever
        }
      )
    }
  `;

  const [getData, { loading, data, error }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let leverTypeStr = ``;
    let leverStr = ``;

    getData({
      variables: {
        yr1: semanas[0].year,
        wk1: semanas[0].week,
        yr2: semanas[1].year,
        wk2: semanas[1].week,
        var: 1,
        segment:
          filters.segmentType === 0
            ? filters?.segmentos[filters?.segment]
            : filters?.especialidades[filters?.segment],
        segmentType: filters.segmentType,
        leverType: leverTypeStr || "",
        lever: leverStr || "",
      },
    });
    currentFilters.current = { ...filters };
  }, [filters.segmentType, filters.segment, semanas]);

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let isLoading = loading || data === undefined;
  let isError = !semanas || !!error || medida === "TicketMedio";

  let labels: string[] = [],
    datalabels: string[] = [],
    values: number[][] = [],
    series: any = [];
  //let min = 0, max = 1

  let exportData: any[] = [];

  if (!loading && !error && data) {
    try {
      const myData = data?.graph;

      console.warn("DATAAAA: ", myData);

      if (!myData) throw new Error(errorMsg);
      if (myData?.Error) throw new Error(`${data.Error}`);

      labels = myData.map((d: any) => Object.keys(d)[0]);
      if (labels.length < 3)
        throw new Error(
          `No hay datos suficientes para mostrar el periodo indicado.`
        );

      datalabels = myData[0][labels[0]].map(
        (d: { Descripcion: string }) => d.Descripcion
      );
      // series.push(data.map((dat:any,i:number)=>Object.values(dat).map((d:any)=> [(name: any) => d[i].Descripcion, (data:any)=> d[i].Item[medida][entorno]])))
      //data.map((dat:any,i:number)=> Object.values(dat).map((d:any)=> console.log("valores d: ",d)))
      // TODO para sacar de otra manera los datos de semana para cada cadena
      // // 				data.map((dat:any,i:number)=> {
      // // for(const [key, value] of Object.entries(dat)){
      // //  console.log(key,value)
      // // }
      // // })
      for (let i = 0; i < datalabels.length; i++) {
        let cadenaCheck = "cad_826832";

        values.push(
          myData
            .map((dat: any) =>
              Object.values(dat).map((d: any) => {
                if (
                  filters?.medidas === 2 &&
                  d[i]?.Item[medida] === undefined
                ) {
                  // FIX ERA SIN LO DE FILTERS?MEDIDAS === 2
                  throw new Error(
                    `La opción de Ticket Medio del filtro Medidas no es aplicable a este gráfico`
                  );
                } else {
                  if (filters.leverType === 0) {
                    let object;
                    // Object is that which Description is equal to datalabels[i]
                    if (d[i]?.Descripcion === datalabels[i]) {
                      object = d[i];
                    } else {
                      // Object is that which Description is equal to datalabels[i] but with the key "Totales"
                      object = d.find(
                        (item: any) => item?.Descripcion === datalabels[i]
                      );
                    }

                    return object?.Item[medida][entorno]["Totales"];
                  } else {
                    let leverKey = leverTypeKeys[filters.leverType || 0];
                    let leverSubKey = leverKeys[leverKey][filters.lever || 0];
                    return d[i]?.Item[medida][entorno][leverKey][leverSubKey];
                  }
                }
              })
            )
            .flat()
            .reverse()
        );

        // series.push({
        // 	name: datalabels[i],
        // 	data: data.map((dat:any)=>Object.values(dat).map((d:any)=>d[i].Item[medida][entorno])).flat().reverse()
        // })
      }
      labels.reverse();
      //console.log("series",series)
      //max = roundGraph(Math.max(...values.reduce((a,b)=>[...a,...b])))
      //min = roundGraph(Math.min(...values.reduce((a,b)=>[...a,...b])))

      values.forEach((val, i) => {
        series.push({
          name: datalabels[i],
          data: val,
        });
      });
      //console.log(series)
      currentFilters.current = { ...filters };
      exportData = getExportData(
        myData,
        datalabels,
        labels,
        semanas[0].year === 2019,
        currentFilters.current,
        leverTypeKeys,
        leverKeys
      );
    } catch (err: any) {
      errorMsg = err.message ?? errorMsg;
    }
  }

  //max=max<0?0:max
  //const ticks = useMemo(() => getTicks(min,max),[min,max]);
  const max: number | undefined = useMemo(() => getMax([...values]), [values]);
  const min: number | undefined = 0; //useMemo(() => getMin([...values]),[values]);
  const stepSize = useMemo(() => getSteps(min, max), [min, max]);
  //console.log(max,stepSize)
  let companyColors = datalabels.includes("Mi compañía") ? ["#888888"] : [];

  const graphData = {
    datalabels: datalabels,
    labels: labels,
    values: values,
    //colors: ["#007070", ...companyColors, "#A30041", "#85b709","#363aaf","#9a2d79","#4d947a","#7EA3CF","#DC9E9D"],
    colors: [
      ...companyColors,
      "#A30041",
      "#85b709",
      "#363aaf",
      "#9a2d79",
      "#4d947a",
      "#7EA3CF",
      "#DC9E9D",
    ],
    annotation: [],
    series: series,
    min: 0, //min,
    max: max,
    stepSize: stepSize,
  };

  const emptyData = {
    labels: [],
    datasets: [],
  };

  return (
    <div className="chart-container">
      <Title
        title={title}
        error={!!error}
        files={{ data: exportData, semanas, user, title, leyenda: "Periodo" }}
      />
      {/*error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>*/}
      <div className="chart">
        {!isError && (
          <LineGraph
            data={
              isError ||
              (filters.entorno === Entorno.Comparable && filters.leverType > 0)
                ? emptyData
                : graphData
            }
            hue={320}
            annotation={"#00ff00"}
            percentages
            error={error}
          />
        )}
      </div>
      {filters.entorno === Entorno.Comparable &&
        filters.leverType > 0 &&
        !loading && (
          <GraphNotCompatible>
            Esta gráfica no soporta el entorno comparable para la variable
            seleccionada.
          </GraphNotCompatible>
        )}
      {isLoading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
          <p>Obteniendo datos...</p>
        </div>
      )}
      {isError && <GraphNotCompatible>{errorMsg}</GraphNotCompatible>}
    </div>
  );
};

export default Graph;

const getExportData = (
  data: any,
  datalabels: any[],
  labels: any,
  isLeap: boolean,
  currentFilters: any,
  leverTypeKeys: any,
  leverKeys: any
) => {
  let exportData: any = [];
  let arrayDeCosas: any = [];
  arrayDeCosas.push("Totales");
  if (isLeap === false && currentFilters.leverType === 0) {
    arrayDeCosas.push("Comparables");
  }

  let test: any = Object.values(data[0]);

  // Make an array of all "Descripcion" values in test[0]
  let test2: any = test[0].map((item: any) => item?.Descripcion);

  for (let i = 0; i < test2.length; i++) {
    let tempVals: any = [];
    ["Ventas", "Tickets"].forEach((med: string, m: number) => {
      arrayDeCosas.forEach((ent: string, e: number) => {
        if (currentFilters.leverType === 0) {
          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i]?.Item[med][ent])
            )
            .flat()
            .reverse();

          tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        } else {
          let palanca = leverTypeKeys[currentFilters.leverType];
          let subPalanca = leverKeys[palanca][currentFilters.lever];

          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map(
                (d: any) => d[i]?.Item[med][ent][palanca][subPalanca]
              )
            )
            .flat()
            .reverse();

          tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        }
      });
    });
    exportData.push({ [datalabels[i]]: tempVals });
  }

  // Remove from exportData the ones whose key is not in datalabels
  let exportDataFiltered = exportData.filter((item: any) => {
    let key = Object.keys(item)[0];
    return datalabels.includes(key);
  });

  return exportDataFiltered;
};

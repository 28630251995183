import { useState, useEffect, useRef, useContext } from "react";
//import Line from "react-apexcharts";
//import { ResponsiveLine } from "@nivo/line";

import { Line } from "react-chartjs-2";
import { Chart as Chart2 } from "chart.js";
import locale from "./locale.es";
import { getItemHexColor, randomColor } from "../../utils/functions";
import ChartDataLabels from "chartjs-plugin-datalabels";

//import './style.scss'
Chart2.register(ChartDataLabels);

const graphBorder = "#303437";
const graphBorderLight = "#7a8085";

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  hue: number;
  annotation: string;
  percentages?: boolean;
};

const LineGraph = ({
  data,
  hue,
  annotation,
  percentages,
  error,
  showLegend,
}: any) => {
  let statusRef = useRef<any>([]);

  const graphRef = useRef<any>(null);

  useEffect(() => {
    //@ts-ignore -- Limitación de apexcharts?
    if (graphRef.current && graphRef.current.props.series.length > 5) {
      data.series.forEach(
        (serie: { name: string; data: number[] }, i: number) => {
          if (i > 5) graphRef?.current?.chart.hideSeries(serie.name);
        }
      );
    }
  }, [data]);

  /* TEST */

  const newLegendClickHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
    ci.update();

    // UpdateStatus
    let status = statusRef.current;
    if (status) {
      status[index].hidden = !status[index].hidden;
    }
  };

  const newLegendHoverHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    setHoveredIndex(index);
    ci.update();
  };

  const newLegendLeaveHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const ci = legend.chart;
    setHoveredIndex(-1);
    ci.update();
  };

  const plugin: any = {
    id: "verticalLiner",
    afterInit: (chart: any, args: any, opts: any) => {
      chart.verticalLiner = {};
    },
    afterEvent: (chart: any, args: any, options: any) => {
      const { inChartArea } = args;
      chart.verticalLiner = { draw: inChartArea };
    },
    beforeTooltipDraw: (chart: any, args: any, options: any) => {
      const { draw } = chart.verticalLiner;
      if (!draw) return;

      const { ctx } = chart;
      const { top, bottom } = chart.chartArea;
      const { tooltip } = args;
      const x = tooltip?.caretX;
      if (!x) return;

      ctx.save();

      ctx.beginPath();
      ctx.moveTo(x, top);
      ctx.lineTo(x, bottom);
      ctx.strokeStyle = "#545455";
      ctx.stroke();

      ctx.restore();
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    tension: 0.4,
    animation: {
      duration: 400,
    },
    interaction: {
      mode: "index" as any,
      intersect: false,
    },
    plugins: {
      verticalLiner: {},
      datalabels: {
        color: showLegend ? "white" : "transparent",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(1);
            }
            return label + "%";
          },
        },
      },
      legend: {
        position: "bottom" as const,
        onClick: newLegendClickHandler,
        onHover: newLegendHoverHandler,
        onLeave: newLegendLeaveHandler,
        labels: {
          color: "rgb(255, 255, 255, 0.9)",
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Mi tendencia",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: true,
          tickColor: "#26292A",
          color: "#26292A",
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(1) + "%"; // convert it to percentage
          },
        },
      },
    },
  };

  /* END TEST */

  let defaultDataSet = {
    labels: [],
    datasets: [],
  };

  let [dataTest, setDataTest] = useState<any>(defaultDataSet);
  let [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  useEffect(() => {
    if (data.labels.length === 0 || data.series.length === 0) return;
    let datasets: any = [];
    let segmentsCount: number = 0;
    let chainsCount: number = 0;
    data.series.forEach(
      (
        serie: { name: string; data: number[]; color: string },
        index: number
      ) => {
        let color: string = serie.color;
        if (serie.name?.toLowerCase().indexOf("mercado") > -1) {
          color = getItemHexColor("market");
        } else if (serie.name?.toLowerCase().indexOf("totalTEST") > -1) {
          color = getItemHexColor("total");
        } else if (serie.name?.toLowerCase().indexOf("compañía") > -1) {
          color = getItemHexColor("company");
        } else if (serie.name?.toLowerCase().indexOf("cad_") > -1) {
          color = getItemHexColor("chain", chainsCount);
          chainsCount += 1;
        } else {
          color = getItemHexColor("segment", segmentsCount);
          segmentsCount += 1;
        }

        //let color = serie.color || rndColor;
        datasets.push({
          label: serie.name,
          data: serie.data,
          borderColor:
            hoveredIndex !== index && hoveredIndex !== -1
              ? "rgba(255,255,255,0.10"
              : color,
          backgroundColor:
            hoveredIndex !== index && hoveredIndex !== -1
              ? "rgba(255,255,255,0.10"
              : color,
          hidden: statusRef?.current[index]?.hidden || false,
        });
      }
    );

    let data3: any = {
      labels: data.labels,
      datasets: datasets,
    };

    if (statusRef.current.length === 0) {
      statusRef.current = datasets;
    }

    setDataTest(data3);
  }, [data.labels, data.series, hoveredIndex]);

  return (
    <div
      className="chart-container"
      style={{ position: "relative", minHeight: "450px" }}
    >
      {dataTest && (
        <Line data={dataTest} options={options} plugins={[plugin]} />
      )}
    </div>
  );
};

export default LineGraph;

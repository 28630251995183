import React from 'react';
import CrudTable from '../../components/CrudTable';
import Title from '../../components/Title';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { Spinner, SpinnerSize } from '@fluentui/react';
import exportFromJSON from 'export-from-json';
import moment from 'moment';
import { IEvento } from '../../AppContext';
//import { normalizeBool } from '../../utils/functions';

const Eventos = () => {
	const addQuery = gql`mutation($semana: Int!, $anyo: Int!, $evento: String, $comentario: String){
		setEvento(
		  input:{
			Semana: $semana
			Anyo: $anyo
			Evento: $evento
			Comentario: $comentario
		  }
		){
			UUID
			Semana
			Anyo
			Evento
			Comentario
			Error
		}
	}`;
	const setQuery = gql`mutation($currId: String!, $semana: Int!, $anyo: Int!, $evento: String, $comentario: String){
		setEvento(
		  input:{
			UUID: $currId
			Semana: $semana
			Anyo: $anyo
			Evento: $evento
			Comentario: $comentario
		  }
		){
			UUID
			Semana
			Anyo
			Evento
			Comentario
			Error
		}
	}`;

	const deleteQuery = gql`mutation($currId: String!){
		deleteEvento(
		  input:{
			UUID: $currId
		  }
		){
			Completed
			Error
		}
	}`;


	const getQuery = gql`query {
		getEvento{
			UUID
			Semana
			Anyo
			Evento
			Comentario
		}
	}`;

	const { data, refetch } = useQuery(getQuery);

	const source = { 
		datafields: [
			{ name: 'UUID', type: 'string', readonly: true },
			{ name: 'Semana', type: 'number' },
			{ name: 'Anyo', type: 'number' },
			{ name: 'Evento', type: 'string' },
			{ name: 'Comentario', type: 'string' }
		],
		datatype: 'json',
		root: '',
		id:'UUID',
		sortcolumn: 'Anyo',
		localdata: data
	}
	const columns = [
		{ text: 'Semana', datafield: 'Semana', width: 60},
		{ text: 'Año', datafield: 'Anyo', width: 50},
		{ text: 'Evento', datafield: 'Evento'},
		{ text: 'Comentario', datafield: 'Comentario'}
	]

	const [getData, { loading }] = useLazyQuery(getQuery, {
		fetchPolicy: "no-cache",
		onCompleted: fetchedData => {

			const data:any[] = []
			fetchedData.getEvento.forEach((evento:IEvento)=>{
				let e = {
					"Semana": evento.Semana,
					"Año": evento.Anyo,
					"Evento": evento.Evento,
					"Comentario": evento.Comentario
				}
				data.push(e)
			})
			exportFromJSON({
				data: data,
				exportType: exportFromJSON.types.xls,
				fileName: `${moment().format('YYYY-MM-DD hh-mm-ss')} - Eventos`,
			})
		}
	});
	
	return(
		<>
			<Title title={"Eventos y comentarios"}/>
			{loading && <div style={{position: "absolute", zIndex: 9, right: 180, top: 128}}><Spinner size={SpinnerSize.medium} /></div>}
			<CrudTable
				label={"Evento"}
				itemName={"Evento"}
				items={data}
				source={source}
				columns={columns}
				addQuery={addQuery}
				setQuery={setQuery}
				deleteQuery={deleteQuery}
				refetch={refetch}
				exportFunc={getData}
			/>
		</>
	)
}

export default Eventos;
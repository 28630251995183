import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as Logo } from "./img/logo.svg";
import { ReactComponent as Title } from "./img/title.svg";
import { DefaultButton, FontIcon, IIconProps } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Modal from "../../components/Modal";
import ExcelLoader from "../../components/ExcelLoader";
import AppContext from "../../AppContext";
import { InformesComparados, MisInformes, Comparadores } from "../Sections";

let novedades = {
  compared: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  mine: [1, 1],
  comparator: [1],
};

const List = ({
  title,
  isCollapsed = false,
  group,
  setCurrPos,
  children,
  hasNews,
}: {
  title: string;
  isCollapsed?: boolean;
  group: number;
  setCurrPos: Function;
  children?: React.ReactNode;
  hasNews?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed ?? false);
  //const { setSection } = useContext(AppContext);

  const handleChangeList = () => {
    setCollapsed(!collapsed);
    /*
      // setSection(group);
      // setCurrPos(1);
      //setCurrPos(0);
      //window.scrollTo({ top: 0, behavior: "smooth" });
    }*/
  };
  return (
    <div
      className={`navlist ${title.replaceAll(" ", "").toLowerCase()}${
        collapsed ? " collapsed" : ""
      }`}
    >
      <div className="main-option">
        <label onClick={handleChangeList}>{title}</label>
        <FontIcon iconName="CaretSolidDown" className="navlist-caret" />
        {hasNews && (
          <span className="is-new" title="Esta sección contiene novedades">
            &#9733;
          </span>
        )}
      </div>
      <ol>{children}</ol>
    </div>
  );
};

let type: any = "smooth";

const ListItem = ({
  i,
  s,
  text,
  active,
  disabled,
  onClick,
  hasNews,
}: {
  i: number;
  s: number;
  text: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: Function;
  hasNews?: boolean;
}) => {
  const { section, setSection, setSectionItem } = useContext(AppContext);
  let isActive = i !== section ? false : s === -1 ? true : active;

  const handleMenuSelection = (e: any) => {
    isActive = i !== section ? false : s === -1 ? true : active;
    if (!!onClick) {
      onClick();
    } else if (!disabled) {
      if (i !== section) {
        setSection(i);
        type = "auto";
      } else {
        type = "smooth";
      }
      setSectionItem(s);
    }
  };

  return (
    <li
      onClick={handleMenuSelection}
      data-i={i}
      data-s={s}
      className={`navlist-item${disabled ? " disabled" : ""}${
        isActive ? " active" : ""
      }`}
    >
      {text}
      {hasNews && (
        <span className="is-new" title="Esta sección contiene novedades">
          &#9733;
        </span>
      )}
    </li>
  );
};

const CloudUpload: IIconProps = { iconName: "CloudUpload" };

const Header = ({
  setLocked,
  auth,
  filtersOpen,
  setFiltersOpen,
}: {
  setLocked: Function;
  auth: {
    Token: string;
    canRead: boolean;
    canWrite: boolean;
    canDownload: boolean;
    isAdmin: boolean;
  };
  filtersOpen: boolean;
  setFiltersOpen: Function;
}) => {
  let { section, userName } = useContext(AppContext);
  const [isMenuOpen, { toggle: toggleMenu, setFalse: closeMenu }] =
    useBoolean(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const handleHide = () => {
    setLocked(false);
    hideModal();
  };
  const handleShow = () => {
    setLocked(true);
    showModal();
  };
  const handleMenu = () => {
    toggleMenu();
  };

  const { sectionItem } = useContext(AppContext);

  useEffect(() => {
    document
      .querySelector(`#sec${section}${sectionItem}`)
      ?.scrollIntoView({ behavior: type || "auto" });
  }, [section, sectionItem]);

  // Código duplicado en Filters por cuestiones de rendimiento:
  const [currPos, setCurrPos] = useState(0);
  useEffect(() => {
    const comparePositions = () => {
      let selected = -1;
      if (section === 0) {
        for (let i in InformesComparados) {
          let obj = document.querySelector(
            `#sec${InformesComparados[i].group}${i}`
          );
          if (obj) {
            let shownRatio =
              obj.getBoundingClientRect().top / window.innerHeight;
            if (shownRatio < -0.1 || shownRatio > 0.5) continue;
            selected = parseInt(i);
          }
          break;
        }
        if (selected !== -1) setCurrPos(selected);
      }

      if (section === 1) {
        for (let i in MisInformes) {
          let obj = document.querySelector(`#sec${MisInformes[i].group}${i}`);
          if (obj) {
            let shownRatio =
              obj.getBoundingClientRect().top / window.innerHeight;
            if (shownRatio < -0.1 || shownRatio > 0.5) continue;
            selected = parseInt(i);
          }
          break;
        }
        if (selected !== -1) setCurrPos(selected);
      }

      if (section === 2) {
        for (let i in Comparadores) {
          let obj = document.querySelector(`#sec${Comparadores[i].group}${i}`);
          if (obj) {
            let shownRatio =
              obj.getBoundingClientRect().top / window.innerHeight;
            if (shownRatio < -0.1 || shownRatio > 0.5) continue;
            selected = parseInt(i);
          }
          break;
        }
        if (selected !== -1) setCurrPos(selected);
      }

      if (section === 3) {
        let obj = document.querySelector(`#sec${3}${0}`);
        if (obj) {
          selected = 0;
        }
      }
      if (selected !== -1) setCurrPos(selected);
    };

    //var prevPosition = 0;
    var ticking = false;
    window.addEventListener("scroll", function (e) {
      //prevPosition = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function () {
          comparePositions();
          ticking = false;
        });
      }
      ticking = true;
    });
  });

  function _logout(): void {
    window.localStorage.removeItem("auth-token");
    window.location.reload();
  }

  const handleMenuToggle = () => {
    if (filtersOpen) setFiltersOpen(false);
    if (isMenuOpen) closeMenu();
  };

  return (
    <header
      className={`dark${isMenuOpen ? " navopen" : " navclosed"}${
        filtersOpen ? " filteropen" : " filterclosed"
      }`}
      onClick={handleMenuToggle}
    >
      <div className="header-menu">
        <button className="show-menu" onClick={handleMenu}>
          <FontIcon iconName="CollapseMenu" />
        </button>
        <div id="header-img">
          <div className="logo">
            <Logo />
          </div>
          <div className="title">
            <Title />
          </div>
        </div>
      </div>

      <nav>
        <div className="user-tag">
          <span>Usuario:</span>
          <p>{userName}</p>
        </div>
        {auth.canRead && (
          <>
            <List
              title={"Informes comparados"}
              isCollapsed={false}
              group={InformesComparados[0].group}
              setCurrPos={setCurrPos}
            >
              {InformesComparados.map((section, i) => {
                return (
                  <ListItem
                    key={i}
                    i={section.group}
                    s={i}
                    text={section.title}
                    active={currPos === i}
                  />
                );
              })}
            </List>

            <List
              title={"Mis informes"}
              isCollapsed={false}
              group={MisInformes[0].group}
              setCurrPos={setCurrPos}
              hasNews={true}
            >
              {MisInformes.map((section, i) => {
                return (
                  <ListItem
                    key={i}
                    i={section.group}
                    s={i}
                    text={section.title}
                    active={currPos === i}
                  />
                );
              })}
            </List>

            <List
              title={"Comparadores"}
              isCollapsed={false}
              group={Comparadores[0].group}
              setCurrPos={setCurrPos}
              hasNews={true}
            >
              {Comparadores.map((section, i) => {
                return (
                  <ListItem
                    key={i}
                    i={section.group}
                    s={i}
                    text={section.title}
                    active={currPos === i}
                  />
                );
              })}
            </List>
          </>
        )}
        <List title={"Otros"} group={3} setCurrPos={setCurrPos}>
          {/*<ListItem key={10} i={1} s={-1} text={"Calendario"}/>*/}
          {auth.canRead && auth.canDownload && (
            <ListItem
              key={14}
              i={3}
              s={0}
              text={"Descarga masiva"}
              hasNews={true}
            />
          )}
          <ListItem
            onClick={_logout}
            key={15}
            i={3}
            s={1}
            text={"Cerrar sesión"}
          />
        </List>

        {auth.canRead && auth.canWrite && (
          <div id="loadDataBtn">
            <DefaultButton
              text="Cargar datos semanales"
              iconProps={CloudUpload}
              onClick={handleShow}
              allowDisabledFocus
            />
          </div>
        )}
      </nav>

      <Modal
        title="Carga de datos semanales de ventas"
        isOpen={isModalOpen}
        hideModal={handleHide}
        showModal={handleShow}
        darkMode
      >
        <ExcelLoader darkMode />
      </Modal>
    </header>
  );
};

export default Header;

import React, { useContext, useEffect, useState } from "react";
import CrudTable from "../../components/CrudTable";
import Title from "../../components/Title";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import {
  IDropdownStyles,
  IDropdownOption,
  Dropdown,
  Stack,
  IStackTokens,
  Spinner,
  SpinnerSize,
  ICommandBarItemProps,
  CommandBar,
  Checkbox,
} from "@fluentui/react";
import AppContext, { IVenta } from "../../AppContext";
import { normalize } from "../../utils/functions";
import exportFromJSON from "export-from-json";
import moment from "moment";
import "./style.scss";

const Ventas = () => {
  const { semanasDatos } = useContext(AppContext);

  const [selectedWeek, setSelectedWeek] = useState<IDropdownOption | null>({
    key: semanasDatos[1].week,
    text: `${semanasDatos[1].week - 1}`,
  });
  const [selectedYear, setSelectedYear] = useState<IDropdownOption | null>({
    key: semanasDatos[1].year,
    text: `${semanasDatos[1].year}`,
  });
  const [selectedCadena, setSelectedCadena] = useState<IDropdownOption | null>(
    null
  );

  const addQuery = gql`
    mutation ($nombre: String!) {
      setVenta(input: { UUID: $currId }) {
        UUID
        Nombre
        Error
      }
    }
  `;
  const setQuery = gql`
    mutation ($currId: String!, $nombre: String!) {
      setVenta(input: { UUID: $currId }) {
        UUID
        Nombre
        Error
      }
    }
  `;
  const deleteQuery = gql`
    mutation ($currId: String!) {
      deleteVenta(input: { UUID: $currId }) {
        Completed
        Error
      }
    }
  `;

  const CADENAS = gql`
    query {
      getCadena {
        Nombre
        hasVentas
      }
    }
  `;

  const VENTAS_QRY = gql`
    query ($year: Int, $wk1: Int, $wk2: Int) {
      getVenta(
        WeekRange: {
          Start: { Year: $year, Week: $wk1 }
          End: { Year: $year, Week: $wk2 }
        }
        ShowAll: true
      ) {
        UUID
        Cadena {
          Nombre
          Segmento {
            Descripcion
          }
        }
        Semana
        Anyo
        LastYear {
          VentasComparables
          TicketsComparables
          LocalesComparables
          LocalesRepComparables
        }
        Actual {
          Ventas {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Tickets {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Locales {
            Aperturas
            Cierres
            Bruto {
              Total
              Reportan
              Self
              Delivery
            }
            Comparable {
              Total
              Reportan
            }
          }
        }
        Anterior {
          Ventas {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Tickets {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Locales {
            Aperturas
            Cierres
            Bruto {
              Total
              Reportan
              Self
              Delivery
            }
            Comparable {
              Total
              Reportan
            }
          }
        }
        Modelizacion
        Ratio
      }
    }
  `;

  const VENTAS_QRY_CAD = gql`
    query ($cad: String!) {
      getVenta(Cadena: { Nombre: $cad }, ShowAll: true) {
        UUID
        Cadena {
          Nombre
          Segmento {
            Descripcion
          }
        }
        Semana
        Anyo
        LastYear {
          VentasComparables
          TicketsComparables
          LocalesComparables
          LocalesRepComparables
        }
        Actual {
          Ventas {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Tickets {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Locales {
            Aperturas
            Cierres
            Bruto {
              Total
              Reportan
              Self
              Delivery
            }
            Comparable {
              Total
              Reportan
            }
          }
        }
        Anterior {
          Ventas {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Tickets {
            Bruto {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
            Comparable {
              Totales
              Canal {
                Delivery
                TakeAway
                Local
              }
              Ubicacion {
                Calle
                CC
                Concesiones
                Otros
              }
              Region {
                Z1
                Z2
                Z3
                Z4
                Z5
                Z6
              }
              Momento {
                T1
                T2
                T3
                T4
                T5
                T6
              }
            }
          }
          Locales {
            Aperturas
            Cierres
            Bruto {
              Total
              Reportan
              Self
              Delivery
            }
            Comparable {
              Total
              Reportan
            }
          }
        }
        Modelizacion
        Ratio
      }
    }
  `;

  let {
    data: ventasData,
    refetch,
    loading: ventasLoading,
  } = useQuery(selectedCadena ? VENTAS_QRY_CAD : VENTAS_QRY, {
    variables: selectedCadena
      ? {
          cad: selectedCadena.key,
        }
      : {
          year: selectedYear?.key,
          wk1: selectedWeek?.key,
          wk2: selectedWeek?.key,
        },
  });

  const [modifiedVentasData, setMVentasData] = useState<any>([]);

  useEffect(() => {
    if (ventasData === undefined) return;

    let ventasData2 = ventasData.getVenta.map((i: any) => {
      return {
        ...i,
        Semana: i.Semana - 1,
      };
    });

    let ventasData3 = ventasData2.filter((i: any) => i.Semana !== 0);

    setMVentasData(ventasData3);
  }, [ventasData]);

  const normalizeStr = [
    "No definido",
    "No modelizado",
    "Suma de partes",
    "Total",
  ];

  const source = {
    datafields: [
      { name: "UUID", type: "string", readonly: true },
      { name: "Semana", type: "number" },
      { name: "Anyo", type: "number" },
      { name: "Cadena", map: "Cadena>Nombre", type: "string" },
      { name: "Segmento", map: "Cadena>Segmento", type: "array" },
      { name: "Ratio", type: "number" },
      { name: "Modelizacion", type: "number" },

      {
        name: "VentasBrutoTotales",
        map: "Actual>Ventas>Bruto>Totales",
        type: "number",
      },
      {
        name: "VentasBrutoCanalDelivery",
        map: "Actual>Ventas>Bruto>Canal>Delivery",
        type: "number",
      },
      {
        name: "VentasBrutoCanalTakeAway",
        map: "Actual>Ventas>Bruto>Canal>TakeAway",
        type: "number",
      },
      {
        name: "VentasBrutoCanalLocal",
        map: "Actual>Ventas>Bruto>Canal>Local",
        type: "number",
      },
      {
        name: "VentasBrutoUbicacionCalle",
        map: "Actual>Ventas>Bruto>Ubicacion>Calle",
        type: "number",
      },
      {
        name: "VentasBrutoUbicacionCC",
        map: "Actual>Ventas>Bruto>Ubicacion>CC",
        type: "number",
      },
      {
        name: "VentasBrutoUbicacionConcesiones",
        map: "Actual>Ventas>Bruto>Ubicacion>Concesiones",
        type: "number",
      },
      {
        name: "VentasBrutoUbicacionOtros",
        map: "Actual>Ventas>Bruto>Ubicacion>Otros",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ1",
        map: "Actual>Ventas>Bruto>Region>Z1",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ2",
        map: "Actual>Ventas>Bruto>Region>Z2",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ3",
        map: "Actual>Ventas>Bruto>Region>Z3",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ4",
        map: "Actual>Ventas>Bruto>Region>Z4",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ5",
        map: "Actual>Ventas>Bruto>Region>Z5",
        type: "number",
      },
      {
        name: "VentasBrutoRegionZ6",
        map: "Actual>Ventas>Bruto>Region>Z6",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT1",
        map: "Actual>Ventas>Bruto>Momento>T1",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT2",
        map: "Actual>Ventas>Bruto>Momento>T2",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT3",
        map: "Actual>Ventas>Bruto>Momento>T3",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT4",
        map: "Actual>Ventas>Bruto>Momento>T4",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT5",
        map: "Actual>Ventas>Bruto>Momento>T5",
        type: "number",
      },
      {
        name: "VentasBrutoMomentoT6",
        map: "Actual>Ventas>Bruto>Momento>T6",
        type: "number",
      },
      {
        name: "VentasComparableTotales",
        map: "Actual>Ventas>Comparable>Totales",
        type: "number",
      },
      {
        name: "VentasComparableCanalDelivery",
        map: "Actual>Ventas>Comparable>Canal>>Delivery",
        type: "number",
      },
      {
        name: "VentasComparableCanalTakeAway",
        map: "Actual>Ventas>Comparable>Canal>TakeAway",
        type: "number",
      },
      {
        name: "VentasComparableCanalLocal",
        map: "Actual>Ventas>Comparable>Canal>Local",
        type: "number",
      },
      {
        name: "VentasComparableUbicacionCalle",
        map: "Actual>Ventas>Comparable>Ubicacion>Calle",
        type: "number",
      },
      {
        name: "VentasComparableUbicacionCC",
        map: "Actual>Ventas>Comparable>Ubicacion>CC",
        type: "number",
      },
      {
        name: "VentasComparableUbicacionConcesiones",
        map: "Actual>Ventas>Comparable>Ubicacion>Concesiones",
        type: "number",
      },
      {
        name: "VentasComparableUbicacionOtros",
        map: "Actual>Ventas>Comparable>Ubicacion>Otros",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ1",
        map: "Actual>Ventas>Comparable>Region>Z1",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ2",
        map: "Actual>Ventas>Comparable>Region>Z2",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ3",
        map: "Actual>Ventas>Comparable>Region>Z3",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ4",
        map: "Actual>Ventas>Comparable>Region>Z4",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ5",
        map: "Actual>Ventas>Comparable>Region>Z5",
        type: "number",
      },
      {
        name: "VentasComparableRegionZ6",
        map: "Actual>Ventas>Comparable>Region>Z6",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT1",
        map: "Actual>Ventas>Comparable>Momento>T1",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT2",
        map: "Actual>Ventas>Comparable>Momento>T2",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT3",
        map: "Actual>Ventas>Comparable>Momento>T3",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT4",
        map: "Actual>Ventas>Comparable>Momento>T4",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT5",
        map: "Actual>Ventas>Comparable>Momento>T5",
        type: "number",
      },
      {
        name: "VentasComparableMomentoT6",
        map: "Actual>Ventas>Comparable>Momento>T6",
        type: "number",
      },
      {
        name: "TicketsBrutoTotales",
        map: "Actual>TicketsBruto>>Totales",
        type: "number",
      },
      {
        name: "TicketsBrutoCanalDelivery",
        map: "Actual>TicketsBruto>Canal>>Delivery",
        type: "number",
      },
      {
        name: "TicketsBrutoCanalTakeAway",
        map: "Actual>TicketsBruto>Canal>TakeAway",
        type: "number",
      },
      {
        name: "TicketsBrutoCanalLocal",
        map: "Actual>TicketsBruto>Canal>Local",
        type: "number",
      },
      {
        name: "TicketsBrutoUbicacionCalle",
        map: "Actual>TicketsBruto>Ubicacion>Calle",
        type: "number",
      },
      {
        name: "TicketsBrutoUbicacionCC",
        map: "Actual>TicketsBruto>Ubicacion>CC",
        type: "number",
      },
      {
        name: "TicketsBrutoUbicacionConcesiones",
        map: "Actual>TicketsBruto>Ubicacion>Concesiones",
        type: "number",
      },
      {
        name: "TicketsBrutoUbicacionOtros",
        map: "Actual>TicketsBruto>Ubicacion>Otros",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ1",
        map: "Actual>TicketsBruto>Region>Z1",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ2",
        map: "Actual>TicketsBruto>Region>Z2",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ3",
        map: "Actual>TicketsBruto>Region>Z3",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ4",
        map: "Actual>TicketsBruto>Region>Z4",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ5",
        map: "Actual>TicketsBruto>Region>Z5",
        type: "number",
      },
      {
        name: "TicketsBrutoRegionZ6",
        map: "Actual>TicketsBruto>Region>Z6",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT1",
        map: "Actual>TicketsBruto>Momento>T1",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT2",
        map: "Actual>TicketsBruto>Momento>T2",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT3",
        map: "Actual>TicketsBruto>Momento>T3",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT4",
        map: "Actual>TicketsBruto>Momento>T4",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT5",
        map: "Actual>TicketsBruto>Momento>T5",
        type: "number",
      },
      {
        name: "TicketsBrutoMomentoT6",
        map: "Actual>TicketsBruto>Momento>T6",
        type: "number",
      },
      {
        name: "TicketsComparableTotales",
        map: "Actual>TicketsComparable>Totales",
        type: "number",
      },
      {
        name: "TicketsComparableCanalDelivery",
        map: "Actual>TicketsComparable>Canal>>Delivery",
        type: "number",
      },
      {
        name: "TicketsComparableCanalTakeAway",
        map: "Actual>TicketsComparable>Canal>TakeAway",
        type: "number",
      },
      {
        name: "TicketsComparableCanalLocal",
        map: "Actual>TicketsComparable>Canal>Local",
        type: "number",
      },
      {
        name: "TicketsComparableUbicacionCalle",
        map: "Actual>TicketsComparable>Ubicacion>Calle",
        type: "number",
      },
      {
        name: "TicketsComparableUbicacionCC",
        map: "Actual>TicketsComparable>Ubicacion>CC",
        type: "number",
      },
      {
        name: "TicketsComparableUbicacionConcesiones",
        map: "Actual>TicketsComparable>Ubicacion>Concesiones",
        type: "number",
      },
      {
        name: "TicketsComparableUbicacionOtros",
        map: "Actual>TicketsComparable>Ubicacion>Otros",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ1",
        map: "Actual>TicketsComparable>Region>Z1",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ2",
        map: "Actual>TicketsComparable>Region>Z2",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ3",
        map: "Actual>TicketsComparable>Region>Z3",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ4",
        map: "Actual>TicketsComparable>Region>Z4",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ5",
        map: "Actual>TicketsComparable>Region>Z5",
        type: "number",
      },
      {
        name: "TicketsComparableRegionZ6",
        map: "Actual>TicketsComparable>Region>Z6",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT1",
        map: "Actual>TicketsComparable>Momento>T1",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT2",
        map: "Actual>TicketsComparable>Momento>T2",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT3",
        map: "Actual>TicketsComparable>Momento>T3",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT4",
        map: "Actual>TicketsComparable>Momento>T4",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT5",
        map: "Actual>TicketsComparable>Momento>T5",
        type: "number",
      },
      {
        name: "TicketsComparableMomentoT6",
        map: "Actual>TicketsComparable>Momento>T6",
        type: "number",
      },
      {
        name: "LocalesAperturas",
        map: "Actual>Locales>Aperturas",
        type: "number",
      },
      { name: "LocalesCierres", map: "Actual>Locales>Cierres", type: "number" },
      {
        name: "LocalesBrutoTotal",
        map: "Actual>Locales>Bruto>Total",
        type: "number",
      },
      {
        name: "LocalesBrutoReportan",
        map: "Actual>Locales>Bruto>Reportan",
        type: "number",
      },
      {
        name: "LocalesBrutoSelf",
        map: "Actual>Locales>Bruto>Self",
        type: "number",
      },
      {
        name: "LocalesBrutoDelivery",
        map: "Actual>Locales>Bruto>Delivery",
        type: "number",
      },
      {
        name: "LocalesComparableTotal",
        map: "Actual>Locales>Comparable>Total",
        type: "number",
      },
      {
        name: "LocalesComparableReportan",
        map: "Actual>Locales>Comparable>Reportan",
        type: "number",
      },
    ],
    datatype: "json",
    root: "",
    id: "UUID",
    sortcolumn: "Cadena",
    localdata: modifiedVentasData,
  };

  const [showDataColumns, setShowDataColumns] = useState(false);

  const dataColumns = !showDataColumns
    ? []
    : [
        {
          text: "VentasBrutoTotales",
          datafield: "VentasBrutoTotales",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoCanalDelivery",
          datafield: "VentasBrutoCanalDelivery",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoCanalTakeAway",
          datafield: "VentasBrutoCanalTakeAway",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoCanalLocal",
          datafield: "VentasBrutoCanalLocal",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoUbicacionCalle",
          datafield: "VentasBrutoUbicacionCalle",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoUbicacionCC",
          datafield: "VentasBrutoUbicacionCC",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoUbicacionConcesiones",
          datafield: "VentasBrutoUbicacionConcesiones",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoUbicacionOtros",
          datafield: "VentasBrutoUbicacionOtros",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ1",
          datafield: "VentasBrutoRegionZ1",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ2",
          datafield: "VentasBrutoRegionZ2",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ3",
          datafield: "VentasBrutoRegionZ3",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ4",
          datafield: "VentasBrutoRegionZ4",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ5",
          datafield: "VentasBrutoRegionZ5",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoRegionZ6",
          datafield: "VentasBrutoRegionZ6",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT1",
          datafield: "VentasBrutoMomentoT1",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT2",
          datafield: "VentasBrutoMomentoT2",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT3",
          datafield: "VentasBrutoMomentoT3",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT4",
          datafield: "VentasBrutoMomentoT4",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT5",
          datafield: "VentasBrutoMomentoT5",
          type: "number",
          width: 120,
        },
        {
          text: "VentasBrutoMomentoT6",
          datafield: "VentasBrutoMomentoT6",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableTotales",
          datafield: "VentasComparableTotales",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableCanalDelivery",
          datafield: "VentasComparableCanalDelivery",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableCanalTakeAway",
          datafield: "VentasComparableCanalTakeAway",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableCanalLocal",
          datafield: "VentasComparableCanalLocal",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableUbicacionCalle",
          datafield: "VentasComparableUbicacionCalle",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableUbicacionCC",
          datafield: "VentasComparableUbicacionCC",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableUbicacionConcesiones",
          datafield: "VentasComparableUbicacionConcesiones",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableUbicacionOtros",
          datafield: "VentasComparableUbicacionOtros",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ1",
          datafield: "VentasComparableRegionZ1",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ2",
          datafield: "VentasComparableRegionZ2",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ3",
          datafield: "VentasComparableRegionZ3",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ4",
          datafield: "VentasComparableRegionZ4",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ5",
          datafield: "VentasComparableRegionZ5",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableRegionZ6",
          datafield: "VentasComparableRegionZ6",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT1",
          datafield: "VentasComparableMomentoT1",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT2",
          datafield: "VentasComparableMomentoT2",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT3",
          datafield: "VentasComparableMomentoT3",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT4",
          datafield: "VentasComparableMomentoT4",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT5",
          datafield: "VentasComparableMomentoT5",
          type: "number",
          width: 120,
        },
        {
          text: "VentasComparableMomentoT6",
          datafield: "VentasComparableMomentoT6",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoTotales",
          datafield: "TicketsBrutoTotales",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoCanalDelivery",
          datafield: "TicketsBrutoCanalDelivery",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoCanalTakeAway",
          datafield: "TicketsBrutoCanalTakeAway",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoCanalLocal",
          datafield: "TicketsBrutoCanalLocal",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoUbicacionCalle",
          datafield: "TicketsBrutoUbicacionCalle",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoUbicacionCC",
          datafield: "TicketsBrutoUbicacionCC",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoUbicacionConcesiones",
          datafield: "TicketsBrutoUbicacionConcesiones",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoUbicacionOtros",
          datafield: "TicketsBrutoUbicacionOtros",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ1",
          datafield: "TicketsBrutoRegionZ1",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ2",
          datafield: "TicketsBrutoRegionZ2",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ3",
          datafield: "TicketsBrutoRegionZ3",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ4",
          datafield: "TicketsBrutoRegionZ4",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ5",
          datafield: "TicketsBrutoRegionZ5",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoRegionZ6",
          datafield: "TicketsBrutoRegionZ6",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT1",
          datafield: "TicketsBrutoMomentoT1",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT2",
          datafield: "TicketsBrutoMomentoT2",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT3",
          datafield: "TicketsBrutoMomentoT3",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT4",
          datafield: "TicketsBrutoMomentoT4",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT5",
          datafield: "TicketsBrutoMomentoT5",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsBrutoMomentoT6",
          datafield: "TicketsBrutoMomentoT6",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableTotales",
          datafield: "TicketsComparableTotales",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableCanalDelivery",
          datafield: "TicketsComparableCanalDelivery",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableCanalTakeAway",
          datafield: "TicketsComparableCanalTakeAway",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableCanalLocal",
          datafield: "TicketsComparableCanalLocal",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableUbicacionCalle",
          datafield: "TicketsComparableUbicacionCalle",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableUbicacionCC",
          datafield: "TicketsComparableUbicacionCC",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableUbicacionConcesiones",
          datafield: "TicketsComparableUbicacionConcesiones",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableUbicacionOtros",
          datafield: "TicketsComparableUbicacionOtros",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ1",
          datafield: "TicketsComparableRegionZ1",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ2",
          datafield: "TicketsComparableRegionZ2",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ3",
          datafield: "TicketsComparableRegionZ3",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ4",
          datafield: "TicketsComparableRegionZ4",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ5",
          datafield: "TicketsComparableRegionZ5",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableRegionZ6",
          datafield: "TicketsComparableRegionZ6",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT1",
          datafield: "TicketsComparableMomentoT1",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT2",
          datafield: "TicketsComparableMomentoT2",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT3",
          datafield: "TicketsComparableMomentoT3",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT4",
          datafield: "TicketsComparableMomentoT4",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT5",
          datafield: "TicketsComparableMomentoT5",
          type: "number",
          width: 120,
        },
        {
          text: "TicketsComparableMomentoT6",
          datafield: "TicketsComparableMomentoT6",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesAperturas",
          datafield: "LocalesAperturas",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesCierres",
          datafield: "LocalesCierres",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesBrutoTotal",
          datafield: "LocalesBrutoTotal",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesBrutoReportan",
          datafield: "LocalesBrutoReportan",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesBrutoSelf",
          datafield: "LocalesBrutoSelf",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesBrutoDelivery",
          datafield: "LocalesBrutoDelivery",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesComparableTotal",
          datafield: "LocalesComparableTotal",
          type: "number",
          width: 120,
        },
        {
          text: "LocalesComparableReportan",
          datafield: "LocalesComparableReportan",
          type: "number",
          width: 120,
        },
      ];

  const columns = [
    { text: "Cadena", datafield: "Cadena", width: 120 },
    {
      text: "Segmento",
      datafield: "Segmento",
      width: 120,
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalize(val[0]?.Descripcion);
      },
    },
    { text: "Semana", datafield: "Semana", width: 110 },
    { text: "Año", datafield: "Anyo", width: 80 },
    { text: "Ratio", datafield: "Ratio", width: 80 },
    {
      text: "Modelización",
      datafield: "Modelizacion",
      exportable: false,
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalize(normalizeStr[val + 1]);
      },
    },
    ...dataColumns,
  ];

  const dropdownStyles: Partial<IDropdownStyles> = {
    root: { display: "flex", alignSelf: "center", marginLeft: "6px" },
    label: {
      color: "var(--primary-color)",
      fontWeight: 400,
      padding: "7px 0px",
      fontSize: "13px",
      margin: "0px 6px 0px 0px",
    },
    title: {
      borderColor: "transparent",
      transition: "background 0.3s",
    },
    dropdown: {
      selectors: {
        "&:hover .ms-Dropdown-title, &:active .ms-Dropdown-title": {
          borderColor: "transparent",
          background: "#f3f2f1",
        },
        ":focus::after": {
          border: "1px solid transparent",
        },
      },
    },
    callout: {
      minWidth: 130,
    },
  };
  const stackTokens: IStackTokens = { childrenGap: 10 };

  let weekOpts: IDropdownOption[] = [];
  let yearOpts: IDropdownOption[] = [];
  //let cadsOpts:IDropdownOption[] = []

  const [cadsOpts, setCadsOpts] = useState<IDropdownOption[]>([]);

  useQuery(CADENAS, {
    onCompleted: (qry) => {
      if (qry.getCadena) {
        let cads: IDropdownOption[] = [];
        let tmpCadenas = JSON.parse(JSON.stringify(qry.getCadena)).sort(
          (a: any, b: any) => a.Nombre.localeCompare(b.Nombre)
        );
        tmpCadenas.forEach((cad: any) => {
          cads.push({
            key: cad.Nombre,
            text: `${cad.Nombre}${cad.hasVentas ? "" : "*"}`,
          });
        });
        setCadsOpts(cads);
      }
    },
  });

  const WEEK_QUERY = gql`
    query ($year: Int!) {
      getWeekVisibility(Anyo: $year)
    }
  `;

  let qry = useQuery(WEEK_QUERY, {
    variables: {
      year: parseInt(selectedYear?.text ?? "2019"),
    },
  });

  useEffect(() => {
    if (!!qry.data?.getWeekVisibility) {
      let maxWeek = Math.max(
        ...qry.data?.getWeekVisibility.map((wk: any) => wk.Semana)
      );
      for (let week = semanasDatos[0].week; week <= maxWeek; week++) {
        // FIX ERA, vamos a evitar que semana 1 entre
        if (week !== 1) {
          weekOpts.push({ key: week, text: `${week - 1}` }); // FIX ERA WEEK 1
        }
      }
    }
  }, [yearOpts, semanasDatos, qry.data?.getWeekVisibility, weekOpts]);

  yearOpts = [
    { key: 2019, text: "2019" },
    { key: 2020, text: "2020" },
    { key: 2021, text: "2021" },
    { key: 2022, text: "2022" },
    { key: 2023, text: "2023" },
    { key: 2024, text: "2024" },
    { key: 2024, text: "2025" },
  ];
  /*
	useEffect(()=>{
		if(qry.data?.getWeekVisibility){
			let weekArray = qry.data?.getWeekVisibility.map((wk:any)=>wk.Semana)
			weekOpts=[]
			for(let week=Math.min(...weekArray); week<=Math.max(...weekArray); week++){
				weekOpts.push({ key: week, text: `${week}` })
			}
			console.log(weekOpts)
		}

	},[qry.data])
	*/

  const changeWeek = (_: any, item: any) => {
    setSelectedCadena(null);
    setSelectedWeek(item);
  };
  const changeYear = (_: any, item: any) => {
    setSelectedCadena(null);
    setSelectedYear(item);
  };
  const changeCadena = (_: any, item: any) => {
    setSelectedWeek(null);
    setSelectedCadena(item);
  };

  // Change in every item of ventasData the value of semana to semana -1

  const [getData, { loading }] = useLazyQuery(VENTAS_QRY, {
    fetchPolicy: "no-cache",
    onCompleted: (fetchedData) => {
      const data: any[] = [];
      fetchedData.getVenta.forEach((venta: IVenta) => {
        let v = {
          segmento: venta.Cadena.Segmento[0]?.Descripcion ?? "",
          cadena: venta.Cadena.Nombre,
          semana: venta.Semana,
          anyo: venta.Anyo,
          locales_comp: venta.Actual.Locales.Comparable.Total,
          locales_comp_last_year: venta.LastYear?.LocalesComparables ?? 0,
          localesrep_comp: venta.Actual.Locales.Comparable.Reportan,
          localesrep_comp_last_year: venta.LastYear?.LocalesRepComparables ?? 0,
          ventas_comp: venta.Actual.Ventas.Comparable.Totales,
          ventas_comp_last_year: venta.LastYear?.VentasComparables ?? 0,
          //ventas_comp_ap: venta.Anterior.Ventas.Comparables,
          tickets_comp: venta.Actual.Tickets.Comparable.Totales,
          tickets_comp_last_year: venta.LastYear?.TicketsComparables ?? 0,
          //tickets_comp_ap: venta.Anterior.Tickets.Comparables,
          locales_aperturas: venta.Actual.Locales.Aperturas,
          locales_cierres: venta.Actual.Locales.Cierres,
          locales: venta.Actual.Locales.Bruto.Total,
          localesrep: venta.Actual.Locales.Bruto.Reportan,
          ventas: venta.Actual.Ventas.Bruto.Totales,
          //ventas_ap: venta.Anterior.Ventas.Totales,
          tickets: venta.Actual.Tickets.Bruto.Totales,
          //tickets_ap: venta.Anterior.Tickets.Totales,
          del_ventas: venta.Actual.Ventas.Bruto.Canal.Delivery,
          //del_ventas_ap: venta.Anterior.Ventas.Delivery,
          del_tickets: venta.Actual.Tickets.Bruto.Canal.Delivery,
          //del_tickets_ap: venta.Anterior.Tickets.Delivery,
          del_locales: venta.Actual.Locales.Bruto.Delivery,
          //del_locales_ap: venta.LocalesReportanDelivery,
          taw_ventas: venta.Actual.Ventas.Bruto.Canal.TakeAway,
          //taw_ventas_ap: venta.Anterior.Ventas.TakeAway,
          taw_tickets: venta.Actual.Tickets.Bruto.Canal.TakeAway,
          //taw_tickets_ap: venta.Anterior.Tickets.TakeAway,
          local_ventas: venta.Actual.Ventas.Bruto.Canal.Local,
          //local_ventas_ap: venta.Anterior.Ventas.Local,
          local_tickets: venta.Actual.Tickets.Bruto.Canal.Local,
          //local_tickets_ap: venta.Anterior.Tickets.Local,
          calle_ventas: venta.Actual.Ventas.Bruto.Ubicacion.Calle,
          //calle_ventas_ap: venta.Anterior.Ventas.Calle,
          calle_tickets: venta.Actual.Tickets.Bruto.Ubicacion.Calle,
          //calle_tickets_ap: venta.Anterior.Tickets.Calle,
          cc_ventas: venta.Actual.Ventas.Bruto.Ubicacion.CC,
          //cc_ventas_ap: venta.Anterior.Ventas.CC,
          cc_tickets: venta.Actual.Tickets.Bruto.Ubicacion.CC,
          //cc_tickets_ap: venta.Anterior.Tickets.CC,
          conces_ventas: venta.Actual.Ventas.Bruto.Ubicacion.Concesiones,
          //conces_ventas_ap: venta.Anterior.Ventas.Concesiones,
          conces_tickets: venta.Actual.Tickets.Bruto.Ubicacion.Concesiones,
          //conces_tickets_ap: venta.Anterior.Tickets.Concesiones,
          otros_ventas: venta.Actual.Ventas.Bruto.Ubicacion.Otros,
          //otros_ventas_ap: venta.Anterior.Ventas.Otros,
          otros_tickets: venta.Actual.Tickets.Bruto.Ubicacion.Otros,
          //otros_tickets_ap: venta.Anterior.Tickets.Otros,
          z1_ventas: venta.Actual.Ventas.Bruto.Region.Z1,
          //z1_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z1,
          z1_tickets: venta.Actual.Tickets.Bruto.Region.Z1,
          //z1_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z1,
          z2_ventas: venta.Actual.Ventas.Bruto.Region.Z2,
          //z2_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z2,
          z2_tickets: venta.Actual.Tickets.Bruto.Region.Z2,
          //z2_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z2,
          z3_ventas: venta.Actual.Ventas.Bruto.Region.Z3,
          //z3_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z3,
          z3_tickets: venta.Actual.Tickets.Bruto.Region.Z3,
          //z3_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z3,
          z4_ventas: venta.Actual.Ventas.Bruto.Region.Z4,
          //z4_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z4,
          z4_tickets: venta.Actual.Tickets.Bruto.Region.Z4,
          //z4_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z4,
          z5_ventas: venta.Actual.Ventas.Bruto.Region.Z5,
          //z5_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z5,
          z5_tickets: venta.Actual.Tickets.Bruto.Region.Z5,
          //z5_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z5,
          z6_ventas: venta.Actual.Ventas.Bruto.Region.Z6,
          //z6_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z6,
          z6_tickets: venta.Actual.Tickets.Bruto.Region.Z6,
          //z6_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z6,
          t1_ventas: venta.Actual.Ventas.Bruto.Momento.T1,
          //t1_ventas_ap: venta.Anterior.Ventas.Dia.Total,
          t1_tickets: venta.Actual.Tickets.Bruto.Momento.T1,
          //t1_tickets_ap: venta.Anterior.Tickets.Dia.Total,
          t2_ventas: venta.Actual.Ventas.Bruto.Momento.T2,
          //t2_ventas_ap: venta.Anterior.Ventas.Noche.Total,
          t2_tickets: venta.Actual.Tickets.Bruto.Momento.T2,
          //t2_tickets_ap: venta.Anterior.Tickets.Noche.Total,
          t3_ventas: venta.Actual.Ventas.Bruto.Momento.T3,
          //t3_ventas_ap: venta.Anterior.Ventas.Dia.Laborables,
          t3_tickets: venta.Actual.Tickets.Bruto.Momento.T3,
          //t3_tickets_ap: venta.Anterior.Tickets.Dia.Laborables,
          t4_ventas: venta.Actual.Ventas.Bruto.Momento.T4,
          //t4_ventas_ap: venta.Anterior.Ventas.Noche.Laborables,
          t4_tickets: venta.Actual.Tickets.Bruto.Momento.T4,
          //t4_tickets_ap: venta.Anterior.Tickets.Noche.Laborables,
          t5_ventas: venta.Actual.Ventas.Bruto.Momento.T5,
          //t5_ventas_ap: venta.Anterior.Ventas.Dia.Findes,
          t5_tickets: venta.Actual.Tickets.Bruto.Momento.T5,
          //t5_tickets_ap: venta.Anterior.Tickets.Dia.Findes,
          t6_ventas: venta.Actual.Ventas.Bruto.Momento.T6,
          //t6_ventas_ap: venta.Anterior.Ventas.Noche.Findes,
          t6_tickets: venta.Actual.Tickets.Bruto.Momento.T6,
          //t6_tickets_ap: venta.Anterior.Tickets.Noche.Findes,
          ratio: venta.Ratio,
          // COMPARABLES
          del_ventas_comp: venta.Actual.Ventas.Comparable.Canal.Delivery,
          //del_ventas_ap: venta.Anterior.Ventas.Delivery,
          del_tickets_comp: venta.Actual.Tickets.Comparable.Canal.Delivery,
          //del_locales_ap: venta.LocalesReportanDelivery,
          taw_ventas_comp: venta.Actual.Ventas.Comparable.Canal.TakeAway,
          //taw_ventas_ap: venta.Anterior.Ventas.TakeAway,
          taw_tickets_comp: venta.Actual.Tickets.Comparable.Canal.TakeAway,
          //taw_tickets_ap: venta.Anterior.Tickets.TakeAway,
          local_ventas_comp: venta.Actual.Ventas.Comparable.Canal.Local,
          //local_ventas_ap: venta.Anterior.Ventas.Local,
          local_tickets_comp: venta.Actual.Tickets.Comparable.Canal.Local,
          //local_tickets_ap: venta.Anterior.Tickets.Local,
          calle_ventas_comp: venta.Actual.Ventas.Comparable.Ubicacion.Calle,
          //calle_ventas_ap: venta.Anterior.Ventas.Calle,
          calle_tickets_comp: venta.Actual.Tickets.Comparable.Ubicacion.Calle,
          //calle_tickets_ap: venta.Anterior.Tickets.Calle,
          cc_ventas_comp: venta.Actual.Ventas.Comparable.Ubicacion.CC,
          //cc_ventas_ap: venta.Anterior.Ventas.CC,
          cc_tickets_comp: venta.Actual.Tickets.Comparable.Ubicacion.CC,
          //cc_tickets_ap: venta.Anterior.Tickets.CC,
          conces_ventas_comp:
            venta.Actual.Ventas.Comparable.Ubicacion.Concesiones,
          //conces_ventas_ap: venta.Anterior.Ventas.Concesiones,
          conces_tickets_comp:
            venta.Actual.Tickets.Comparable.Ubicacion.Concesiones,
          //conces_tickets_ap: venta.Anterior.Tickets.Concesiones,
          otros_ventas_comp: venta.Actual.Ventas.Comparable.Ubicacion.Otros,
          //otros_ventas_ap: venta.Anterior.Ventas.Otros,
          otros_tickets_comp: venta.Actual.Tickets.Comparable.Ubicacion.Otros,
          //otros_tickets_ap: venta.Anterior.Tickets.Otros,
          z1_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z1,
          //z1_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z1,
          z1_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z1,
          //z1_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z1,
          z2_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z2,
          //z2_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z2,
          z2_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z2,
          //z2_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z2,
          z3_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z3,
          //z3_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z3,
          z3_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z3,
          //z3_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z3,
          z4_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z4,
          //z4_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z4,
          z4_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z4,
          //z4_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z4,
          z5_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z5,
          //z5_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z5,
          z5_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z5,
          //z5_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z5,
          z6_ventas_comp: venta.Actual.Ventas.Comparable.Region.Z6,
          //z6_ventas_ap: venta.Anterior.Ventas.Bruto.Region.Z6,
          z6_tickets_comp: venta.Actual.Tickets.Comparable.Region.Z6,
          //z6_tickets_ap: venta.Anterior.Tickets.Bruto.Region.Z6,
          t1_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T1,
          //t1_ventas_ap: venta.Anterior.Ventas.Dia.Total,
          t1_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T1,
          //t1_tickets_ap: venta.Anterior.Tickets.Dia.Total,
          t2_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T2,
          //t2_ventas_ap: venta.Anterior.Ventas.Noche.Total,
          t2_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T2,
          //t2_tickets_ap: venta.Anterior.Tickets.Noche.Total,
          t3_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T3,
          //t3_ventas_ap: venta.Anterior.Ventas.Dia.Laborables,
          t3_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T3,
          //t3_tickets_ap: venta.Anterior.Tickets.Dia.Laborables,
          t4_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T4,
          //t4_ventas_ap: venta.Anterior.Ventas.Noche.Laborables,
          t4_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T4,
          //t4_tickets_ap: venta.Anterior.Tickets.Noche.Laborables,
          t5_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T5,
          //t5_ventas_ap: venta.Anterior.Ventas.Dia.Findes,
          t5_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T5,
          //t5_tickets_ap: venta.Anterior.Tickets.Dia.Findes,
          t6_ventas_comp: venta.Actual.Ventas.Comparable.Momento.T6,
          //t6_ventas_ap: venta.Anterior.Ventas.Noche.Findes,
          t6_tickets_comp: venta.Actual.Tickets.Comparable.Momento.T6,
          //t6_tickets_ap: venta.Anterior.Tickets.Noche.Findes,
        };
        if (v.semana !== 1) {
          v.semana = v.semana - 1; // FIX ERA SIN NADA DE ESTO
          data.push(v);
        }
      });
      exportFromJSON({
        data: data,
        exportType: exportFromJSON.types.xls,
        fileName: `${moment().format("YYYY-MM-DD hh-mm-ss")} - Ventas ${
          selectedYear?.text
        }`,
      });
    },
  });

  let farButtons: ICommandBarItemProps[] = [
    {
      key: "export",
      text: "Exportar año",
      iconProps: { iconName: "Download" },
      onClick: () => {
        getData({
          variables: {
            year: parseInt(`${selectedYear?.text}`) || moment().year(),
            wk1: 1,
            wk2: 53,
          },
        });
      },
    },
  ];

  /*

	position: relative;
    background: red;
    z-index: 2;
    margin-bottom: -38px;
	*/

  const containerStyles = {
    root: {
      position: "relative",
      marginBottom: -35,
      zIndex: 1,
      height: 32,
      padding: "0 0 0 8px",
      width: "calc(100% - 120px)",
      left: 120,
    },
  } as const;

  let isLoading = !!qry.loading || loading || ventasLoading;

  //if(!!ventasData && ventasData.getVenta.length>0) debugger
  return (
    <>
      <Title title={"Ventas"} />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            left: "calc(60% - 50px)",
            top: 160,
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className={`table-container small ${isLoading ? "loading" : ""}`}>
        <Stack horizontal tokens={stackTokens} styles={containerStyles}>
          <Stack horizontal tokens={stackTokens} className="group">
            <Dropdown
              label="Semana"
              placeholder={
                selectedCadena
                  ? ""
                  : selectedWeek
                  ? `${parseInt(selectedWeek.text)}`
                  : ""
              }
              options={weekOpts}
              styles={dropdownStyles}
              onChange={changeWeek}
              className="grouped"
            />
            <Dropdown
              label="Año"
              placeholder={
                selectedCadena ? "" : selectedYear ? selectedYear.text : ""
              }
              options={yearOpts}
              styles={dropdownStyles}
              onChange={changeYear}
              className="grouped"
            />
          </Stack>
          <Stack horizontal tokens={stackTokens} className="group">
            <Dropdown
              label="Cadena"
              placeholder={selectedWeek ? "" : ""}
              selectedKey={selectedCadena ? selectedCadena.key : null}
              options={cadsOpts}
              styles={dropdownStyles}
              onChange={changeCadena}
              className="grouped"
            />
          </Stack>

          <div className="falseHeader">
            {!isLoading && (
              <>
                <Checkbox
                  label="Ver todo"
                  onChange={() => {
                    setShowDataColumns(!showDataColumns);
                  }}
                />
                <CommandBar items={[]} farItems={farButtons} />
              </>
            )}
          </div>
        </Stack>

        <CrudTable
          label={"Venta"}
          itemName={"Venta"}
          items={modifiedVentasData}
          source={source}
          columns={columns}
          addQuery={addQuery}
          setQuery={setQuery}
          deleteQuery={deleteQuery}
          refetch={refetch}
          width={100}
          onlyDelete
          exportFunc={() => {}}
        />
      </div>
    </>
  );
};

export default Ventas;

import React, { useState } from "react";
import CrudTable from "../../components/CrudTable";
import Title from "../../components/Title";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { normalizeBool } from "../../utils/functions";
import { ICadena } from "../../AppContext";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { Spinner, SpinnerSize } from "@fluentui/react";

const Cadenas = () => {
  const addQuery = gql`
    mutation (
      $nombre: String!
      $compania: String!
      $especialidad: String!
      $segmento: String!
      $contactonombre: String
      $contactoemail: String
      $contactotelefono: String
      $ratio: Int
    ) {
      setCadena(
        input: {
          Nombre: $nombre
          Compania: { UUID: $compania }
          Especialidad: { UUID: $especialidad }
          Segmento: { UUID: $segmento }
          Contacto: {
            Nombre: $contactonombre
            Email: $contactoemail
            Telefono: $contactotelefono
          }
          Ratio: $ratio
        }
      ) {
        UUID
        Nombre
        Error
      }
    }
  `;
  const setQuery = gql`
    mutation (
      $currId: String!
      $nombre: String!
      $compania: String!
      $especialidad: String!
      $segmento: String!
      $contactonombre: String
      $contactoemail: String
      $contactotelefono: String
      $ratio: Int
    ) {
      setCadena(
        input: {
          UUID: $currId
          Nombre: $nombre
          Compania: { UUID: $compania }
          Especialidad: { UUID: $especialidad }
          Segmento: { UUID: $segmento }
          Contacto: {
            Nombre: $contactonombre
            Email: $contactoemail
            Telefono: $contactotelefono
          }
          Ratio: $ratio
        }
      ) {
        UUID
        Nombre
        Error
      }
    }
  `;
  const deleteQuery = gql`
    mutation ($currId: String!) {
      deleteCadena(input: { UUID: $currId }) {
        Completed
        Error
      }
    }
  `;

  const getQuery = gql`
    query {
      getCadena {
        UUID
        Nombre
        Contacto {
          Nombre
          Email
          Telefono
        }
        Compania {
          Nombre
        }
        Especialidad {
          Descripcion
        }
        Segmento {
          Descripcion
        }
        CargaIVA
        Ratio
        hasUsuarios
        hasVentas
      }
    }
  `;

  let { data, refetch, loading: getLoading } = useQuery(getQuery);

  const [finalData, setFinalData] = useState(data);
  const processData = (data: any) => {
    let fData: any[] = [];
    if (data)
      data["getCadena"].forEach((cadena: any) => {
        let fSegmento = "";
        let fEspecialidad = "";

        cadena.Segmento.forEach((segmento: any) => {
          fSegmento += segmento.Descripcion + ", ";
        });
        cadena.Especialidad.forEach((especialidad: any) => {
          if (especialidad?.Descripcion)
            fEspecialidad += especialidad.Descripcion + ", ";
        });
        fSegmento = fSegmento.slice(0, -2);
        fEspecialidad = fEspecialidad.slice(0, -2);
        fData.push({
          ...cadena,
          Segmento: fSegmento,
          Especialidad: fEspecialidad,
        });
      });

    return { getCadena: fData };
  };
  React.useEffect(() => {
    setFinalData(processData(data));
  }, [data]);

  const source = {
    datafields: [
      { name: "UUID", type: "string", readonly: true },
      { name: "Nombre", type: "string" },
      { name: "Compania", map: "Compania>Nombre", type: "string" },
      { name: "Contacto: Nombre", map: "Contacto>Nombre", type: "string" },
      { name: "Contacto: Email", map: "Contacto>Email", type: "string" },
      { name: "Contacto: Telefono", map: "Contacto>Telefono", type: "number" },
      { name: "Segmento", map: "Segmento", type: "string" },
      { name: "Especialidad", map: "Especialidad", type: "string" },
      { name: "Ratio", type: "number" },
      { name: "hasUsuarios", type: "bool", readonly: true },
      { name: "hasVentas", type: "bool", readonly: true },
      { name: "CargaIVA", type: "bool", readonly: true },
    ],
    datatype: "json",
    root: "",
    id: "UUID",
    sortcolumn: "Nombre",
    localdata: finalData,
  };
  const columns = [
    { text: "Código", datafield: "Nombre", getDatawidth: 80 },
    { text: "Grupo", datafield: "Compania", width: 80 },
    { text: "Nombre", datafield: "Contacto: Nombre", width: 80 },
    { text: "Email", datafield: "Contacto: Email", width: 120 },
    { text: "Teléfono", datafield: "Contacto: Telefono", width: 80 },
    { text: "Segmento", datafield: "Segmento" },
    { text: "Especialidad", datafield: "Especialidad" },
    {
      text: "Usuarios",
      datafield: "hasUsuarios",
      width: 60,
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "Ventas",
      datafield: "hasVentas",
      width: 60,
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "IVA inc.",
      datafield: "CargaIVA",
      width: 60,
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    { text: "Ratio", datafield: "Ratio", width: 40 },
  ];

  const [getData, { loading }] = useLazyQuery(getQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (fetchedData) => {
      const data: any[] = [];
      fetchedData.getCadena.forEach((cadena: ICadena) => {
        let c = {
          Código: cadena.Nombre,
          Grupo: cadena.Compania.Nombre,
          Nombre: cadena.Contacto?.Nombre,
          Email: cadena.Contacto?.Email,
          Teléfono: cadena.Contacto?.Telefono,
          Segmento: cadena.Segmento[0].Descripcion,
          Especialidad: cadena.Especialidad[0].Descripcion,
          Usuarios: cadena.hasUsuarios ? "Sí" : "No",
          Ventas: cadena.hasVentas ? "Sí" : "No",
          "IVA inc.": cadena.CargaIVA ? "Sí" : "No",
          Ratio: cadena.Ratio,
        };
        data.push(c);
      });
      exportFromJSON({
        data: data,
        exportType: exportFromJSON.types.xls,
        fileName: `${moment().format("YYYY-MM-DD hh-mm-ss")} - Cadenas`,
      });
    },
  });

  let isLoading = loading || getLoading;

  return (
    <>
      <Title title={"Cadenas"} />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            left: "calc(60% - 50px)",
            top: 160,
          }}
        >
          <Spinner size={SpinnerSize.medium} />
        </div>
      )}
      <div className={`table-container small ${isLoading ? "loading" : ""}`}>
        <CrudTable
          label={"Cadena"}
          itemName={"Cadena"}
          items={data}
          source={source}
          columns={columns}
          addQuery={addQuery}
          setQuery={setQuery}
          deleteQuery={deleteQuery}
          width={100}
          refetch={refetch}
          exportFunc={getData}
        />
      </div>
    </>
  );
};

export default Cadenas;

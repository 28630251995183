import React from "react";
import GrupoCadenas from "./GrupoCadenas";
import Cadenas from "./Cadenas";
import Especialidades from "./Especialidades";
import Segmentos from "./Segmentos";
import Usuarios from "./Usuarios";
import CargaDatos from "./CargaDatos";
import Configuracion from "./Configuracion";
import Ventas from "./Ventas";
import Eventos from "./Eventos";
import CuadroVisibilidad from "./CuadroVisibilidad";
import Parametros from "./Parametros";
import ClientesRevo from "./ClientesREVO";

const Sections = ({ section }: { section: number }) => {
  switch (section) {
    case 0:
      return (
        <main className="admin section">
          <GrupoCadenas />
        </main>
      );
    case 1:
      return (
        <main className="admin section">
          <Cadenas />
        </main>
      );
    case 2:
      return (
        <main className="admin section">
          <Segmentos />
        </main>
      );
    case 3:
      return (
        <main className="admin section">
          <Especialidades />
        </main>
      );
    case 4:
      return (
        <main className="admin section">
          <Usuarios />
        </main>
      );
    case 5:
      return (
        <main className="admin section">
          <Ventas />
        </main>
      );
    case 6:
      return (
        <main className="admin section">
          <Eventos />
        </main>
      );
    case 7:
      return <main className="admin section">Periodos</main>;
    case 8:
      return (
        <main className="admin section">
          <CuadroVisibilidad />
        </main>
      );
    case 10:
      return (
        <main className="admin section">
          <Parametros />
        </main>
      );
    case 11:
      return (
        <main className="admin section">
          <CargaDatos />
        </main>
      );
    case 12:
      return (
        <main className="admin section">
          <Configuracion />
        </main>
      );
    case 13:
      return (
        <main className="admin section">
          <ClientesRevo />
        </main>
      );
    default:
      return <></>;
  }
};

export default Sections;

import { gql, useMutation } from '@apollo/client';
import { IStackProps, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
//import { useBoolean } from '@fluentui/react-hooks';
import Notification, {INotification} from '../../components/InlineNotification'

import {ReactComponent as BgImg} from './img/bg.svg'
import {ReactComponent as Logo} from './img/logo.svg'


const RESET_MUTATION = gql`
	mutation (
		$token: String,
		$password: String
	) {
		reset(input: {Token: $token, Password: $password}) {
			Error
		}
	}
`;


const Login = ({setToken}:{setToken:Function}) => {
	const [errorReset, setErrorReset] = useState("")
	const [message, setMessage] = useState<{type:INotification,text:string}>({type:"success",text:""})

	const [formState, setFormState] = useState({
		password1: '',
		password2: ''
	});

	const [reset] = useMutation(RESET_MUTATION, {
		variables: {
			token: window.location.search.slice(7),
			password: formState.password1
		},
		onCompleted: ({ reset }) => {
			if(!reset||(reset.Error&&reset.Error!=="")){	
				setMessage({type: "error", text:reset.Error||"Se ha producido un error. Inténtelo de nuevo más tarde."})
			}else{
				setMessage({type: "success", text:"Se ha establecido la nueva contraseña."})
				setErrorReset("")
			}
		}
	});

	const handleReset = () => {
		if(formState.password1!==formState.password2){
			setErrorReset("Las contraseñas no coinciden.")
			return
		}else{
			reset()
		}
	}
  
	useEffect(()=>{
		if(message.type!=="error" && window.location.search.slice(0,7)!=="?reset="){
			setMessage({type: "error", text:"El enlace introducido es incorrecto."})
		}
	},[message.type])

	const columnProps: Partial<IStackProps> = {
		tokens: { childrenGap: 15 },
		styles: { root: { width: 300 } },
	};

  	return (
		<>
		<main id="login">
			<div className="login-panel"><BgImg/></div>
			<div className="login-form-container">
				<form className="login-form">
					<div className="logo"><Logo/></div>
					<Stack {...columnProps}>
						{message.text !== "" &&
							<Notification
								text={message.text}
								type={message.type}
								visible={true}
								isMultiline={true}
								toggleVisibility={()=>{window.location.assign(window.location.origin)}}
								autoHide={false}
							/>
						}
						{message.text === "" &&
						<>
							<TextField
								label="Nueva contraseña"
								type="password"
								autoComplete="current-password"
								canRevealPassword
								required
								onKeyPress={e=>{if(e.key==="Enter"){e.preventDefault();handleReset()}}}
								onChange={(e:any) =>
									setFormState({
									...formState,
									password1: e.target.value
									})
								}
							/>
							<TextField
								label="Repetir nueva contraseña"
								type="password"
								errorMessage={errorReset}
								autoComplete="current-password"
								canRevealPassword
								required
								onKeyPress={e=>{if(e.key==="Enter"){e.preventDefault();handleReset()}}}
								onChange={(e:any) =>
									setFormState({
									...formState,
									password2: e.target.value
									})
								}
							/>
							<PrimaryButton
								id={"loginbtn"}
								text="Establecer nueva contraseña"
								onClick={()=>handleReset()}
								allowDisabledFocus
								type="button"
							/>
						</>
						}
					</Stack>
				</form>
			</div>
    	</main>
		</>
  	);
};

export default Login;

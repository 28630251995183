import { getTheme, Spinner, Stack, SpinnerSize } from '@fluentui/react';

const theme = getTheme();
const styles = {
	root: {
		position: 'absolute',
		background: `linear-gradient(-45deg, ${theme.palette.neutralPrimary}, ${theme.palette.neutralDark})`,
		width: '100vw',
		height: '100vh',
		zIndex: '100',
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center'
	}
} as const;

const Loading = () => {
	return(
		<Stack styles={styles}>
			<div style={{alignSelf: "center",textAlign: "center"}}>
				<Spinner size={SpinnerSize.large} />
			</div>
		</Stack>
	)
}

export default Loading;
import React, { useState } from "react";
import CrudTable from "../../components/CrudTable";
import Title from "../../components/Title";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { normalizeBool } from "../../utils/functions";
import "./style.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { IUsuario } from "../../AppContext";
import exportFromJSON from "export-from-json";
import moment from "moment";

const Usuarios = () => {
  const addQuery = gql`
    mutation (
      $nombre: String!
      $email: String!
      $compania: String!
      $cadena: String!
      $rol: String!
    ) {
      setUsuario(
        input: {
          Nombre: $nombre
          Email: $email
          Compania: { UUID: $compania }
          Cadena: { UUID: $cadena }
          Rol: { UUID: $rol }
        }
      ) {
        UUID
        Nombre
        Error
      }
    }
  `;
  const setQuery = gql`
    mutation (
      $currId: String!
      $nombre: String!
      $email: String!
      $compania: String!
      $cadena: String!
      $rol: String!
    ) {
      setUsuario(
        input: {
          UUID: $currId
          Nombre: $nombre
          Email: $email
          Compania: { UUID: $compania }
          Cadena: { UUID: $cadena }
          Rol: { UUID: $rol }
        }
      ) {
        UUID
        Nombre
        Error
      }
    }
  `;

  const deleteQuery = gql`
    mutation ($currId: String!) {
      deleteUsuario(input: { UUID: $currId }) {
        Completed
        Error
      }
    }
  `;

  const getQuery = gql`
    query {
      getUsuario {
        UUID
        Nombre
        Email
        Rol {
          Descripcion
          isAdmin
          canRead
          canWrite
          canDownload
        }
        Compania {
          Nombre
        }
        Cadena {
          Nombre
        }
        hasVentas
      }
    }
  `;

  const getQuery2 = gql`
    query {
      getRolUsuario {
        UUID
        Descripcion
        isAdmin
        isUser
        canRead
        canWrite
        canDownload
      }
    }
  `;

  let { data, refetch, loading: getLoading } = useQuery(getQuery);

  let data2 = useQuery(getQuery2);

  const [finalData, setFinalData] = useState(data);
  const processData = (data: any) => {
    let fData: any[] = [];
    if (data)
      data["getUsuario"].forEach((usuario: any) => {
        let fValue = "";
        if (usuario.Compania) fValue += usuario.Compania.Nombre;
        if (usuario.Cadena) fValue += usuario.Cadena.Nombre;

        fData.push({ ...usuario, ComboID: fValue });
      });

    return { getUsuario: fData };
  };

  React.useEffect(() => {
    setFinalData(processData(data));
  }, [data]);

  const source = {
    datafields: [
      { name: "UUID", type: "string", readonly: true },
      { name: "Nombre", type: "string" },
      { name: "Email", map: "Email", type: "string" },
      { name: "ComboID", value: "ComboID", type: "string", readonly: true },
      {
        name: "Compania",
        map: "Compania>Nombre",
        type: "string",
        inputonly: true,
      },
      { name: "Cadena", map: "Cadena>Nombre", type: "string", inputonly: true },
      { name: "Rol", map: "Rol>Descripcion", type: "string" },
      { name: "hasVentas", type: "bool", readonly: true },
    ],
    datatype: "json",
    root: "",
    id: "UUID",
    localdata: finalData,
  };
  const columns = [
    { text: "Nombre", datafield: "Nombre" },
    { text: "Rol", datafield: "Rol", width: 140 },
    { text: "Correo", datafield: "Email" },
    { text: "Compañía", datafield: "Compania", hidden: true },
    { text: "Cadena", datafield: "Cadena", hidden: true },
    { text: "Cadena o grupo", datafield: "ComboID", width: 120 },
    {
      text: "Ventas",
      datafield: "hasVentas",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
      width: 80,
    },
  ];

  const source2 = {
    datafields: [
      { name: "UUID", type: "string", readonly: true },
      { name: "Descripcion", type: "string" },
      { name: "isAdmin", type: "boolean" },
      { name: "isUser", type: "boolean" },
      { name: "canRead", type: "boolean" },
      { name: "canWrite", type: "boolean" },
      { name: "canDownload", type: "boolean" },
    ],
    datatype: "json",
    root: "",
    id: "UUID",
    localdata: data2.data,
  };
  const columns2 = [
    { text: "Descripción", datafield: "Descripcion" },
    {
      text: "Administrador",
      datafield: "isAdmin",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "Usuario",
      datafield: "isUser",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "Lectura",
      datafield: "canRead",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "Escritura",
      datafield: "canWrite",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
    {
      text: "Descarga",
      datafield: "canDownload",
      cellsRenderer: (_r: any, _c: any, val: any) => {
        return normalizeBool(val);
      },
    },
  ];

  const [getData, { loading }] = useLazyQuery(getQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (fetchedData) => {
      const data: any[] = [];
      fetchedData.getUsuario.forEach((usuario: IUsuario) => {
        let u = {
          Nombre: usuario.Nombre,
          Rol: usuario.Rol.Descripcion,
          Correo: usuario.Email,
          "Cadena o grupo": `${usuario.Cadena ? usuario.Cadena.Nombre : ""}${
            usuario.Compania ? usuario.Compania.Nombre : ""
          }`,
          Ventas: usuario.hasVentas ? "Sí" : "No",
        };
        data.push(u);
      });
      exportFromJSON({
        data: data,
        exportType: exportFromJSON.types.xls,
        fileName: `${moment().format("YYYY-MM-DD hh-mm-ss")} - Usuarios`,
      });
    },
  });

  let isLoading = loading || getLoading;

  return (
    <>
      <Title title={"Usuarios"} />
      {isLoading && (
        <div className="spinner-container">
          <Spinner size={SpinnerSize.medium} />
        </div>
      )}
      <div className={`table-container small ${isLoading ? "loading" : ""}`}>
        <CrudTable
          label={"Usuario"}
          itemName={"Usuario"}
          items={data}
          source={source}
          columns={columns}
          addQuery={addQuery}
          setQuery={setQuery}
          deleteQuery={deleteQuery}
          refetch={refetch}
          exportFunc={getData}
        />
      </div>
      <div className="spacer" />
      <Title title={"Roles de usuario"} />
      <CrudTable
        label={"Rol usuario"}
        itemName={"Rol usuario"}
        items={data}
        source={source2}
        columns={columns2}
        addQuery={addQuery}
        setQuery={setQuery}
        deleteQuery={deleteQuery}
        refetch={refetch}
        readonly
      />
    </>
  );
};

export default Usuarios;

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#0c0e0ebf";
    tooltipEl.style.border = "1px solid #151616";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.color = "white";
    tooltipEl.style.fontWeight = "400";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.backdropFilter = "blur(5px)";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const customTooltip = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "11px";
      span.style.width = "11px";
      span.style.display = "inline-block";
      span.style.borderRadius = "6px";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0";

      const td = document.createElement("td");
      td.style.borderWidth = "0";

      const text = document.createTextNode(
        body[0].includes(":") ? body[0].split(": ")[1] + "%" : body[0] + "%"
      );

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    //tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const flipped = chart.config.options.plugins.tooltip.axis === "y";
  //console.log(flipped)

  const cWidth =
    window.innerWidth > 1220 ? window.innerWidth - 400 : window.innerWidth;
  //console.log({innerWidth: window.innerWidth, cWidth, positionX, caretX: tooltip.caretX})
  //console.log(positionX + tooltip.caretX + 100 > cWidth)

  let left =
    flipped && positionX + tooltip.caretX + 100 > cWidth
      ? cWidth / 2 + 80
      : positionX + tooltip.caretX;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${
    window.innerWidth - 400 >= left ? left : window.innerWidth - 400
  }px`;
  tooltipEl.style.top = `${
    !flipped && tooltip.caretY < 0 ? positionY : positionY + tooltip.caretY
  }px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

import { MessageBar, MessageBarType } from '@fluentui/react';
import './style.scss'

export type INotification = "default"|"info"|"error"|"blocked"|"severeWarning"|"success"|"warning"
const Notification = ({text,type,visible,toggleVisibility,autoHide=true,isMultiline=false}:{text:string,type:INotification, visible: boolean, toggleVisibility: Function, autoHide?:boolean, isMultiline?:boolean}) => {
	return (
		<>
		{visible &&
		<MessageBar
			messageBarType={type==="default"?0:MessageBarType[type]}
			isMultiline={isMultiline}
			onDismiss={()=>toggleVisibility(false)}
			dismissButtonAriaLabel="Close"
			style={{maxWidth: "fit-content"}}
			//className={`${localVisible?"visible":"hidden"}`}
		>{text}</MessageBar>
		}
		</>
	);
}

export default Notification;

